import { useDispatch, useSelector } from 'react-redux';
import { ReportsSelectors } from './selectors';
import {
  reportsGetPaymentVolume,
  reportsGetTransactionsByCountry,
  reportsGetTransactionsByPaymentMethod,
  reportsGetTransactionsByPaymentSolution,
  reportsGetTransactionsSummary,
} from './actions';

export const useReports = () => {
  const dispatch = useDispatch();

  return {
    summary: useSelector(ReportsSelectors.getSummary),
    getTransactionsSummary: (days: number, period?: string) => dispatch(reportsGetTransactionsSummary(days, period)),

    paymentVolume: useSelector(ReportsSelectors.getPaymentVolume),
    getPaymentVolume: (days: number, period?: string) => dispatch(reportsGetPaymentVolume(days, period)),

    transactionsByCountry: useSelector(ReportsSelectors.getTransactionsByCountry),
    getTransactionsByCountry: (days: number, period?: string) => dispatch(reportsGetTransactionsByCountry(days, period)),

    transactionsByPaymentSolution: useSelector(ReportsSelectors.getTransactionsByPaymentSolution),
    getTransactionsByPaymentSolution: (days: number, period?: string) =>
      dispatch(reportsGetTransactionsByPaymentSolution(days, period)),

    transactionsByPaymentMethod: useSelector(ReportsSelectors.getTransactionsByPaymentMethod),
    getTransactionsByPaymentMethod: (days: number, period?: string) =>
      dispatch(reportsGetTransactionsByPaymentMethod(days, period)),
  };
};
