import { handleErrorInfo } from '../../utils';
import { ChargebacksActionType } from './actions';
import { ChargebacksState } from './interfaces';
import { prepareExportTransactionCSV } from '../../utils/chargebacks-utils';

const initialState: ChargebacksState = {
  loading: true,
  error: false,
  allChargebacks: undefined,
  chargebackDetail: {
    loading: false,
    error: false,
    errorInfo: undefined,
    data: undefined,
  },
  paymentMethods: {
    loading: false,
    error: false,
    errorInfo: undefined,
    data: [],
  },
  export: {
    loading: false,
    error: false,
    errorInfo: undefined,
    show: false,
    data: undefined,
  },
  uploadDocument: {
    loading: false,
    error: false,
    errorInfo: undefined,
    data: undefined,
  },
};

const ChargebacksReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ChargebacksActionType.GET_CHARGEBACKS: {
      return {
        ...state,
        loading: true,
        error: false,
        errorInfo: undefined,
      };
    }
    case ChargebacksActionType.GET_CHARGEBACKS_SUCCESS: {
      return {
        ...state,
        loading: false,
        allChargebacks: {
          ...action.response,
        },
      };
    }
    case ChargebacksActionType.GET_CHARGEBACKS_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        errorInfo: handleErrorInfo(ChargebacksActionType.GET_CHARGEBACKS_ERROR, action.error),
        allChargebacks: null,
      };
    }
    case ChargebacksActionType.SET_CHARGEBACK_DETAIL: {
      return {
        ...state,
        chargebackDetail: {
          ...state.chargebackDetail,
          data: action.data,
        },
      };
    }
    case ChargebacksActionType.GET_CHARGEBACK_DETAIL: {
      return {
        ...state,
        chargebackDetail: {
          ...state.chargebackDetail,
          loading: true,
          error: false,
          errorInfo: undefined,
          data: undefined,
        },
      };
    }
    case ChargebacksActionType.GET_CHARGEBACK_DETAIL_SUCCESS: {
      return {
        ...state,
        chargebackDetail: {
          ...state.chargebackDetail,
          loading: false,
          data: action.response,
        },
      };
    }
    case ChargebacksActionType.GET_CHARGEBACK_DETAIL_ERROR: {
      return {
        ...state,
        chargebackDetail: {
          ...state.chargebackDetail,
          loading: false,
          error: true,
          errorInfo: handleErrorInfo(ChargebacksActionType.GET_CHARGEBACK_DETAIL_ERROR, action.error),
          data: null,
        },
      };
    }
    case ChargebacksActionType.GET_PAYMENT_METHODS_FOR_CHARGEBACKS: {
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          loading: true,
          error: false,
          errorInfo: undefined,
          data: [],
        },
      };
    }
    case ChargebacksActionType.GET_PAYMENT_METHODS_FOR_CHARGEBACKS_SUCCESS: {
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          loading: false,
          error: false,
          errorInfo: undefined,
          data: action.response.data,
        },
      };
    }
    case ChargebacksActionType.GET_PAYMENT_METHODS_FOR_CHARGEBACKS_ERROR: {
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          loading: false,
          error: true,
          errorInfo: handleErrorInfo(ChargebacksActionType.GET_PAYMENT_METHODS_FOR_CHARGEBACKS_ERROR, action.error),
        },
      };
    }
    case ChargebacksActionType.SHOW_EXPORT_CHARGEBACKS: {
      return {
        ...state,
        export: {
          ...state.export,
          show: action.show,
          ...(action.show
            ? {
                loading: false,
                error: false,
                errorInfo: undefined,
                data: undefined,
              }
            : {}),
        },
      };
    }
    case ChargebacksActionType.EXPORT_CHARGEBACKS: {
      return {
        ...state,
        exportType: action.exportType,
        export: {
          ...state.export,
          loading: true,
          error: false,
          errorInfo: undefined,
          data: undefined,
        },
      };
    }
    case ChargebacksActionType.EXPORT_CHARGEBACKS_SUCCESS: {
      return {
        ...state,
        export: {
          ...state.export,
          loading: false,
          data: prepareExportTransactionCSV(action.response.text),
        },
      };
    }
    case ChargebacksActionType.EXPORT_CHARGEBACKS_ERROR: {
      return {
        ...state,
        export: {
          ...state.export,
          loading: false,
          error: true,
          errorInfo: handleErrorInfo(ChargebacksActionType.EXPORT_CHARGEBACKS_ERROR, action.error),
        },
      };
    }
    case ChargebacksActionType.SEND_CHARGEBACK_DOCUMENT: {
      return {
        ...state,
        uploadDocument: {
          ...state.uploadDocument,
          loading: true,
          error: false,
          errorInfo: undefined,
          data: undefined,
        },
      };
    }
    case ChargebacksActionType.SEND_CHARGEBACK_DOCUMENT_SUCCESS: {
      return {
        ...state,
        uploadDocument: {
          ...state.uploadDocument,
          loading: false,
          data: action.response,
        },
      };
    }
    case ChargebacksActionType.SEND_CHARGEBACK_DOCUMENT_ERROR: {
      return {
        ...state,
        uploadDocument: {
          ...state.uploadDocument,
          loading: false,
          error: true,
          errorInfo: handleErrorInfo(ChargebacksActionType.SEND_CHARGEBACK_DOCUMENT_ERROR, action.error),
          data: null,
        },
      };
    }
    case ChargebacksActionType.RESET_CHARGEBACK_DOCUMENT: {
      return {
        ...state,
        uploadDocument: {
          ...state.uploadDocument,
          loading: false,
          error: false,
          errorInfo: undefined,
          data: undefined,
        },
      };
    }

    default:
      return state;
  }
};

export default ChargebacksReducer;
