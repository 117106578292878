import { useMemo } from 'react';
import Cookies from 'universal-cookie';

const COOKIE_NAME = '_ga';

export default function useMerchantGaCookie() {
  return useMemo(() => {
    const cookies = new Cookies();
    const gaCookie = cookies.get(COOKIE_NAME);
    const match = gaCookie && gaCookie.match(/\d+\.\d+$/);

    return match ? match[0] : undefined;
  });
}
