import { useDispatch, useSelector } from 'react-redux';
import { ExportType, Filters } from '../interfaces';
import {
  exportChargebacks,
  getPaymentMethodsForTrans,
  setChargebackDetail,
  getChargebackDetail,
  getChargebacks,
  showExportChargebacks,
  sendChargebackDocument,
  resetChargebackDocument,
} from './actions';
import { Chargeback, UploadChargebackDocumentValues } from './interfaces';
import { ChargebacksSelectors } from './selectors';

export const useChargebacks = () => {
  const dispatch = useDispatch();

  return {
    loading: useSelector(ChargebacksSelectors.getLoading),
    error: useSelector(ChargebacksSelectors.getError),
    allChargebacks: useSelector(ChargebacksSelectors.getAllChargebacks),
    chargebackDetail: useSelector(ChargebacksSelectors.getChargebackDetail),
    export: useSelector(ChargebacksSelectors.getExport),
    exportType: useSelector(ChargebacksSelectors.getExportType),
    paymentMethods: useSelector(ChargebacksSelectors.getPaymentMethods),
    chargebackUploadDocument: useSelector(ChargebacksSelectors.getChargebackUploadDocument),
    getChargebacks: (page?: number, filter?: Filters) => dispatch(getChargebacks(page, filter)),
    setChargebackDetail: (data: Chargeback) => dispatch(setChargebackDetail(data)),
    getChargebackDetail: (invoiceId: string) => dispatch(getChargebackDetail(invoiceId)),
    sendChargebackDocument: (chargebackId: number, values: UploadChargebackDocumentValues) =>
      dispatch(sendChargebackDocument(chargebackId, values)),
    resetChargebackDocument: () => dispatch(resetChargebackDocument()),
    getPaymentMethodsForTrans: (country: string) => dispatch(getPaymentMethodsForTrans(country)),
    showExportChargebacks: (show: boolean) => dispatch(showExportChargebacks(show)),
    exportChargebacks: (filter: string, type: ExportType) => dispatch(exportChargebacks(filter, type)),
  };
};
