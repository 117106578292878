import l from '../../../../lang';
import IntegrationCard from '../integration-card';
import { ReactComponent as OpenCartIcon } from '../../../../assets/icons/integrations/opencart.svg';
import { usePaymentIntegration } from '../../../../store/payment-integration';
import { DetailsOpened } from '../../../../store/payment-integration/interfaces';
import { segmentIntegrationsMenuHostinger } from '../../../../utils/analytics';

export default () => {
  const { setDetailsOpened } = usePaymentIntegration();

  return (
    <IntegrationCard
      title={l('paymentIntegration.opencart.title')}
      icon={<OpenCartIcon />}
      color="light-blue"
      onDetails={() => {
        setDetailsOpened(DetailsOpened.OPENCART);
      }}
      detailsText={l('paymentIntegration.card.integration.voting.link')}
    />
  );
};
