import l from '../../../../lang';
import { useTransactions } from '../../../../store/transactions';
import ButtonLink from '../../../common/button-link';
import { Chip } from '../../../common/chip';
import InputSearch from '../../../common/input-search';
import './styles.scss';
import { useMediaQuery } from '@mui/material';
import TransactionFilterGrouped from './filter-grouped';
import useComponentsFilter from './use-components-filter';
import { segmentTransactionsFilterSearchBox } from '../../../../utils/analytics';

interface Filters {
  [key: string]: string;
}

interface TransactionFilterProps {
  loading: boolean;
  appliedFilters: Filters;
  clearFilters: () => void;
  changeFilters: (filters: Filters) => void;
}

const ChargebackFilter = ({ loading, appliedFilters, clearFilters, changeFilters }: TransactionFilterProps) => {
  const isDesktop = useMediaQuery('(min-width:1024px)');
  const { dateRangeFilter, countryFilter, paymentMethodsFilter, statusFilter } = useComponentsFilter({
    appliedFilters,
    changeFilters,
  });
  const { paymentMethods } = useTransactions();

  const chipText = (keyFilter: string, value: string, allFilters: Filters): { label: string; value: string }[] => {
    switch (keyFilter) {
      case 'status':
        return value.split(',').map((s: string) => ({ label: `${l('chargebacks.status.' + s)}`, value: s }));
      case 'country':
        return value.split(',').map((co: string) => ({ label: `${l('countryCode.' + co)}`, value: co }));
      case 'paymentMethodId':
        return value.split(',').map((pmId: string) => ({
          label: `${paymentMethods.data.find(pm => `${pm.id}` === `${pmId}`)?.name}`,
          value: pmId,
        }));
      case 'from':
        if (value === allFilters.to) {
          return [{ label: value, value }];
        }
        return [{ label: `${l('transactions.filter.chip.' + keyFilter)}: ${value}`, value }];
      case 'to':
        if (value !== allFilters.from) {
          return [{ label: `${l('transactions.filter.chip.' + keyFilter)}: ${value}`, value }];
        }
        return [{ label: '', value: '' }];
      default:
        return [{ label: value, value }];
    }
  };

  const removeItem = (appliedFilter: string, valueToRemove: string) => {
    return appliedFilter.split(',').length > 1
      ? appliedFilter
          .split(',')
          .filter(item => item !== valueToRemove)
          .join(',')
      : '';
  };

  const searchByFilters = [{ value: 'external_id', label: l('chargebacks.invoiceId') }];

  return (
    <div className="transaction_filter">
      {isDesktop ? (
        <>
          <div className="transaction_filter__select">{dateRangeFilter}</div>
          <div className="transaction_filter__select">{countryFilter}</div>
          <div className="transaction_filter__select">{paymentMethodsFilter}</div>
          <div className="transaction_filter__select">{statusFilter}</div>
        </>
      ) : (
        <div className="transaction_filter__button_filter_wrapper">
          <TransactionFilterGrouped
            appliedFilters={appliedFilters}
            clearFilters={clearFilters}
            changeFilters={changeFilters}
          />
        </div>
      )}
      {Object.keys(appliedFilters).some(keyFilter => appliedFilters[keyFilter] !== '') && (
        <>
          <div className="transaction_filter__divider" />
          <div className="transaction_filter__applied_filters_wrapper">
            <div className="transaction_filter__applied_filters">
              <span>{l('transactions.filter.appliedFilters')}: </span>
              {!isDesktop && (
                <>
                  <span className="transaction_filter__clear_filters_mobile">
                    <ButtonLink text={l('transactions.filter.clearFilters')} onClick={clearFilters} />
                  </span>
                  <br />
                </>
              )}
              {Object.keys(appliedFilters).map(keyFilter => {
                const chip =
                  appliedFilters[keyFilter] !== '' && chipText(keyFilter, appliedFilters[keyFilter], appliedFilters);
                return (
                  <>
                    {chip &&
                      chip.map(({ label, value }) => (
                        <>
                          {label && value && (
                            <div className="transaction_filter__chip_wrapper">
                              <Chip
                                label={label}
                                onDelete={() =>
                                  changeFilters({
                                    ...appliedFilters,
                                    ...(keyFilter === 'country'
                                      ? { paymentMethodId: '' }
                                      : keyFilter === 'from' && appliedFilters.from === appliedFilters.to
                                      ? { to: '' }
                                      : {}),
                                    [keyFilter]: removeItem(appliedFilters[keyFilter], value),
                                  })
                                }
                              />
                            </div>
                          )}
                        </>
                      ))}
                  </>
                );
              })}
            </div>
            <div className="transaction_filter__clear_filters_desktop">
              <ButtonLink text={l('transactions.filter.clearFilters')} onClick={clearFilters} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ChargebackFilter;
