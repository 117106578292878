import { FunctionComponent } from 'react';
import Parser from 'html-react-parser';
import l from '../../../../lang';
import { DialogConfirm, CardConfirm } from '../../../common/confirm';

interface UploadChargebackDocumentSuccessProps {
  onClose: (confirm: boolean) => void;
}

const UploadChargebackDocumentSuccess: FunctionComponent<UploadChargebackDocumentSuccessProps> = ({ onClose }) => (
  <DialogConfirm onClose={onClose}>
    <CardConfirm
      onClose={onClose}
      headline={Parser(l('chargebacks.upload-doc.success.headline'))}
      description={Parser(l('chargebacks.upload-doc.success.subheadline'))}
      confirmChildren={l('chargebacks.upload-doc.success.button')}
      level={'success'}
    />
  </DialogConfirm>
);

export default UploadChargebackDocumentSuccess;
