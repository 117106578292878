import l from '../../../../lang';
import IntegrationCard from '../integration-card';
import { ReactComponent as JumpsellerIcon } from '../../../../assets/icons/integrations/jumpseller.svg';
import { usePaymentIntegration } from '../../../../store/payment-integration';
import { DetailsOpened } from '../../../../store/payment-integration/interfaces';

export default () => {
  const { setDetailsOpened } = usePaymentIntegration();

  return (
    <IntegrationCard
      title={l('paymentIntegration.jumpseller.title')}
      icon={<JumpsellerIcon />}
      color="green"
      onDetails={() => {
        setDetailsOpened(DetailsOpened.JUMPSELLER);
      }}
      detailsText={l('paymentIntegration.card.integration.voting.link')}
    />
  );
};
