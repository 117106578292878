import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { FormHelperText, MenuItem, OutlinedInput, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DateRangePicker from '../date-range-picker';
import RadioButton from '../radio-button';
import l from '../../../lang';
import moment from 'moment';
import { Button } from '../button';

const useStyles = makeStyles(() => ({
  formControl: {
    marginTop: useTheme().spacing(2),
    '&.sm': {
      fontSize: '14px',
    },
    '&.md': {
      fontSize: '16px',
    },
    '&.lg': {
      width: '100%',
    },
  },
  label: {
    fontSize: '14px',
  },
  select: {
    backgroundColor: '#ffffff9e',
    fontSize: '14px',
  },
  selectDisabled: {
    backgroundColor: '#f1f1f19e',
    fontSize: '14px',
  },
  titleOptions: {
    padding: '10px 16px 0',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '24px',
  },
  buttons: {
    position: 'sticky',
    backgroundColor: '#fff !important',
    bottom: 0,
    zIndex: 2,
  },
  cancel: {
    width: '49%',
    marginRight: '1%',
  },
  apply: {
    width: '49%',
    marginLeft: '1%',
  },
}));

interface RangeProps {
  from: string;
  to: string;
  range: Range;
}

interface SelectDateRangeProps {
  fromDate?: string;
  toDate?: string;
  label?: string;
  name?: string;
  disabled?: boolean;
  size?: 'small' | 'medium';
  error?: string;
  titleOptions?: string;
  withApplyBtn?: boolean;
  hideValue?: boolean;
  onChangeRange: ({ from, to, range }: RangeProps) => void;
}

export enum Range {
  today = 'today',
  last_week = 'last_week',
  last_month = 'last_month',
  specific_date_range = 'specific_date_range',
}

const SelectDateRange = ({
  label,
  name,
  disabled,
  size,
  error,
  fromDate,
  toDate,
  titleOptions,
  withApplyBtn,
  hideValue,
  onChangeRange,
}: SelectDateRangeProps) => {
  const classes = useStyles();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [rangeApplied, setRangeApplied] = useState<RangeProps>({
    from: fromDate || '',
    to: toDate || '',
    range: Range.specific_date_range,
  });

  const [rangeToApply, setRangeToApply] = useState<RangeProps>({
    from: fromDate || '',
    to: toDate || '',
    range: Range.specific_date_range,
  });

  const handleChangeRange = (range: RangeProps) => {
    setRangeToApply(range);
    if (!withApplyBtn) {
      setRangeApplied(range);
      onChangeRange(range);
    }
  };

  const applyChanges = () => {
    setRangeApplied(rangeToApply);
    onChangeRange(rangeToApply);
  };

  const cancelChanges = () => {
    setRangeToApply(rangeApplied);
    setIsMenuOpen(false);
  };

  const selectRef = useRef<HTMLDivElement | null>(null);
  const selectBodyRef = useRef<HTMLDivElement | null>(null);

  const handleOpenMenu = (e: SyntheticEvent) => {
    if (selectRef.current && selectRef.current.contains(e.target as Node)) {
      setIsMenuOpen(true);
    }
  };

  const handleCloseMenu = (e: SyntheticEvent) => {
    // @ts-ignore-next-line
    if (selectBodyRef.current && !selectBodyRef.current.contains(e.target)) {
      setIsMenuOpen(false);
    }
  };

  const clearAll = () => {
    const initRange = {
      from: '',
      to: '',
      range: Range.specific_date_range,
    };
    setRangeApplied(initRange);
    setRangeToApply(initRange);
    onChangeRange(initRange);
  };

  useEffect(() => {
    let from = rangeApplied.from;
    let to = rangeApplied.to;
    let range = rangeApplied.range;

    if ((fromDate || fromDate === '') && fromDate !== rangeApplied.from) {
      from = fromDate;
      range = Range.specific_date_range;
    }
    if ((toDate || toDate === '') && toDate !== rangeApplied.to) {
      to = toDate;
      range = Range.specific_date_range;
    }

    const newRange = { from, to, range };

    setRangeApplied(newRange);
    setRangeToApply(newRange);
  }, [fromDate, toDate]);

  return (
    <FormControl fullWidth variant="outlined" className={classes.formControl} error={!!error} size={size}>
      <InputLabel htmlFor={`select-date-range-${name}`} className={classes.label} {...(hideValue ? { shrink: false } : {})}>
        {label}
      </InputLabel>
      <Select
        ref={selectRef}
        id={`select-date-range-${name}`}
        label={label}
        open={isMenuOpen}
        onClose={e => handleCloseMenu(e)}
        onClick={e => handleOpenMenu(e)}
        className={disabled ? classes.selectDisabled : classes.select}
        value={
          rangeToApply.from || rangeToApply.to
            ? `${rangeApplied.from || l('datepicker.placeholder')}_${rangeToApply.to || l('datepicker.placeholder')}`
            : ''
        }
        renderValue={() =>
          hideValue
            ? ''
            : rangeToApply.from === rangeToApply.to
            ? rangeToApply.from
            : rangeToApply.from || rangeToApply.to
            ? `${rangeToApply.from || l('datepicker.placeholder')} - ${rangeToApply.to || l('datepicker.placeholder')}`
            : ''
        }
        input={hideValue ? <OutlinedInput /> : undefined}
      >
        <div ref={selectBodyRef}>
          {titleOptions && <div className={classes.titleOptions}>{titleOptions}</div>}
          <MenuItem>
            <RadioButton
              checked={Range.today === rangeToApply.range}
              onChange={() => {
                const today = moment().format('YYYY-MM-DD');
                handleChangeRange({ from: today, to: today, range: Range.today });
              }}
              label={l('selectDateRange.today')}
              disabled={disabled}
            />
          </MenuItem>
          <MenuItem>
            <RadioButton
              checked={Range.last_week === rangeToApply.range}
              onChange={() => {
                handleChangeRange({
                  from: moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'),
                  to: moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD'),
                  range: Range.last_week,
                });
              }}
              label={l('selectDateRange.lastWeek')}
              disabled={disabled}
            />
          </MenuItem>
          <MenuItem>
            <RadioButton
              checked={Range.last_month === rangeToApply.range}
              onChange={() => {
                handleChangeRange({
                  from: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
                  to: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
                  range: Range.last_month,
                });
              }}
              label={l('selectDateRange.lastMonth')}
              disabled={disabled}
            />
          </MenuItem>
          <MenuItem>
            <div>
              <RadioButton
                checked={Range.specific_date_range === rangeToApply.range}
                onChange={() => {
                  handleChangeRange({
                    ...rangeToApply,
                    range: Range.specific_date_range,
                  });
                }}
                label={l('selectDateRange.specificDateRange')}
                disabled={disabled}
              />
              <DateRangePicker
                fromDate={rangeToApply.from}
                toDate={rangeToApply.to}
                changeFrom={from =>
                  handleChangeRange({
                    ...rangeToApply,
                    from,
                  })
                }
                changeTo={to =>
                  handleChangeRange({
                    ...rangeToApply,
                    to,
                  })
                }
                size="small"
                vertical
                disabled={disabled || Range.specific_date_range !== rangeToApply.range}
              />
            </div>
          </MenuItem>
          {withApplyBtn && (
            <MenuItem className={classes.buttons}>
              <Button
                variant="text"
                className={classes.cancel}
                onClick={() =>
                  JSON.stringify(rangeApplied) === JSON.stringify(rangeToApply) ? clearAll() : cancelChanges()
                }
                disabled={disabled || (!rangeToApply.from && !rangeToApply.to)}
              >
                {JSON.stringify(rangeApplied) === JSON.stringify(rangeToApply) ? l('common.clearAll') : l('common.cancel')}
              </Button>
              <Button
                className={classes.apply}
                onClick={() => applyChanges()}
                disabled={disabled || JSON.stringify(rangeApplied) === JSON.stringify(rangeToApply)}
              >
                {l('common.apply')}
              </Button>
            </MenuItem>
          )}
        </div>
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default SelectDateRange;
