import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { Refresh } from '@mui/icons-material';
import Loading from '../../common/loading';
import { getRefunds } from '../../../store/refunds/actions';
import Table from '../../common/table';
import icRefunds from '../../../assets/icons/refunds.svg?url';
import { Urls } from '../../../config/urls';
import l from '../../../lang';
import './styles.scss';
import ChipStatus from '../../common/chip-status';
import MessageError from '../../common/message-error';
import { createDate, getMappedCountryCode } from '../../../utils/index';
import TopDashboardSection from '../../common/top-dashboard-section';
import RefundDetail from './detail';
import Dialog from '../../common/dialog';
import ButtonMore from '../../common/button-more';
import RefundFilter from './refund-filter';
import AlertMessages from '../alert-messages';
import BlockedMessage from '../alert-messages/blocked-message';

const Refunds = ({ loading, error, allRefunds, fnGetRefunds }) => {
  const [filter, setFilter] = useState('');
  const [refundDetail, setRefundDetail] = useState(null);
  useEffect(() => {
    fnGetRefunds();
  }, [fnGetRefunds]);

  const dataExist = allRefunds?.data?.length > 0;

  return (
    <>
      <TopDashboardSection
        title={l('menu.item.REFUNDS')}
        subtitle={l('menu.item.REFUNDS.desc')}
        component={
          <RefundFilter
            fnChangeFilters={filterParam => {
              setFilter(filterParam);
              fnGetRefunds(0, filterParam);
            }}
          />
        }
      />
      <AlertMessages />
      <div className={`panel ${dataExist ? 'panel--no-bg' : ''} refunds`}>
        <div className={`refunds__container ${dataExist ? 'refunds__container--data-exist' : ''}`}>
          {loading && (
            <div className="refunds__loading">
              <Loading />
            </div>
          )}
          {error && (
            <div className="refunds__error">
              <MessageError iconClose={<Refresh />} onClose={() => fnGetRefunds()} />
            </div>
          )}
          {!loading && !error && (
            <div className="refunds__table">
              <Table
                idxHiddenColumnsOnMobile={[0, 1, 5, 7]}
                withBtnMenu
                headers={[
                  l('refunds.id'),
                  l('refunds.country'),
                  l('refunds.createDate'),
                  l('refunds.transactionId'),
                  l('refunds.paymentMethod'),
                  l('refunds.localAmount'),
                  l('refunds.balanceAmount'),
                  l('refunds.balanceFee'),
                  l('refunds.status'),
                ]}
                body={
                  (dataExist &&
                    allRefunds?.data?.map(refund => {
                      return [
                        <span>{refund.id}</span>,
                        <img
                          alt={`img-country-${refund.country}`}
                          className="refunds__country"
                          src={`${Urls.flags.replace(':code', getMappedCountryCode(refund.country))}`}
                        />,
                        <Moment format="DD/MM/YYYY">{createDate(refund.date)}</Moment>,
                        <span>{refund.transactionInvoiceId}</span>,
                        <span>{refund.paymentMethod}</span>,
                        <span>
                          {refund.currency} {refund.localAmount}
                        </span>,
                        <span>
                          {refund.balanceCurrency} {refund.balanceAmount}
                        </span>,
                        <span>
                          {refund.balanceCurrency} {refund.balanceFee}
                        </span>,
                        <ChipStatus status={refund.status} text={l(`refunds.status.${refund.status}`)} />,
                        <ButtonMore onClick={() => setRefundDetail(refund)} />,
                      ];
                    })) ||
                  null
                }
                rowsPerPageOptions={[allRefunds?.size]}
                rowsLength={allRefunds?.totalElements}
                rowsPerPage={allRefunds?.size}
                page={allRefunds?.page}
                handleChangePage={(event, newPage) => fnGetRefunds(newPage, filter)}
              />
              {refundDetail && (
                <Dialog
                  dividers={false}
                  titleCenter
                  title={l('refunds.details')}
                  handleClose={() => setRefundDetail(null)}
                  body={<RefundDetail detail={refundDetail} />}
                />
              )}
              {!dataExist && (
                <div className="refunds__no-refunds">
                  <div>
                    <img className="refunds__no-refunds__img" src={icRefunds} alt="img-no-refunds" />
                    <div>{l('refunds.noRefunds')}</div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

Refunds.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  allRefunds: PropTypes.object,
  fnGetRefunds: PropTypes.func,
};

const mapStateToProps = state => ({
  loading: state.refunds.loading,
  error: state.refunds.error,
  allRefunds: state.refunds.allRefunds,
});

const mapDispatchToProps = dispatch => ({
  fnGetRefunds: (page, filter) => dispatch(getRefunds(page, filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Refunds);
