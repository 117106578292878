const availableColors = [
  '#000000',
  '#5B5B5B',
  '#717171',
  '#9B9B9B',
  '#D2D2D2',
  '#E7E7E7',
  '#F0F0F0',
  '#FF0000',
  '#FF8383',
  '#FFC700',
  '#CCFF00',
  '#00AF12',
  '#00770C',
  '#015A0A',
  '#001583',
  '#3F62A5',
  '#32D9F0',
  '#32F0F0',
  '#7A32F0',
  '#4A01C2',
  '#7D805C',
];

export default availableColors;
