import TopDashboardSection from '../../common/top-dashboard-section';
import l from '../../../lang';
import Card from '../../common/card';
import CardTitle from '../../common/card-title';
import CardDescription from '../../common/card-description';
import DataCards from './data-cards';
import './styles.scss';
import PaymentVolume from './charts/payment-volume';
import TransactionsBy from './charts/transactions-by';
import { Button, ButtonGroup } from '@mui/material';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

const ReportTimeOptions = {
  last7Days: 'LAST_7_DAYS',
  currentMonth: 'CURRENT_MONTH',
  lastMonth: 'LAST_MONTH',
};
const AvailablePeriods = Object.values(ReportTimeOptions);

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default () => {
  const queryParams = useQuery();
  const days = 7;

  const queryPeriod = queryParams.get('period');
  const isValidPeriod = queryPeriod && AvailablePeriods.includes(queryPeriod.toUpperCase());

  const [selectedPeriod, setSelectedPeriod] = useState(
    isValidPeriod ? queryPeriod.toUpperCase() : ReportTimeOptions.last7Days
  );

  const handlePeriodChange = (option: string) => {
    window.history.pushState({}, '', `?period=${option.toLowerCase()}`);
    setSelectedPeriod(option);
  };

  return (
    <>
      <TopDashboardSection title={l('reports.headline')} />
      <Card>
        <CardTitle>{l('reports.subheadline')}</CardTitle>
        <CardDescription>{l('reports.description')}</CardDescription>
        {/* <div className="reports_pill">{l('reports.tag', period)}</div> */}
        <ButtonGroup className="reports-time-options" variant="outlined" aria-label="reports-options">
          <Button
            variant={selectedPeriod === ReportTimeOptions.last7Days ? 'contained' : 'outlined'}
            onClick={() => handlePeriodChange(ReportTimeOptions.last7Days)}
          >
            {l('reports.tag.last_7_days')}
          </Button>
          <Button
            variant={selectedPeriod === ReportTimeOptions.currentMonth ? 'contained' : 'outlined'}
            onClick={() => handlePeriodChange(ReportTimeOptions.currentMonth)}
          >
            {l('reports.tag.current_month')}
          </Button>
          <Button
            variant={selectedPeriod === ReportTimeOptions.lastMonth ? 'contained' : 'outlined'}
            onClick={() => handlePeriodChange(ReportTimeOptions.lastMonth)}
          >
            {l('reports.tag.last_month')}
          </Button>
        </ButtonGroup>
        <DataCards days={days} period={selectedPeriod} />
        <PaymentVolume days={days} period={selectedPeriod} />
        <TransactionsBy days={days} period={selectedPeriod} />
      </Card>
    </>
  );
};
