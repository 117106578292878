import { handleErrorInfo } from '../../utils';
import { CheckoutThemesActionType } from './actions';
import { CheckoutThemeState } from './interfaces';

const initialState: CheckoutThemeState = {
  loading: true,
  formLoading: false,
  error: false,
  errorInfo: undefined,
  allCheckoutThemes: undefined,
  showCreateCheckoutTheme: false,
  showUpdateCheckoutTheme: false,
  requestSuccess: false,
  updateTheme: undefined,
};

const CheckoutThemesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CheckoutThemesActionType.SHOW_CREATE_CHECKOUT_THEME: {
      return {
        ...state,
        showCreateCheckoutTheme: action.show,
        requestSuccess: action.show ? state.requestSuccess : false,
        error: false,
        errorInfo: undefined,
      };
    }
    case CheckoutThemesActionType.GET_CHECKOUT_THEMES: {
      return {
        ...state,
        loading: true,
        error: false,
        errorInfo: undefined,
      };
    }
    case CheckoutThemesActionType.GET_CHECKOUT_THEMES_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        errorInfo: {},
        allCheckoutThemes: action.response,
      };
    }
    case CheckoutThemesActionType.GET_CHECKOUT_THEMES_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        errorInfo: null,
      };
    }
    case CheckoutThemesActionType.POST_CREATE_CHECKOUT_THEME: {
      return {
        ...state,
        formLoading: true,
        error: false,
        errorInfo: undefined,
      };
    }
    case CheckoutThemesActionType.POST_CREATE_CHECKOUT_THEME_SUCCESS: {
      return {
        ...state,
        loading: false,
        formLoading: false,
        error: false,
        errorInfo: null,
        requestSuccess: true,
        allCheckoutThemes: {
          ...state.allCheckoutThemes,
          data: [action.response, ...(state.allCheckoutThemes?.data ?? [])],
        },
      };
    }
    case CheckoutThemesActionType.HIDE_CHECKOUT_THEME_ERROR: {
      return {
        ...state,
        error: false,
        errorInfo: undefined,
      };
    }
    case CheckoutThemesActionType.TOGGLE_CHECKOUT_THEME: {
      return {
        ...state,
        loading: true,
        error: false,
        errorInfo: undefined,
      };
    }
    case CheckoutThemesActionType.TOGGLE_CHECKOUT_THEME_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        errorInfo: handleErrorInfo(CheckoutThemesActionType.TOGGLE_CHECKOUT_THEME_ERROR, action.error),
      };
    }
    case CheckoutThemesActionType.TOGGLE_CHECKOUT_THEME_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        errorInfo: undefined,
      };
    }
    case CheckoutThemesActionType.CHANGE_UPDATE_CHECKOUT_THEME: {
      return {
        ...state,
        showUpdateCheckoutTheme: !!action.theme,
        updateTheme: action.theme,
        error: false,
        requestSuccess: false,
      };
    }
    default:
      return state;
  }
};

export default CheckoutThemesReducer;
