// @ts-nocheck
import { useRef, useEffect } from 'react';
import { Formik } from 'formik';
import _ from 'lodash';
import Parser from 'html-react-parser';
import Grid from '@mui/material/Grid';
import { RadioGroup, useMediaQuery } from '@mui/material';
import PopupInElement from '../../../../common/popup-in-element';
import Input from '../../../../common/input';
import { kycValidations } from '../../../../../utils/validations';
import ButtonCustom from '../../../../common/__deprecated__/button-custom';
import Select from '../../../../common/select';
import icInfo from '../../../../../assets/icons/info-filled.svg?url';
import l, { langKeyExists } from '../../../../../lang';
import { ReactComponent as BuildingIcon } from '../../../../../assets/icons/building.svg';
import { ReactComponent as BriefcaseIcon } from '../../../../../assets/icons/briefcase.svg';
import { ReactComponent as UserIcon } from '../../../../../assets/icons/user.svg';
import { operatingCountriesForAutocomplete, setDefaultDocumentType } from '../../../../../utils/common-utils';
import MessageError from '../../../../common/message-error';
import RadioButton from '../../../../common/radio-button';
import { functionalCurrenciesByCountry } from '../../../../../config';
import { removeProtocolFromUrl, scrollIntoView } from '../../../../../utils';
import Card from '../../../../common/card';
import CardTitle from '../../../../common/card-title';
import CardDescription from '../../../../common/card-description';
import { FormObserver } from './form-observer';
import { Autocomplete } from '../../../../common/autocomplete';
import InputCountryPhone from '../../../../common/input-country-phone';
import { segmentKycFormL1 } from '../../../../../utils/analytics';
import { useCompanyInfo } from '../../../../../store/company-information';
import { useDashboard } from '../../../../../store/dashboard';
import { KYCLevel1Props, ValuesLevelOne } from './index';
import { AccountCreatorRole, MerchantFeatures } from '../../../../../constants';
import { FeatureToggle, On, Off, useFeature } from '../../../../../hooks/use-features';
import labels from './../config/labels';

export const KYCLevel1Form = ({ setOnboardingMenu }: KYCLevel1Props) => {
  const {
    loading,
    error,
    errorInfo,
    errorFields,
    success,
    industries,
    documentTypesForCompany,
    documentTypesForNaturalPerson,
    getIndustries,
    getDocumentTypesForKYC,
    sendKYC,
  } = useCompanyInfo();
  const { userMe, merchantMe } = useDashboard();
  const { hasFeature } = useFeature();
  const language = userMe?.language;
  const isCompany = merchantMe?.company as string;
  const isTabletMobile = useMediaQuery('(max-width:768px)');
  const errorRef = useRef(null);

  useEffect(() => {
    getIndustries();
  }, [language]);

  useEffect(() => {
    if (error) {
      scrollIntoView(errorRef);
    }
  }, [error]);

  useEffect(() => {
    // Necessary to show errors after try to submit data.
    if (errorFields) {
      document.getElementById('btn-kyc-level-1')?.click();
    }
  }, [errorFields]);

  const initialValues: ValuesLevelOne = {
    company: hasFeature(MerchantFeatures.ONBOARD_BY_COMPANY_TYPE) ? isCompany : null,
    name: '',
    lastName: '',
    fantasyName: '',
    country: '',
    documentType: '',
    document: '',
    website: '',
    phone: '',
    subIndustryId: '',
    functionalCurrency: '',
    accountCreatorName: '',
    accountCreatorRole: '',
  };

  const getFunctionalCurrencies = (country: string) => {
    const currencies = {};
    if (!country) return {};

    const localCurrency = functionalCurrenciesByCountry[country];
    currencies[localCurrency] = l(`common.currency.${localCurrency}`);

    if (functionalCurrenciesByCountry[country] !== 'USD') {
      currencies.USD = l('common.currency.USD');
    }
    return currencies;
  };

  const getDocumentTextForTooltip = (country: string, document: string) => {
    const key = `companyInformation.kyc.infopopup.${country}.${document}`;
    return langKeyExists(key) ? l(key) : null;
  };

  const getIndustryOptions = () => {
    const options = [];
    industries?.forEach(industry => {
      industry.subIndustries?.forEach(subI => {
        options.push({
          category: industry.description,
          value: `${subI.id}${!subI.allowed ? flagIndustryNotAllowed : ''}`,
          label: subI.description,
        });
      });
    });
    return options.sort((a, b) => -b.category.localeCompare(a.category));
  };

  const getAccountCreatorRole = () => {
    if (isCompany) {
      return {
        [AccountCreatorRole.CFO]: l(`companyInformation.kyc.companyForm.roles.${AccountCreatorRole.CFO}`),
        [AccountCreatorRole.CTO]: l(`companyInformation.kyc.companyForm.roles.${AccountCreatorRole.CTO}`),
        [AccountCreatorRole.CCO]: l(`companyInformation.kyc.companyForm.roles.${AccountCreatorRole.CCO}`),
        [AccountCreatorRole.CIO]: l(`companyInformation.kyc.companyForm.roles.${AccountCreatorRole.CIO}`),
        [AccountCreatorRole.CMO]: l(`companyInformation.kyc.companyForm.roles.${AccountCreatorRole.CMO}`),
        [AccountCreatorRole.FINANCIAL_OFFICER_MANAGER]: l(
          `companyInformation.kyc.companyForm.roles.${AccountCreatorRole.FINANCIAL_OFFICER_MANAGER}`
        ),
        [AccountCreatorRole.TECHNOLOGY_OFFICER_MANAGER]: l(
          `companyInformation.kyc.companyForm.roles.${AccountCreatorRole.TECHNOLOGY_OFFICER_MANAGER}`
        ),
        [AccountCreatorRole.COMMERCIAL_MANAGER]: l(
          `companyInformation.kyc.companyForm.roles.${AccountCreatorRole.COMMERCIAL_MANAGER}`
        ),
        [AccountCreatorRole.DIRECTOR_OF_OPERATIONS]: l(
          `companyInformation.kyc.companyForm.roles.${AccountCreatorRole.DIRECTOR_OF_OPERATIONS}`
        ),
        [AccountCreatorRole.MARKETING_MANAGER]: l(
          `companyInformation.kyc.companyForm.roles.${AccountCreatorRole.MARKETING_MANAGER}`
        ),
        [AccountCreatorRole.ECOMMERCE_MANAGER]: l(
          `companyInformation.kyc.companyForm.roles.${AccountCreatorRole.ECOMMERCE_MANAGER}`
        ),
        [AccountCreatorRole.LEGAL_MANAGER]: l(
          `companyInformation.kyc.companyForm.roles.${AccountCreatorRole.LEGAL_MANAGER}`
        ),
        [AccountCreatorRole.PROJECT_MANAGER]: l(
          `companyInformation.kyc.companyForm.roles.${AccountCreatorRole.PROJECT_MANAGER}`
        ),
        [AccountCreatorRole.OTHER]: l(`companyInformation.kyc.companyForm.roles.${AccountCreatorRole.OTHER}`),
      };
    }
    return {
      [AccountCreatorRole.EMPLOYEE]: l(`companyInformation.kyc.companyForm.roles.${AccountCreatorRole.EMPLOYEE}`),
      [AccountCreatorRole.OWNER]: l(`companyInformation.kyc.companyForm.roles.${AccountCreatorRole.OWNER}`),
      [AccountCreatorRole.PARTNER]: l(`companyInformation.kyc.companyForm.roles.${AccountCreatorRole.PARTNER}`),
      [AccountCreatorRole.LEGAL_REPRESENTATIVE]: l(
        `companyInformation.kyc.companyForm.roles.${AccountCreatorRole.LEGAL_REPRESENTATIVE}`
      ),
      [AccountCreatorRole.OTHER]: l(`companyInformation.kyc.companyForm.roles.${AccountCreatorRole.OTHER}`),
    };
  };

  const flagIndustryNotAllowed = '_NOT_ALLOWEB';
  const validate = (values: ValuesLevelOne) => {
    const errors: ValuesLevelOne = {};
    if (!values.name) {
      errors.name = l('companyInformation.kyc.required');
    } else if (!kycValidations.nameValid(values.name)) {
      errors.name = l('companyInformation.kyc.tooShort');
    } else if (errorFields?.name?.value === values.name || errorFields?.name === values.name) {
      errors.name = l(errorFields?.name?.message || 'companyInformation.kyc.invalid');
    }
    if (!values.company) {
      if (!values.lastName) {
        errors.lastName = l('companyInformation.kyc.required');
      } else if (!kycValidations.nameValid(values.lastName)) {
        errors.lastName = l('companyInformation.kyc.tooShort');
      } else if (errorFields?.lastName?.value === values.lastName || errorFields?.lastName === values.lastName) {
        errors.lastName = l(errorFields?.lastName?.message || 'companyInformation.kyc.invalid');
      }
    }
    if (!values.country) {
      errors.country = l('companyInformation.kyc.required');
    }
    if (!values.documentType) {
      errors.documentType = l('companyInformation.kyc.required');
    }
    if (!values.document) {
      errors.document = l('companyInformation.kyc.required');
    } else if (errorFields?.document?.value === values.document || errorFields?.document === values.document) {
      errors.document = l(errorFields?.document?.message || 'companyInformation.kyc.invalid');
    }
    if (!values.website) {
      errors.website = l('companyInformation.kyc.required');
    } else if (values.website && !kycValidations.webSiteWihtoutHttp(values.website)) {
      errors.website = l('companyInformation.kyc.invalid');
    } else if (removeProtocolFromUrl(errorFields?.website?.value || errorFields?.website) === values.website) {
      errors.website = l(errorFields?.website?.message || 'companyInformation.kyc.invalid');
    }
    if (!values.phone) {
      errors.phone = l('companyInformation.kyc.required');
    } else if (errorFields?.phone?.value === values.phone || errorFields?.phone === values.phone) {
      errors.phone = l(errorFields?.phone?.message || 'companyInformation.kyc.invalid');
    }
    if (!values.subIndustryId) {
      errors.subIndustryId = l('companyInformation.kyc.required');
    } else if (values.subIndustryId.includes(flagIndustryNotAllowed)) {
      errors.subIndustryId = l('companyInformation.kyc.subIndustryNotSupported');
    }
    if (!values.fantasyName) {
      errors.fantasyName = l('companyInformation.kyc.required');
    }
    if (!values.functionalCurrency) {
      errors.functionalCurrency = l('companyInformation.kyc.required');
    }
    if (values.company) {
      if (!values.accountCreatorName) {
        errors.accountCreatorName = l('companyInformation.kyc.required');
      }
      if (!values.accountCreatorRole) {
        errors.accountCreatorRole = l('companyInformation.kyc.required');
      }
    }

    return errors;
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={7}>
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={values => {
            let valuesToSend = {};
            if (values.company) {
              valuesToSend = {
                company: true,
                name: values.name,
                fantasyName: values.fantasyName,
                country: values.country,
                documentType: values.documentType,
                document: values.document,
                website: `https://${values.website}`,
                phone: values.phone,
                subIndustryId: values.subIndustryId,
                functionalCurrency: values.functionalCurrency,
                accountCreatorName: values.accountCreatorName,
                accountCreatorRole: values.accountCreatorRole,
              };
            } else {
              valuesToSend = {
                company: false,
                name: values.name,
                lastName: values.lastName,
                fantasyName: values.fantasyName,
                country: values.country,
                documentType: values.documentType,
                document: values.document,
                website: `https://${values.website}`,
                phone: values.phone,
                subIndustryId: values.subIndustryId,
                functionalCurrency: values.functionalCurrency,
              };
            }
            sendKYC(valuesToSend);
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
            const documentTypes = values.company ? documentTypesForCompany : documentTypesForNaturalPerson;
            const documentTypesKeys = Object.keys(documentTypes);
            documentTypesKeys.forEach(key => {
              documentTypes[key] = langKeyExists(`companyInformation.kyc.documentTypes.${key}`)
                ? l(`companyInformation.kyc.documentTypes.${key}`)
                : key;
            });
            setDefaultDocumentType(documentTypes, 'documentType', values.documentType, setFieldValue);
            return (
              <>
                <FormObserver onChangeOnboardingMenu={menu => setOnboardingMenu(menu)} />
                <div className="kyc-form-container">
                  <Card>
                    <CardTitle>{labels.title(hasFeature, { isCompany })}</CardTitle>
                    <CardDescription>{labels.subtitle(hasFeature, { isCompany })}</CardDescription>
                  </Card>
                </div>
                <FeatureToggle feature={MerchantFeatures.ONBOARD_BY_COMPANY_TYPE}>
                  <Off>
                    <div className="kyc-form-container">
                      <Card>
                        <CardTitle>{l('companyInformation.kyc.companyForm.businessType')}</CardTitle>
                        <Grid item xs={12}>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            <RadioButton
                              label={l('companyInformation.kyc.company')}
                              name="company"
                              checked={values.company === true}
                              onChange={() => {
                                segmentKycFormL1('company');
                                setFieldValue('company', true);
                                setFieldValue('documentType', '');
                                setFieldValue('document', '');
                              }}
                            />
                            <RadioButton
                              label={l('companyInformation.kyc.naturalPerson')}
                              name="naturalPerson"
                              checked={values.company === false}
                              onChange={() => {
                                segmentKycFormL1('individual');
                                setFieldValue('company', false);
                                setFieldValue('document', '');
                              }}
                            />
                          </RadioGroup>
                        </Grid>
                      </Card>
                    </div>
                  </Off>
                </FeatureToggle>
                {values.company !== null && (
                  <div className="kyc-form-container">
                    <Card>
                      <form onSubmit={handleSubmit}>
                        <div className={`kyc-level-1__container ${success ? 'kyc-level-1__container--hide' : ''}`}>
                          <FeatureToggle feature={MerchantFeatures.ONBOARD_BY_COMPANY_TYPE}>
                            <Off>
                              <div className="kyc-level-1__common-desc">
                                <h2>{l('companyInformation.kyc.companyForm.businessData')}</h2>
                                <p>{l('companyInformation.kyc.companyForm.allDataRequired')}</p>
                              </div>
                            </Off>
                          </FeatureToggle>

                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <h2 className="kyc-title">
                                <BuildingIcon />
                                <span>{l('companyInformation.kyc.companyForm.businessInformation')}</span>
                              </h2>
                              <PopupInElement
                                disabled={hasFeature(MerchantFeatures.ONBOARD_BY_COMPANY_TYPE) && !isCompany}
                                top={isTabletMobile}
                                Content={<p>{Parser(l('companyInformation.kyc.infopopup.businessName'))}</p>}
                              >
                                <Input
                                  name="fantasyName"
                                  label={labels.companyNameOrBrand(hasFeature, { isCompany })}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.fantasyName}
                                  maxLength={100}
                                  disabled={loading}
                                  error={
                                    (touched.fantasyName && errors.fantasyName) ||
                                    (errorFields &&
                                      errorFields.fantasyName &&
                                      errorFields.fantasyName === values.fantasyName &&
                                      l('companyInformation.kyc.invalid')) ||
                                    ''
                                  }
                                />
                              </PopupInElement>
                            </Grid>

                            <Grid item xs={12}>
                              <Autocomplete
                                name="subIndustryId"
                                label={labels.subIndustry(hasFeature, { isCompany })}
                                value={values.subIndustryId}
                                onChange={e => setFieldValue('subIndustryId', e.target.value)}
                                onBlur={handleBlur}
                                options={getIndustryOptions()}
                                groupBy={option => option.category}
                                disabled={_.isEmpty(industries) || loading}
                                error={!!(touched.subIndustryId && errors.subIndustryId)}
                                helperText={(touched.subIndustryId && errors.subIndustryId) || undefined}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <PopupInElement
                                top={isTabletMobile}
                                Content={
                                  <>
                                    <p>{labels.websiteTooltip(hasFeature, { isCompany })}</p>
                                    <FeatureToggle feature={MerchantFeatures.ONBOARD_BY_COMPANY_TYPE}>
                                      <Off>
                                        <span className="kyc-warning_title">
                                          <img src={icInfo} alt="ic-info" />{' '}
                                          <p>{l('companyInformation.kyc.infopopup.website.2')}</p>
                                        </span>
                                      </Off>
                                    </FeatureToggle>
                                  </>
                                }
                              >
                                <Input
                                  name="website"
                                  label={l('companyInformation.kyc.website')}
                                  placeholder={l('companyInformation.kyc.website.placeholder')}
                                  onChange={e => setFieldValue('website', removeProtocolFromUrl(e.target.value))}
                                  onBlur={handleBlur}
                                  value={values.website}
                                  maxLength={100}
                                  disabled={loading}
                                  error={
                                    (touched.website && errors.website) ||
                                    (errorFields &&
                                      errorFields.website &&
                                      errorFields.website === values.website &&
                                      l('companyInformation.kyc.invalid')) ||
                                    ''
                                  }
                                />
                              </PopupInElement>
                            </Grid>

                            <Grid item xs={12}>
                              <h2 className="kyc-title">
                                <BriefcaseIcon />
                                <span>{l('companyInformation.kyc.companyForm.legalInformation')}</span>
                              </h2>
                              <PopupInElement top={isTabletMobile} Content={labels.nameTooltip(hasFeature, { isCompany })}>
                                <Input
                                  name="name"
                                  label={labels.name(hasFeature, { isCompany })}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.name}
                                  maxLength={100}
                                  disabled={loading}
                                  error={
                                    (touched.name && errors.name) ||
                                    (errorFields &&
                                      errorFields.name &&
                                      errorFields.name === values.name &&
                                      l('companyInformation.kyc.invalid')) ||
                                    ''
                                  }
                                />
                              </PopupInElement>
                            </Grid>

                            {!values.company && (
                              <Grid item xs={12}>
                                <PopupInElement
                                  top={isTabletMobile}
                                  Content={<p>{l('companyInformation.kyc.infopopup.legalSurnamePerson')}</p>}
                                >
                                  <Input
                                    name="lastName"
                                    label={l('companyInformation.kyc.lastName')}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.lastName}
                                    maxLength={100}
                                    disabled={loading}
                                    error={
                                      (touched.lastName && errors.lastName) ||
                                      (errorFields &&
                                        errorFields.lastName &&
                                        errorFields.lastName === values.lastName &&
                                        l('companyInformation.kyc.invalid')) ||
                                      ''
                                    }
                                  />
                                </PopupInElement>
                              </Grid>
                            )}

                            <Grid item xs={12}>
                              <PopupInElement
                                top={isTabletMobile}
                                Content={labels.countryTooltip(hasFeature, { isCompany })}
                              >
                                <Autocomplete
                                  name="country"
                                  label={labels.country(hasFeature, { isCompany })}
                                  value={values.country}
                                  onChange={e => {
                                    getDocumentTypesForKYC(e.target.value);
                                    setFieldValue('country', e.target.value);
                                    setFieldValue('functionalCurrency', '');
                                  }}
                                  onBlur={handleBlur}
                                  options={operatingCountriesForAutocomplete()}
                                  disabled={loading}
                                  error={!!(touched.country && errors.country)}
                                  helperText={(touched.country && errors.country) || undefined}
                                />
                              </PopupInElement>
                            </Grid>

                            {documentTypesKeys?.length !== 1 && (
                              <Grid item xs={6}>
                                <Select
                                  name="documentType"
                                  label={l('companyInformation.kyc.documentType')}
                                  value={values.documentType}
                                  onChange={handleChange}
                                  options={documentTypes}
                                  disabled={_.isEmpty(documentTypes) || !values.country || loading}
                                  error={touched.documentType && errors.documentType}
                                />
                              </Grid>
                            )}
                            <Grid item xs={documentTypesKeys?.length === 1 ? 12 : 6}>
                              <PopupInElement
                                top={isTabletMobile}
                                Content={getDocumentTextForTooltip(values.country, values.documentType)}
                              >
                                <Input
                                  name="document"
                                  label={
                                    documentTypesKeys?.length === 1
                                      ? documentTypes[documentTypesKeys[0]]
                                      : l('companyInformation.kyc.document')
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.document}
                                  maxLength={50}
                                  disabled={_.isEmpty(documentTypes) || !values.country || loading}
                                  error={
                                    (touched.document && errors.document) ||
                                    (errorFields &&
                                      errorFields.document &&
                                      errorFields.document === values.document &&
                                      l('companyInformation.kyc.invalid')) ||
                                    ''
                                  }
                                />
                              </PopupInElement>
                            </Grid>

                            <Grid item xs={12}>
                              <PopupInElement
                                top={isTabletMobile}
                                Content={<p>{Parser(l('companyInformation.kyc.infopopup.functionalCurrency'))}</p>}
                              >
                                <Select
                                  label={l('functionalCurrency.label')}
                                  onChange={handleChange}
                                  value={values.functionalCurrency}
                                  name="functionalCurrency"
                                  disabled={loading}
                                  options={getFunctionalCurrencies(values.country)}
                                  error={(touched.functionalCurrency && errors.functionalCurrency) || undefined}
                                />
                              </PopupInElement>
                            </Grid>

                            <Grid item xs={12}>
                              <h2 className="kyc-title">
                                <UserIcon />
                                <span>
                                  {values.company
                                    ? l('companyInformation.kyc.companyForm.dataWhoRegistersAccount')
                                    : l('companyInformation.kyc.companyForm.contactInformation')}
                                </span>
                              </h2>
                              {values.company && (
                                <PopupInElement
                                  top={isTabletMobile}
                                  Content={<p>{l('companyInformation.kyc.infopopup.accountCreatorName')}</p>}
                                >
                                  <Input
                                    name="accountCreatorName"
                                    label={l('accountCreatorName.label')}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.accountCreatorName}
                                    maxLength={50}
                                    disabled={loading}
                                    error={(touched.accountCreatorName && errors.accountCreatorName) || undefined}
                                  />
                                </PopupInElement>
                              )}
                            </Grid>

                            {values.company && (
                              <Grid item xs={12}>
                                <Select
                                  label={l('accountCreatorRole.label')}
                                  onChange={handleChange}
                                  value={values.accountCreatorRole}
                                  name="accountCreatorRole"
                                  disabled={loading}
                                  options={getAccountCreatorRole()}
                                  error={(touched.accountCreatorRole && errors.accountCreatorRole) || undefined}
                                />
                              </Grid>
                            )}

                            <Grid item xs={12}>
                              <InputCountryPhone
                                type="number"
                                name="phone"
                                label={l('companyInformation.kyc.phone')}
                                onChange={value => setFieldValue('phone', value)}
                                onBlur={handleBlur}
                                value={values.phone}
                                defaultCountry={values.country}
                                disabled={!values.country || loading}
                                error={touched.phone && errors.phone}
                              />
                            </Grid>

                            <Grid item xs={12} ref={errorRef}>
                              {error && (
                                <div className="kyc-level-1__error">
                                  <MessageError errorInfo={errorInfo} />
                                </div>
                              )}
                            </Grid>
                          </Grid>
                        </div>
                        {!success && (
                          <div className="kyc-level-1__btn">
                            <ButtonCustom
                              name="btn-kyc-level-1"
                              type="submit"
                              fullWidth
                              variant="contained"
                              text={l('companyInformation.send')}
                              textLoading={l('form.sending')}
                              loading={loading}
                            />
                          </div>
                        )}
                      </form>
                    </Card>
                  </div>
                )}
              </>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
};
