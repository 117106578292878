import './styles.scss';
import logoDlGO from '../../../../assets/images/dlocalGo.svg?url';

export const PrivacyPoliciesPT = () => {
  return (
    <div className="privacy_policies_pt">
      <div className="privacy_policies_pt__header">
        <span className="privacy_policies_pt__title">Aviso de Privacidade</span>
        <img className="privacy_policies_pt__logo" src={logoDlGO} alt="" />
      </div>
      <div className="privacy_policies_pt__content">
        {/* CONTENT EXPORTED DIRECTLY FROM GOOGLE DOC */}
        <div className="c29 doc-content">
          <p className="c9">
            <span className="c3">&Uacute;ltima atualiza&ccedil;&atilde;o:</span>
            <span className="c1">&nbsp;8 de junho de 2022.&nbsp;&nbsp; </span>
          </p>
          <p className="c11">
            <span className="c3">Este &eacute; o aviso de privacidade da dlocal llp</span>
          </p>
          <ol className="c10 lst-kix_mc2q6bh01h67-0 start" start={1}>
            <li className="c2 c20 li-bullet-0">
              <span className="c15 c3">Introdu&ccedil;&atilde;o</span>
            </li>
          </ol>
          <p className="c11">
            <span className="c1">
              Sua privacidade &eacute; muito importante para n&oacute;s. Temos o compromisso de proteger seus Dados Pessoais.
              O objetivo deste Aviso de Privacidade &eacute; inform&aacute;-lo sobre a forma como tratamos seus Dados
              Pessoais, incluindo refer&ecirc;ncias a quais dados tratamos, como, por que e por quanto tempo, al&eacute;m de
              informa&ccedil;&otilde;es sobre seus direitos como Titular dos Dados.
            </span>
          </p>
          <p className="c11">
            <span className="c7">Este Aviso de Privacidade (</span>
            <span className="c7 c14">juntamente com nossos Termos do Site disponibilizados </span>
            <span className="c22 c7 c14">
              <a className="c12" href="https://dlocalgo.com/pt/legal/terminos-y-condiciones">
                AQUI
              </a>
            </span>
            <span className="c1">
              &nbsp;e quaisquer outros documentos neles mencionados) estabelece a base na qual quaisquer de seus Dados
              Pessoais coletados ou fornecidos ser&atilde;o tratados por n&oacute;s. Este Aviso de Privacidade tamb&eacute;m
              estabelece como voc&ecirc; pode nos informar caso prefira limitar o uso desses Dados Pessoais, bem como os
              procedimentos que temos em vigor para proteger sua privacidade.
            </span>
          </p>
          <p className="c11">
            <span className="c1">
              &Eacute; importante que voc&ecirc; leia este Aviso de Privacidade junto com qualquer outro aviso de privacidade
              ou aviso de tratamento de dados que possamos fornecer em ocasi&otilde;es espec&iacute;ficas ao coletarmos ou
              tratarmos seus Dados Pessoais, de forma que voc&ecirc; esteja plenamente ciente sobre como e por que estamos
              utilizando seus Dados Pessoais. Este Aviso de Privacidade complementa os demais avisos e n&atilde;o tem o
              objetivo de substitu&iacute;-los.
            </span>
          </p>
          <ol className="c10 lst-kix_mc2q6bh01h67-0" start={2}>
            <li className="c2 c20 li-bullet-0">
              <span className="c3 c15">Informa&ccedil;&otilde;es importantes</span>
            </li>
          </ol>
          <p className="c19">
            <span className="c3 c4">Empresa</span>
          </p>
          <p className="c27 c28">
            <span className="c7">
              O endere&ccedil;o da Dlocal LLP &eacute; 4 King&#39;s Bench Walk, Londres EC4Y 7DL, Reino Unido.
            </span>
            <span className="c7 c31">&nbsp;</span>
          </p>
          <p className="c19">
            <span className="c7">
              A Dlocal LLP poder&aacute; atuar como Controladora de Dados Pessoais ou como Operadora de Dados Pessoais e
              &eacute; designada neste Aviso de Privacidade como &ldquo;
            </span>
            <span className="c3">dLocal</span>
            <span className="c7">&rdquo;, &ldquo;</span>
            <span className="c3">n&oacute;s</span>
            <span className="c7">&rdquo; ou &ldquo;</span>
            <span className="c3">nosso</span>
            <span className="c1">&rdquo;.</span>
          </p>
          <p className="c19">
            <span className="c4 c3">
              Indiv&iacute;duos dos quais coletamos Dados Pessoais (os &ldquo;Titulares dos Dados&rdquo;)
            </span>
          </p>
          <p className="c9">
            <span className="c7">Neste Aviso de Privacidade,&nbsp;</span>
            <span className="c3">&ldquo;voc&ecirc;&rdquo;</span>
            <span className="c7">&nbsp;ou&nbsp;</span>
            <span className="c3">&ldquo;seu&rdquo;</span>
            <span className="c7">
              &nbsp;refere-se um indiv&iacute;duo que &eacute; o Titular dos Dados Pessoais que tratamos na qualidade de
              controladora de dados, que normalmente seria: os visitantes de nosso site em{' '}
            </span>
            <span className="c7 c18">
              <a className="c12" href="http://www.dlocalgo.com">
                www.dlocalgo.com
              </a>
            </span>
            <span className="c7">&nbsp; (nosso</span>
            <span className="c3">&nbsp;&ldquo;Site&rdquo;</span>
            <span className="c7">
              ); e (ii) os representantes e usu&aacute;rios finais de comerciantes online e outros provedores de pagamento
              (nossos&nbsp;
            </span>
            <span className="c3">&ldquo;Clientes&rdquo;</span>
            <span className="c1">
              ), que interagem conosco, abrem contas de usu&aacute;rio conosco e acessam nossa plataforma para receber nossos
              servi&ccedil;os de processamento de pagamentos.
            </span>
          </p>
          <p className="c11">
            <span className="c7">Para efeitos deste Aviso de Privacidade, </span>
            <span className="c3">&ldquo;Legisla&ccedil;&atilde;o de Prote&ccedil;&atilde;o de Dados&rdquo; </span>
            <span className="c7">
              significa (i) a Lei de Prote&ccedil;&atilde;o de Dados do Reino Unido de 2018; e (ii) o GDPR conforme alterado
              e adotado pela lei do Reino Unido em virtude do artigo 3 da Lei sobre a (Sa&iacute;da da) Uni&atilde;o Europeia
              de 2018 (o{' '}
            </span>
            <span className="c3">&ldquo;GDPR do Reino Unido&rdquo;</span>
            <span className="c1">).</span>
          </p>
          <p className="c19">
            <span className="c4 c3">Informa&ccedil;&otilde;es de Contato</span>
          </p>
          <p className="c9">
            <span className="c7">Nomeamos um encarregado de prote&ccedil;&atilde;o de dados (</span>
            <span className="c7 c21">data protection officer</span>
            <span className="c7">
              &nbsp;- DPO) que &eacute; respons&aacute;vel por supervisionar quest&otilde;es relacionadas a este Aviso de
              Privacidade. Caso tenha alguma d&uacute;vida sobre este Aviso de Privacidade, incluindo quaisquer
              solicita&ccedil;&otilde;es de exerc&iacute;cio de seus direitos legais, entre em contato conosco pelo
              e-mail:&nbsp;
            </span>
            <span className="c17 c7">
              <a className="c12" href="mailto:dpo@dlocal.com">
                dpo@dlocal.com
              </a>
            </span>
          </p>
          <p className="c11">
            <span className="c7">
              Voc&ecirc; tem o direito de, a qualquer momento, apresentar uma reclama&ccedil;&atilde;o &agrave; autoridade de
              supervis&atilde;o competente com rela&ccedil;&atilde;o a quest&otilde;es de prote&ccedil;&atilde;o de dados: o{' '}
            </span>
            <span className="c7 c21">Information Commissioner&rsquo;s Office</span>
            <span className="c7">&nbsp;do Reino Unido (</span>
            <span className="c17 c7">
              <a className="c12" href="https://ico.org.uk/">
                https://ico.org.uk/
              </a>
            </span>
            <span className="c1">).</span>
          </p>
          <p className="c11">
            <span className="c1">
              No entanto, gostar&iacute;amos de ter a oportunidade de tratar suas preocupa&ccedil;&otilde;es antes de
              recorrer ao ICO. Portanto, em primeiro lugar, entre em contato conosco.
            </span>
          </p>
          <p className="c2">
            <span className="c15 c3">3. Informa&ccedil;&otilde;es sobre voc&ecirc; que podemos coletar (ou receber)</span>
          </p>
          <p className="c5">
            <span className="c1"></span>
          </p>
          <p className="c9">
            <span className="c1">Como seus Dados Pessoais s&atilde;o coletados:</span>
          </p>
          <ol className="c10 lst-kix_list_1-0 start" start={1}>
            <li className="c13 li-bullet-0">
              <span className="c3">Intera&ccedil;&otilde;es diretas.</span>
              <span className="c1">
                &nbsp;Voc&ecirc; poder&aacute; nos fornecer seus Dados de Identidade e de Contrato (conforme definido abaixo)
                ao preencher formul&aacute;rios ou ao se comunicar conosco por correio, telefone, e-mail ou de outra maneira.
                Isso inclui Dados Pessoais que voc&ecirc; fornece como representante de um Cliente ou como um Cliente em
                potencial quando:
              </span>
            </li>
          </ol>
          <ol className="c10 lst-kix_list_1-1 start" start={1}>
            <li className="c9 c30 li-bullet-0">
              <span className="c1">Solicita ou pergunta sobre nossos produtos ou servi&ccedil;os;</span>
            </li>
            <li className="c26 li-bullet-0">
              <span className="c1">
                Interage conosco com rela&ccedil;&atilde;o aos nossos servi&ccedil;os ou &agrave; nossa rela&ccedil;&atilde;o
                com o Cliente que voc&ecirc; representa;
              </span>
            </li>
            <li className="c26 li-bullet-0">
              <span className="c1">
                Cria uma conta de usu&aacute;rio do Cliente em nossa plataforma de servi&ccedil;os de processamento de
                pagamentos para receber nossos servi&ccedil;os; ou
              </span>
            </li>
            <li className="c26 li-bullet-0">
              <span className="c1">Assina nossos servi&ccedil;os ou publica&ccedil;&otilde;es.</span>
            </li>
          </ol>
          <ol className="c10 lst-kix_list_1-0" start={2}>
            <li className="c6 li-bullet-0">
              <span className="c3">Voc&ecirc; usa e interage com nosso site.</span>
              <span className="c7">
                &nbsp;Quando voc&ecirc; navega em nosso site, n&oacute;s tratamos Dados T&eacute;cnicos (conforme definido
                abaixo). Utilizamos esses dados para nossos interesses leg&iacute;timos de garantir que nosso site funcione
                corretamente, incluindo depura&ccedil;&atilde;o, para poder fornecer nosso conte&uacute;do a voc&ecirc;, para
                mitiga&ccedil;&atilde;o de DDOS em nosso site, bem como para melhorar nosso site e realizar an&aacute;lises
                estat&iacute;sticas a fim de otimizar a qualidade de nosso site. Consulte a nossa Pol&iacute;tica de Cookies{' '}
              </span>
              <span className="c22 c7">
                <a className="c12" href="https://dlocalgo.com/pt/legal/politicas-de-cookies">
                  AQUI
                </a>
              </span>
              <span className="c1">&nbsp;para mais detalhes.</span>
            </li>
            <li className="c6 li-bullet-0">
              <span className="c3">Solu&ccedil;&otilde;es SmartFields e Redirect.</span>
              <span className="c1">
                &nbsp;Quando voc&ecirc; compra produtos ou servi&ccedil;os de clientes da dLocal que escolheram nossas
                solu&ccedil;&otilde;es Smartfield e Redirect para cobran&ccedil;a de pagamentos, &eacute; muito
                prov&aacute;vel que voc&ecirc; esteja fornecendo seus Dados Pessoais diretamente &agrave; dLocal.
              </span>
            </li>
            <li className="c6 li-bullet-0">
              <span className="c3 c21">Due Diligence</span>
              <span className="c3">.</span>
              <span className="c1">
                &nbsp;Quando voc&ecirc; solicita tornar-se um cliente comercial da dLocal, exigimos que forne&ccedil;a os
                dados pessoais detalhados neste par&aacute;grafo, mas n&atilde;o se limitando a esta descri&ccedil;&atilde;o.
                Por raz&otilde;es regulat&oacute;rias, podemos solicitar nome, endere&ccedil;o postal, n&uacute;mero de
                telefone e endere&ccedil;o de e-mail para atender &agrave;s exig&ecirc;ncias regulat&oacute;rias e de nossos
                parceiros financeiros. Tamb&eacute;m podemos coletar suas informa&ccedil;&otilde;es financeiras e pessoais,
                como sua participa&ccedil;&atilde;o acion&aacute;ria na empresa, seu status de executivo ou diretor, sua data
                de nascimento e identificadores governamentais associados a voc&ecirc; e &agrave; sua empresa (como seu
                n&uacute;mero de previd&ecirc;ncia social, n&uacute;mero de contribuinte ou N&uacute;mero de
                Identifica&ccedil;&atilde;o do Empregador). Al&eacute;m disso, podemos exigir informa&ccedil;&otilde;es de
                conta banc&aacute;ria.
              </span>
            </li>
            <li className="c6 li-bullet-0">
              <span className="c3">Terceiros ou fontes dispon&iacute;veis publicamente</span>
              <span className="c1">
                &nbsp;Podemos receber seus Dados Pessoais de v&aacute;rios terceiros e fontes p&uacute;blicas quando
                realizamos verifica&ccedil;&otilde;es de antecedentes, verifica&ccedil;&otilde;es de preven&ccedil;&atilde;o
                de fraudes, verifica&ccedil;&otilde;es de identidade e outras pr&aacute;ticas de &ldquo;Conhe&ccedil;a Seu
                Cliente&rdquo; que precisamos realizar nos representantes de nossos Clientes para cumprir as normas e
                exig&ecirc;ncias de servi&ccedil;os financeiros aplic&aacute;veis e as leis e regulamentos aplic&aacute;veis.
              </span>
            </li>
          </ol>
          <p className="c5">
            <span className="c1"></span>
          </p>
          <p className="c9">
            <span className="c1">
              Podemos coletar, usar, armazenar e transferir diferentes tipos de dados sobre voc&ecirc;, os quais agrupamos a
              seguir:
            </span>
          </p>
          <ol className="c10 lst-kix_list_2-0 start" start={1}>
            <li className="c13 li-bullet-0">
              <span className="c3">Dados de Identidade: </span>
              <span className="c1">
                incluem primeiro nome, sobrenome, nome de usu&aacute;rio, n&uacute;mero do documento de identidade;
              </span>
            </li>
            <li className="c6 li-bullet-0">
              <span className="c3">Dados de Contato:</span>
              <span className="c1">
                &nbsp;incluem informa&ccedil;&otilde;es de contato, endere&ccedil;o para faturamento, endere&ccedil;o de
                entrega, endere&ccedil;o de e-mail e n&uacute;meros de telefone;
              </span>
            </li>
            <li className="c6 li-bullet-0">
              <span className="c3">Dados T&eacute;cnicos: </span>
              <span className="c1">
                incluem seu endere&ccedil;o de protocolo de internet (IP), seus dados de login, ID do Google Analytics,
                navegador de Internet e tipo de dispositivo, configura&ccedil;&atilde;o de fuso hor&aacute;rio, dados de
                localiza&ccedil;&atilde;o e uso de nosso site, incluindo quais p&aacute;ginas voc&ecirc; visitou, como chegou
                ao nosso site, a hora e a dura&ccedil;&atilde;o de sua visita e suas prefer&ecirc;ncias de idioma;
              </span>
            </li>
            <li className="c6 li-bullet-0">
              <span className="c3">Dados de usu&aacute;rio de Representantes de Clientes: </span>
              <span className="c1">
                dados de uso gerados pelos representantes de nossos Clientes quando eles acessam nossa plataforma ou quando
                interagem conosco por e-mail ou de outra forma.
              </span>
            </li>
            <li className="c6 li-bullet-0">
              <span className="c3">Dados de Perfil: </span>
              <span className="c1">incluem o nome de usu&aacute;rio e a senha dos representantes de nossos Clientes;</span>
            </li>
            <li className="c6 li-bullet-0">
              <span className="c3">Dados de Marketing e Comunica&ccedil;&otilde;es: </span>
              <span className="c1">
                seu nome, cargo e informa&ccedil;&otilde;es comerciais e incluem suas prefer&ecirc;ncias de recebimento de
                marketing nosso e de nossos terceiros e suas prefer&ecirc;ncias de comunica&ccedil;&atilde;o; e
              </span>
            </li>
            <li className="c6 li-bullet-0">
              <span className="c3">Dados Financeiros: </span>
              <span className="c1">
                incluem dados de cart&atilde;o, dados de conta banc&aacute;ria, informa&ccedil;&otilde;es fiscais;
              </span>
            </li>
            <li className="c6 li-bullet-0">
              <span className="c3">Categoria especial de dados pessoais:</span>
              <span className="c1">
                &nbsp;como dados biom&eacute;tricos. Existem casos em que podemos coletar dados pessoais especiais; por
                exemplo, para confirmar a identidade de nossos clientes.
              </span>
            </li>
          </ol>
          <p className="c5">
            <span className="c1"></span>
          </p>
          <p className="c9">
            <span className="c7">Tamb&eacute;m coletamos, utilizamos e compartilhamos </span>
            <span className="c3">Dados Agregados</span>
            <span className="c7">
              , como dados estat&iacute;sticos ou demogr&aacute;ficos para qualquer finalidade. Dados Agregados podem ser
              derivados de seus Dados Pessoais, mas n&atilde;o s&atilde;o considerados Dados Pessoais de acordo com a lei,
              pois eles{' '}
            </span>
            <span className="c3">n&atilde;o</span>
            <span className="c1">
              &nbsp;revelar&atilde;o, direta ou indiretamente, sua identidade. Por exemplo, n&oacute;s podemos agregar seus
              Dados T&eacute;cnicos para calcular o percentual de usu&aacute;rios que acessam um recurso espec&iacute;fico do
              site.
            </span>
          </p>
          <p className="c11">
            <span className="c1">
              Podemos coletar informa&ccedil;&otilde;es sobre condena&ccedil;&otilde;es e crimes, mas apenas no contexto de
              fraudes ou verifica&ccedil;&otilde;es de seguran&ccedil;a, quando isso for necess&aacute;rio para cumprir as
              leis aplic&aacute;veis ou quaisquer normas ou exig&ecirc;ncias de servi&ccedil;os financeiros
              aplic&aacute;veis.
            </span>
          </p>
          <p className="c11">
            <span className="c3">Se voc&ecirc; n&atilde;o fornecer Dados Pessoais</span>
          </p>
          <p className="c11">
            <span className="c1">
              Se precisarmos coletar Dados Pessoais de acordo com a lei ou com os termos de um contrato e voc&ecirc;
              n&atilde;o fornecer esses dados quando solicitados, &eacute; poss&iacute;vel que n&atilde;o consigamos cumprir
              o contrato que temos ou estamos tentando celebrar com voc&ecirc; (por exemplo, para fornecer-lhe produtos ou
              servi&ccedil;os). Nesse caso, &eacute; poss&iacute;vel que tenhamos de cancelar um produto ou servi&ccedil;o
              que voc&ecirc; tenha conosco, mas n&oacute;s lhe comunicaremos oportunamente.
            </span>
          </p>
          <p className="c2">
            <span className="c15 c3">4. Como utilizamos seus dados pessoais?</span>
          </p>
          <p className="c5">
            <span className="c1"></span>
          </p>
          <p className="c9">
            <span className="c7">Podemos tratar seus Dados Pessoais para uma ou mais bases legais de tratamento (</span>
            <span className="c3">&ldquo;Base Legal&rdquo;</span>
            <span className="c1">
              ), dependendo da finalidade espec&iacute;fica para a qual estamos utilizando seus dados (consulte abaixo).
            </span>
          </p>
          <p className="c11">
            <span className="c1">
              De acordo com este Aviso de Privacidade, a dLocal poder&aacute; utilizar seus Dados Pessoais para:
            </span>
          </p>
          <ol className="c10 lst-kix_list_3-0 start" start={1}>
            <li className="c13 li-bullet-0">
              <span className="c7">Prestar os servi&ccedil;os que voc&ecirc; nos solicitar </span>
              <span className="c7 c21">
                (Base Legal: cumprimento de nossas obriga&ccedil;&otilde;es legais e de nosso contrato com voc&ecirc;)
              </span>
            </li>
            <li className="c6 li-bullet-0">
              <span className="c7">
                Verificar sua identidade ou realizar verifica&ccedil;&otilde;es adequadas de idoneidade ou fraude{' '}
              </span>
              <span className="c7 c21">
                (Base Legal: cumprimento de nossas obriga&ccedil;&otilde;es legais e necess&aacute;rio para nossos interesses
                leg&iacute;timos)
              </span>
              <span className="c1">;</span>
            </li>
            <li className="c6 li-bullet-0">
              <span className="c7">
                Entender suas necessidades para fornecer os produtos e servi&ccedil;os de que voc&ecirc; necessita{' '}
              </span>
              <span className="c7 c21">(Base Legal: cumprimento do contrato celebrado com voc&ecirc;)</span>
              <span className="c1">;</span>
            </li>
            <li className="c6 li-bullet-0">
              <span className="c7">
                Administrar e gerir nossos servi&ccedil;os, incluindo faturamento dos servi&ccedil;os prestados e
                cobran&ccedil;a de d&iacute;vidas{' '}
              </span>
              <span className="c7 c21">
                (Base Legal: cumprimento do contrato celebrado com voc&ecirc; e de nossas obriga&ccedil;&otilde;es legais, e
                necess&aacute;rio para nossos interesses leg&iacute;timos)
              </span>
              <span className="c1">;</span>
            </li>
            <li className="c6 li-bullet-0">
              <span className="c7">
                Distribuir informa&ccedil;&otilde;es, boletins informativos, publica&ccedil;&otilde;es e outras
                comunica&ccedil;&otilde;es por v&aacute;rios meios para mant&ecirc;-lo informado{' '}
              </span>
              <span className="c7 c21">
                (Base Legal: seu consentimento, cumprimento do contrato celebrado com voc&ecirc; e necess&aacute;rio para
                nossos interesses leg&iacute;timos)
              </span>
              <span className="c1">;</span>
            </li>
            <li className="c6 li-bullet-0">
              <span className="c7">Pesquisar e desenvolver novas ofertas de produtos e servi&ccedil;os </span>
              <span className="c7 c21">
                (Base Legal: cumprimento do contrato celebrado com voc&ecirc; e necess&aacute;rio para nossos interesses
                leg&iacute;timos)
              </span>
              <span className="c1">;</span>
            </li>
            <li className="c6 li-bullet-0">
              <span className="c7">
                Gerir e conduzir nossos neg&oacute;cios e os servi&ccedil;os que prestamos a nossos Clientes
              </span>
              <span className="c7 c21">
                &nbsp;(Base Legal: cumprimento do contrato celebrado com voc&ecirc; e necess&aacute;rio para nossos
                interesses leg&iacute;timos)
              </span>
              <span className="c1">;</span>
            </li>
            <li className="c6 li-bullet-0">
              <span className="c7">
                Garantir que nosso site funcione corretamente, incluindo depura&ccedil;&atilde;o, para poder fornecer nosso
                conte&uacute;do a voc&ecirc;, para mitiga&ccedil;&atilde;o de DDOS em nosso site, bem como para melhorar
                nosso site e realizar an&aacute;lises estat&iacute;sticas{' '}
              </span>
              <span className="c7 c21">(Base Legal: necess&aacute;rio para nossos interesses leg&iacute;timos)</span>
              <span className="c1">;</span>
            </li>
            <li className="c6 li-bullet-0">
              <span className="c7">
                Fornecer-lhe ofertas adaptadas &agrave;s suas necessidades e personalizar o que lhe mostramos &agrave;s suas
                prefer&ecirc;ncias, com o seu consentimento pr&eacute;vio{' '}
              </span>
              <span className="c7 c21">
                (Base Legal: cumprimento do contrato celebrado com voc&ecirc; e necess&aacute;rio para nossos interesses
                leg&iacute;timos)
              </span>
              <span className="c1">;</span>
            </li>
            <li className="c6 li-bullet-0">
              <span className="c7">Nos comunicarmos com terceiros de maneira eficaz </span>
              <span className="c7 c21">
                (Base Legal: seu consentimento, cumprimento do contrato celebrado com voc&ecirc; e necess&aacute;rio para
                nossos interesses leg&iacute;timos)
              </span>
              <span className="c1">; e</span>
            </li>
            <li className="c6 li-bullet-0">
              <span className="c7">Conforme exigido ou autorizado pela lei aplic&aacute;vel</span>
              <span className="c7 c21">&nbsp;(Base Legal: cumprimento de nossas obriga&ccedil;&otilde;es legais)</span>
              <span className="c1">.</span>
            </li>
          </ol>
          <p className="c2">
            <span className="c15 c3">5. Quando podemos divulgar os dados pessoais?</span>
          </p>
          <p className="c5">
            <span className="c1"></span>
          </p>
          <p className="c9">
            <span className="c1">
              Para os fins estabelecidos neste Aviso de Privacidade, suas informa&ccedil;&otilde;es podem ser divulgadas a
              fim de realizar tratamento de Dados para:
            </span>
          </p>
          <ol className="c10 lst-kix_list_4-0 start" start={1}>
            <li className="c13 li-bullet-0">
              <span className="c1">
                Nossos empregados, nossas afiliadas e seus empregados. Por exemplo, a dLocal compartilhar&aacute; suas
                informa&ccedil;&otilde;es com outras afiliadas da dLocal para fins de presta&ccedil;&atilde;o de nossos
                servi&ccedil;os ou quando essas afiliadas prestarem servi&ccedil;os de suporte &agrave; dLocal;
              </span>
            </li>
            <li className="c6 li-bullet-0">
              <span className="c1">
                Nossos consultores terceirizados, (sub) contratados, fornecedores ou outros prestadores de servi&ccedil;os
                que possam acessar suas informa&ccedil;&otilde;es pessoais ao prestar servi&ccedil;os para n&oacute;s
                (incluindo, entre outros, servi&ccedil;os de suporte de TI) (isso inclui especialistas em tecnologia da
                informa&ccedil;&atilde;o que projetam e hospedam nosso site e empresas de servi&ccedil;os gerais);
              </span>
            </li>
            <li className="c6 li-bullet-0">
              <span className="c1">
                Auditores ou contratados ou outros consultores que auditem, auxiliem ou assessorem sobre qualquer um de
                nossos objetivos comerciais;
              </span>
            </li>
            <li className="c6 li-bullet-0">
              <span className="c1">
                Provedores de mecanismos de pesquisa e an&aacute;lises que nos auxiliem na melhoria e
                otimiza&ccedil;&atilde;o de nosso site;
              </span>
            </li>
            <li className="c6 li-bullet-0">
              <span className="c1">
                Nossos sucessores legais, potenciais vendedores ou compradores de nossos neg&oacute;cios ou nossas Afiliadas
                no caso de uma fus&atilde;o ou reestrutura&ccedil;&atilde;o;
              </span>
            </li>
            <li className="c6 li-bullet-0">
              <span className="c1">
                &Oacute;rg&atilde;os governamentais e ag&ecirc;ncias de aplica&ccedil;&atilde;o da lei e em resposta a outros
                pedidos legais e regulat&oacute;rios;
              </span>
            </li>
            <li className="c6 li-bullet-0">
              <span className="c1">
                Qualquer terceiro, se essa divulga&ccedil;&atilde;o for necess&aacute;ria para executar ou aplicar os Termos
                do Site ou outros acordos relevantes; e
              </span>
            </li>
            <li className="c6 li-bullet-0">
              <span className="c1">
                Proteger os direitos, propriedade, integridade ou seguran&ccedil;a de nossa empresa, nossos clientes ou de
                terceiros (incluindo, entre outros, voc&ecirc;), o que inclui trocar informa&ccedil;&otilde;es com outras
                empresas e organiza&ccedil;&otilde;es para os fins de prote&ccedil;&atilde;o contra fraude e
                redu&ccedil;&atilde;o de risco de cr&eacute;dito.
              </span>
            </li>
          </ol>
          <p className="c5">
            <span className="c1"></span>
          </p>
          <p className="c9">
            <span className="c1">
              Se suas informa&ccedil;&otilde;es forem fornecidas a qualquer outra parte para uma finalidade expressa,
              exigiremos que ela mantenha em seguran&ccedil;a e proteja seus Dados Pessoais e os utilizaremos apenas para a
              finalidade pretendida.
            </span>
          </p>
          <p className="c2">
            <span className="c15 c3">6. Transfer&ecirc;ncias internacionais</span>
          </p>
          <p className="c5">
            <span className="c1"></span>
          </p>
          <p className="c9">
            <span className="c1">
              A dLocal atende clientes no mundo todo. Dessa forma, seus Dados Pessoais podem ser compartilhados com outras
              afiliadas da dLocal fora do Reino Unido, quando isso for necess&aacute;rio para os fins mencionados neste Aviso
              de Privacidade. Esses pa&iacute;ses incluem os pa&iacute;ses em que atuamos e os pa&iacute;ses em que alguns
              dos nossos prestadores de servi&ccedil;os est&atilde;o localizados.
            </span>
          </p>
          <p className="c11">
            <span className="c1">
              Para proteger seus Dados Pessoais quando eles s&atilde;o transferidos para pa&iacute;ses fora do Reino Unido,
              implementamos medidas de seguran&ccedil;a apropriadas. A transfer&ecirc;ncia de Dados Pessoais do Reino Unido
              para pa&iacute;ses considerados &lsquo;n&atilde;o adequados&rsquo; &eacute; protegida por mecanismos de defesas
              aprovados no Reino Unido, como as Cl&aacute;usulas Contratuais Padr&atilde;o.
            </span>
          </p>
          <p className="c2">
            <span className="c15 c3">
              7. O que acontece se voc&ecirc; n&atilde;o fornecer os dados pessoais solicitados?
            </span>
          </p>
          <p className="c5">
            <span className="c1"></span>
          </p>
          <p className="c9">
            <span className="c1">
              Sempre que poss&iacute;vel, voc&ecirc; pode ter a op&ccedil;&atilde;o de tratar com a dLocal de forma
              an&ocirc;nima ou usando um pseud&ocirc;nimo. No entanto, em alguns casos, se n&atilde;o conseguirmos coletar
              seus Dados Pessoais, ou se os Dados Pessoais fornecidos estiverem incompletos ou incorretos, a dLocal pode
              n&atilde;o ser capaz de ajud&aacute;-lo, inclusive no fornecimento dos produtos ou servi&ccedil;os que
              voc&ecirc; procura ou na presta&ccedil;&atilde;o de suporte ou aux&iacute;lio em suas d&uacute;vidas.
            </span>
          </p>
          <p className="c2">
            <span className="c15 c3">8. Seguran&ccedil;a de dados pessoais</span>
          </p>
          <p className="c5">
            <span className="c1"></span>
          </p>
          <p className="c9">
            <span className="c1">
              A Internet n&atilde;o &eacute; um meio seguro. No entanto, implementamos uma s&eacute;rie de procedimentos de
              seguran&ccedil;a, conforme estabelecido neste Aviso de Privacidade. Se voc&ecirc; tiver uma conta, esta
              &aacute;rea &eacute; protegida por seu nome de usu&aacute;rio e senha, os quais voc&ecirc; nunca deve divulgar
              a ningu&eacute;m.
            </span>
          </p>
          <p className="c11">
            <span className="c1">
              Esteja ciente de que as comunica&ccedil;&otilde;es pela Internet, como e-mails/webmails, n&atilde;o s&atilde;o
              seguras, a menos que tenham sido criptografadas. Suas comunica&ccedil;&otilde;es podem passar por v&aacute;rios
              pa&iacute;ses antes de serem entregues. Esta &eacute; a natureza da Rede Mundial de Computadores/Internet.
              N&atilde;o podemos assumir a responsabilidade por nenhum acesso n&atilde;o autorizado ou perda de
              informa&ccedil;&otilde;es pessoais que estejam fora de nosso controle.
            </span>
          </p>
          <p className="c11">
            <span className="c1">
              Envidaremos todos os esfor&ccedil;os razo&aacute;veis para implementar pol&iacute;ticas, regras e medidas
              t&eacute;cnicas apropriadas para proteger os Dados Pessoais que temos sob nosso controle (considerando o tipo e
              a quantidade desses dados) contra acesso n&atilde;o autorizado, divulga&ccedil;&atilde;o ou uso indevido,
              modifica&ccedil;&atilde;o n&atilde;o autorizada, destrui&ccedil;&atilde;o ilegal ou perda acidental.
            </span>
          </p>
          <p className="c11">
            <span className="c1">
              Garantimos que suas informa&ccedil;&otilde;es n&atilde;o ser&atilde;o divulgadas a institui&ccedil;&otilde;es
              ou autoridades governamentais, exceto se exigido por lei (por exemplo, quando solicitado por
              &oacute;rg&atilde;os reguladores ou organiza&ccedil;&otilde;es de aplica&ccedil;&atilde;o da lei de acordo com
              a legisla&ccedil;&atilde;o aplic&aacute;vel).
            </span>
          </p>
          <p className="c2">
            <span className="c15 c3">9. Cookies</span>
          </p>
          <p className="c9">
            <span className="c7">
              A dLocal utiliza cookies para armazenar e coletar informa&ccedil;&otilde;es sobre o uso de nosso Site. Cookies
              s&atilde;o pequenos arquivos de texto armazenados pelo navegador no disco r&iacute;gido do seu equipamento.
              Eles enviam informa&ccedil;&otilde;es armazenadas neles de volta para o nosso servidor da web quando voc&ecirc;
              acessa nosso Site. Esses cookies nos permitem definir configura&ccedil;&otilde;es pessoais e carregar suas
              prefer&ecirc;ncias pessoais para melhorar sua experi&ecirc;ncia. Voc&ecirc; pode obter mais
              informa&ccedil;&otilde;es sobre cookies em{' '}
            </span>
            <span className="c17 c7">
              <a className="c12" href="https://www.allaboutcookies.org/">
                www.allaboutcookies.org
              </a>
            </span>
            <span className="c7">
              &nbsp;e sobre os cookies que utilizamos em nossa &ldquo;Pol&iacute;tica de Cookies&rdquo; dispon&iacute;vel em
              nosso{' '}
            </span>
            <span className="c7 c22">
              <a className="c12" href="https://dlocalgo.com/pt/legal/politicas-de-cookies/">
                SITE
              </a>
            </span>
            <span className="c1">.</span>
          </p>
          <p className="c2">
            <span className="c15 c3">10. Seus direitos</span>
          </p>
          <p className="c5">
            <span className="c1"></span>
          </p>
          <p className="c9">
            <span className="c1">
              Tomaremos todas as medidas razo&aacute;veis para garantir que todas as informa&ccedil;&otilde;es que coletamos,
              utilizamos ou divulgamos estejam corretas, completas e atualizadas. Entre em contato conosco em caso de
              altera&ccedil;&atilde;o de seus dados ou se voc&ecirc; acreditar que as informa&ccedil;&otilde;es que temos
              sobre voc&ecirc; n&atilde;o est&atilde;o corretas ou completas.
            </span>
          </p>
          <p className="c11">
            <span className="c1">Em alguns casos, voc&ecirc; tamb&eacute;m poder&aacute; ter o direito de:</span>
          </p>
          <ol className="c10 lst-kix_list_5-0 start" start={1}>
            <li className="c13 li-bullet-0">
              <span className="c3">Solicitar acesso </span>
              <span className="c1">
                a seus Dados Pessoais (comumente conhecido como &ldquo;solicita&ccedil;&atilde;o de acesso do titular dos
                dados&rdquo;). Isso permite que voc&ecirc; receba uma c&oacute;pia de seus Dados Pessoais mantidos por
                n&oacute;s e verifique se estamos tratando-os de forma legal.
              </span>
            </li>
            <li className="c6 li-bullet-0">
              <span className="c3">Solicitar corre&ccedil;&atilde;o </span>
              <span className="c1">
                de seus Dados Pessoais mantidos por n&oacute;s. Isso permite que voc&ecirc; corrija quaisquer de seus dados
                mantidos por n&oacute;s que estejam incompletos ou incorretos, embora possa ser necess&aacute;rio verificar a
                precis&atilde;o das novas informa&ccedil;&otilde;es que voc&ecirc; nos fornecer.
              </span>
            </li>
            <li className="c6 li-bullet-0">
              <span className="c3">Solicitar exclus&atilde;o de seus Dados Pessoais.</span>
              <span className="c1">
                &nbsp;Isso permite que voc&ecirc; nos pe&ccedil;a para excluir ou remover Dados Pessoais quando n&atilde;o
                houver um bom motivo para continuarmos tratando-os. No entanto, observe que nem sempre poderemos atender ao
                seu pedido de exclus&atilde;o por motivos legais espec&iacute;ficos que lhe ser&atilde;o informados.
              </span>
            </li>
            <li className="c6 li-bullet-0">
              <span className="c3">Opor-se ao tratamento</span>
              <span className="c1">
                &nbsp;de seus Dados Pessoais, se estivermos nos baseando em um interesse leg&iacute;timo (ou de terceiros) e
                houver algo sobre sua situa&ccedil;&atilde;o particular que o fa&ccedil;a querer se opor ao tratamento por
                esse motivo, pois voc&ecirc; sente que isso afeta seus direitos e liberdades fundamentais. Voc&ecirc;
                tamb&eacute;m tem o direito de se opor se estivermos tratando seus Dados Pessoais para fins de marketing
                direto. Em alguns casos, podemos comprovar que temos motivos leg&iacute;timos convincentes para tratar suas
                informa&ccedil;&otilde;es, os quais n&atilde;o anulam seus direitos e liberdades.
              </span>
            </li>
            <li className="c6 li-bullet-0">
              <span className="c3">Solicitar restri&ccedil;&atilde;o de tratamento </span>
              <span className="c1">
                de seus Dados Pessoais. Isso permite que voc&ecirc; nos pe&ccedil;a para suspender o tratamento de seus Dados
                Pessoais nos seguintes cen&aacute;rios: se voc&ecirc; deseja que estabele&ccedil;amos a exatid&atilde;o dos
                dados; se nosso uso dos dados for ilegal, mas voc&ecirc; n&atilde;o deseja que os apaguemos; se voc&ecirc;
                precisar que mantenhamos os dados, mesmo que n&atilde;o precisemos mais deles, pois voc&ecirc; precisa deles
                para estabelecer, exercer ou defender reivindica&ccedil;&otilde;es legais; ou voc&ecirc; se op&ocirc;s ao uso
                de seus dados, mas precisamos verificar se temos motivos leg&iacute;timos prevalecentes para us&aacute;-los.
              </span>
            </li>
            <li className="c6 li-bullet-0">
              <span className="c3">Solicitar a transfer&ecirc;ncia dos seus Dados Pessoais </span>
              <span className="c1">
                para voc&ecirc; ou para terceiros. Forneceremos a voc&ecirc;, ou a terceiros escolhidos por voc&ecirc;, seus
                Dados Pessoais em um formato estruturado, comumente usado e leg&iacute;vel por m&aacute;quina. Observe que
                esse direito se aplica apenas a informa&ccedil;&otilde;es automatizadas que voc&ecirc; inicialmente nos deu
                consentimento para usar ou caso utilizemos as informa&ccedil;&otilde;es para cumprir um contrato com
                voc&ecirc;.
              </span>
            </li>
            <li className="c6 li-bullet-0">
              <span className="c3">Retirar o consentimento a qualquer momento</span>
              <span className="c1">
                , se estivermos nos baseando no consentimento para tratar seus Dados Pessoais. No entanto, isso n&atilde;o
                afetar&aacute; a legalidade de qualquer tratamento realizado antes de voc&ecirc; retirar seu consentimento.
                Se voc&ecirc; retirar seu consentimento, talvez n&atilde;o possamos fornecer-lhe determinados produtos ou
                servi&ccedil;os. Se for esse o caso, avisaremos voc&ecirc;.
              </span>
            </li>
          </ol>
          <p className="c5">
            <span className="c3 c23"></span>
          </p>
          <p className="c9">
            <span className="c3">Como exercer seus direitos?</span>
          </p>
          <p className="c11">
            <span className="c7">Clique neste </span>
            <span className="c7 c17">
              <a
                className="c12"
                href="https://omnitrack.vinciworks.com/dlocal/forms/assigned/839b5347-1ed6-4941-9338-2ecf897d448d"
              >
                LINK
              </a>
            </span>
            <span className="c1">
              &nbsp;e preencha o formul&aacute;rio para enviar sua solicita&ccedil;&atilde;o. Este &eacute; o canal exclusivo
              para enviar uma solicita&ccedil;&atilde;o e exercer seus direitos. Solicita&ccedil;&otilde;es enviadas por
              outros meios n&atilde;o ser&atilde;o respondidas.
            </span>
          </p>
          <p className="c11">
            <span className="c3">O que podemos precisar de voc&ecirc;?</span>
          </p>
          <p className="c11">
            <span className="c1">
              Podemos precisar solicitar-lhe informa&ccedil;&otilde;es espec&iacute;ficas para nos ajudar a confirmar sua
              identidade. Tamb&eacute;m podemos contat&aacute;-lo para pedir mais informa&ccedil;&otilde;es com
              rela&ccedil;&atilde;o &agrave; sua solicita&ccedil;&atilde;o.
            </span>
          </p>
          <p className="c11">
            <span className="c3">Prazo de resposta.</span>
          </p>
          <p className="c11">
            <span className="c1">
              Tentamos responder a todas as solicita&ccedil;&otilde;es leg&iacute;timas no prazo de um m&ecirc;s.
              Ocasionalmente, podemos demorar mais de um m&ecirc;s caso sua solicita&ccedil;&atilde;o seja particularmente
              complexa ou voc&ecirc; tenha feito v&aacute;rias solicita&ccedil;&otilde;es. Nesse caso, n&oacute;s lhe
              comunicaremos e o manteremos atualizado.
            </span>
          </p>
          <p className="c11">
            <span className="c3">Inexist&ecirc;ncia de taxas.</span>
          </p>
          <p className="c11">
            <span className="c1">
              Todas as comunica&ccedil;&otilde;es e todas as a&ccedil;&otilde;es tomadas pela dLocal com
              rela&ccedil;&atilde;o a seus direitos descritos acima s&atilde;o fornecidas gratuitamente. A dLocal reserva-se
              o direito, no caso de solicita&ccedil;&otilde;es claramente infundadas ou irrazo&aacute;veis, de cobrar uma
              taxa razo&aacute;vel que cubra os custos administrativos para fornecer as informa&ccedil;&otilde;es ou para
              tomar a a&ccedil;&atilde;o solicitada ou recusar-se a cumprir a a&ccedil;&atilde;o solicitada.
            </span>
          </p>
          <p className="c2">
            <span className="c15 c3">11. Por quanto tempo mantemos os dados pessoais?</span>
          </p>
          <p className="c5">
            <span className="c1"></span>
          </p>
          <p className="c9">
            <span className="c1">
              N&oacute;s reteremos seus Dados Pessoais somente enquanto voc&ecirc; consentir com essa
              manuten&ccedil;&atilde;o ou enquanto nos for necess&aacute;rio para prestar-lhe nossos servi&ccedil;os ou para
              cumprir os prop&oacute;sitos para os quais os coletamos, inclusive para os fins de cumprir quaisquer
              exig&ecirc;ncias legais, cont&aacute;beis ou de apresenta&ccedil;&atilde;o de relat&oacute;rio.
            </span>
          </p>
          <p className="c11">
            <span className="c1">
              Tomaremos medidas razo&aacute;veis para destruir ou desidentificar permanentemente os Dados Pessoais que
              n&atilde;o forem mais necess&aacute;rios para qualquer finalidade permitida pela Legisla&ccedil;&atilde;o de
              Prote&ccedil;&atilde;o de Dados.
            </span>
          </p>
          <p className="c11">
            <span className="c1">
              Por exemplo, por lei, temos de manter informa&ccedil;&otilde;es b&aacute;sicas sobre nossos clientes (incluindo
              dados de contato, identidade, financeiros e de transa&ccedil;&otilde;es). Para determinar o per&iacute;odo de
              reten&ccedil;&atilde;o apropriado dos Dados Pessoais, consideramos a quantidade, a natureza e a sensibilidade
              dos Dados Pessoais, o potencial de risco de preju&iacute;zo da divulga&ccedil;&atilde;o ou uso n&atilde;o
              autorizado de seus Dados Pessoais, os prop&oacute;sitos para os quais tratamos seus Dados Pessoais e se podemos
              alcan&ccedil;ar esses prop&oacute;sitos por outros meios, bem como as exig&ecirc;ncias legais
              aplic&aacute;veis.
            </span>
          </p>
          <p className="c2">
            <span className="c15 c3">12. Altera&ccedil;&otilde;es</span>
          </p>
          <p className="c5">
            <span className="c1"></span>
          </p>
          <p className="c9">
            <span className="c1">
              Reservamo-nos o direito de alterar ou editar este Aviso de Privacidade periodicamente, a nosso crit&eacute;rio,
              de modo a refletir mudan&ccedil;as nos neg&oacute;cios ou pr&aacute;ticas da dLocal. Podemos alterar o Aviso de
              Privacidade a qualquer momento, publicando o Aviso de Privacidade alterado no site da dLocal, incluindo a
              publica&ccedil;&atilde;o de um aviso na p&aacute;gina inicial do site da dLocal indicando que houve uma
              altera&ccedil;&atilde;o.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};
