import { CheckoutTheme, CreateCheckoutTheme } from './interfaces';

export enum CheckoutThemesActionType {
  CHANGE_UPDATE_CHECKOUT_THEME = 'CHECKOUT_THEMES/CHANGE_UPDATE_CHECKOUT_THEME',
  GET_CHECKOUT_THEMES = 'CHECKOUT_THEMES/GET_CHECKOUT_THEMES',
  GET_CHECKOUT_THEMES_SUCCESS = 'CHECKOUT_THEMES/GET_CHECKOUT_THEMES_SUCCESS',
  GET_CHECKOUT_THEMES_ERROR = 'CHECKOUT_THEMES/GET_CHECKOUT_THEMES_ERROR',
  SHOW_CREATE_CHECKOUT_THEME = 'CHECKOUT_THEMES/SHOW_CREATE_CHECKOUT_THEME',
  POST_CREATE_CHECKOUT_THEME = 'CHECKOUT_THEMES/POST_CREATE_CHECKOUT_THEME',
  POST_CREATE_CHECKOUT_THEME_SUCCESS = 'CHECKOUT_THEMES/POST_CREATE_CHECKOUT_THEME_SUCCESS',
  TOGGLE_CHECKOUT_THEME = 'CHECKOUT_THEMES/TOGGLE_CHECKOUT_THEME',
  TOGGLE_CHECKOUT_THEME_SUCCESS = 'CHECKOUT_THEMES/TOGGLE_CHECKOUT_THEME_SUCCESS',
  TOGGLE_CHECKOUT_THEME_ERROR = 'CHECKOUT_THEMES/TOGGLE_CHECKOUT_THEME_ERROR',
  HIDE_CHECKOUT_THEME_ERROR = 'CHECKOUT_THEMES/HIDE_CHECKOUT_THEME_ERROR',
}

export const getCheckoutThemes = (page: number) => ({
  type: CheckoutThemesActionType.GET_CHECKOUT_THEMES,
  page,
});

export const getCheckoutThemesSuccess = (response: any) => ({
  type: CheckoutThemesActionType.GET_CHECKOUT_THEMES_SUCCESS,
  response,
});

export const getCheckoutThemesError = (error: any) => ({
  type: CheckoutThemesActionType.GET_CHECKOUT_THEMES_ERROR,
  error,
});

export const changeShowCreateCheckoutTheme = (show: boolean) => ({
  type: CheckoutThemesActionType.SHOW_CREATE_CHECKOUT_THEME,
  show,
});

export const postCreateCheckoutTheme = (payload: CreateCheckoutTheme) => ({
  type: CheckoutThemesActionType.POST_CREATE_CHECKOUT_THEME,
  payload,
});

export const postCreateCheckoutThemeSuccess = (response: CheckoutTheme) => ({
  type: CheckoutThemesActionType.POST_CREATE_CHECKOUT_THEME_SUCCESS,
  response,
});

export const changeUpdateCheckoutTheme = (theme: CheckoutTheme) => ({
  type: CheckoutThemesActionType.CHANGE_UPDATE_CHECKOUT_THEME,
  theme,
});

export const toggleCheckoutTheme = (id: Number) => ({
  type: CheckoutThemesActionType.TOGGLE_CHECKOUT_THEME,
  id,
});

export const toggleCheckoutThemeSuccess = () => ({
  type: CheckoutThemesActionType.TOGGLE_CHECKOUT_THEME_SUCCESS,
});

export const toggleCheckoutThemeError = (error: any) => ({
  type: CheckoutThemesActionType.TOGGLE_CHECKOUT_THEME_ERROR,
  error,
});

export const hideCheckoutThemeError = () => ({
  type: CheckoutThemesActionType.HIDE_CHECKOUT_THEME_ERROR,
});
