import { useEffect, useState } from 'react';
import Parser from 'html-react-parser';
import _ from 'lodash';
import { ReactComponent as InfoIcon } from '../../../../../../assets/icons/info-unfilled.svg';
import l from '../../../../../../lang';
import Input from '../../../../../common/input';
import ButtonCustom from '../../../../../common/__deprecated__/button-custom';
import Select from '../../../../../common/select';
import InputCheckbox from '../../../../../common/input-checkbox';
import Accordion from '../../../../../common/accordion';
import TooltipIcon from '../../../../../common/tooltip-icon';
import Card from '../../../../../common/card';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import NewClientFields from '../new-client-fields';
import ExistingClients from '../../../existing-clients';
import MessageError from '../../../../../common/message-error';
import { usePaymentLink } from '../../../../../../store/payment-link';
import { useDashboard } from '../../../../../../store/dashboard';
import { ReactComponent as ArrowRightIcon } from '../../../../../../assets/icons/arrow-right-completed.svg';
import CurrencySelector from '../../../../../common/currency-selector';
import { functionalCurrenciesByCountry } from '../../../../../../config';
import AlertMessages from '../../../../alert-messages';
import ExpirationBox from '../../../../../common/expiration-box';
import { removeProtocolFromUrl } from '../../../../../../utils';

const CreateFormFields = ({
  isRecurring,
  handleSubmit,
  validateForm,
  values,
  errors,
  setFieldValue,
  touched,
  handleChange,
  handleBlur,
  scrollToFormErrors,
  setShowClientData,
  setNewClient,
  errorCardRef,
  countryRef,
  amountRef,
  currencyRef,
  clientEmailRef,
  redirectLinkRef,
  clientDocumentRef,
  countries,
  showClientData,
  newClient,
}: any) => {
  const { merchantMe } = useDashboard();
  const {
    loading,
    errorInfo,
    currencies,
    exchangeRates,
    documentTypes,
    cleanErrorPaymentLink,
    getCurrencies,
    cleanCurrencies,
    getDocumentTypesForPaymentLink,
  } = usePaymentLink();

  const crossBorderCurrency = 'USD';
  const [isCrossBorder, setIsCrossBorder] = useState(false);
  const [crossBorderSameCurrency, setCrossBorderSameCurrency] = useState(false);

  const email = !showClientData || newClient ? values.clientEmail : '';
  const formDisabled = !merchantMe?.canOperate || merchantMe?.fraudReview || loading;

  useEffect(() => {
    validateForm();
  }, [errorInfo]);

  useEffect(() => {
    if (!_.isEmpty(errors) && !_.isEmpty(touched)) {
      scrollToFormErrors(errors);
    }
  }, [errors]);

  useEffect(() => {
    // if (values.country === merchantMe?.country) {
    // setIsCrossBorder(false);
    // setCrossBorderSameCurrency(false);
    // setDefaultCurrency(values.country, false);
    // } else
    if (values.country) {
      setIsCrossBorder(true);
      setCrossBorderSameCurrency(!Object.keys(currencies).find(c => c !== crossBorderCurrency));
      setDefaultCurrency(values.country, true);
    } else if (isRecurring) {
      handleOnChangeCurrency('USD');
    }
  }, [currencies]);

  useEffect(() => {
    if (!values.country && values.amount === 0) setIsCrossBorder(false);
  }, [values.amount]);

  useEffect(() => {
    if (values.country && values.currency) {
      !values.crossBorderData.secondCurrency &&
        setFieldValue('crossBorderData', {
          ...values.crossBorderData,
          secondCurrency: values.currency,
        });
    }
  }, [values.currency]);

  const setDefaultCurrency = (value: string, isCrossBorder: boolean) => {
    if (currencies) {
      if (!isCrossBorder) {
        const defaultCurrency = (functionalCurrenciesByCountry as { [key: string]: string })[value];
        handleOnChangeCurrency(defaultCurrency);
      } else {
        if (currencies['USD']) {
          handleOnChangeCurrency('USD');
        } else {
          const cs = Object.keys(currencies);
          if (cs.length && !cs.filter(d => value === d).length) {
            handleOnChangeCurrency(cs[0]);
          }
        }
      }
    }
  };

  const handleOnChangeCurrency = (value: string) => {
    setFieldValue('currency', value);
    // cleans
    setFieldValue('amount', '');
    if (isCrossBorder) {
      setFieldValue('crossBorderData', {
        ...values.crossBorderData,
        secondCurrency: Object.keys(currencies).find(c => c !== value) || '',
        secondAmount: '',
      });
    }

    cleanErrorPaymentLink();
  };

  const handleOnChangeCountry = (value: string) => {
    if (isRecurring && value === '') {
      setFieldValue('currency', 'USD');
      cleanCurrencies();
    } else {
      getCurrencies(value);
      getDocumentTypesForPaymentLink(value);
    }

    setFieldValue('clientDocumentType', '');
    setFieldValue('clientDocument', '');
    setFieldValue('country', value);
  };

  const applyToFixed = (value: number) => {
    return value && value !== 0 ? value.toFixed(2) : value;
  };

  return (
    <div className="payment-card">
      <AlertMessages />
      <form onSubmit={handleSubmit}>
        {(errorInfo?.title || errorInfo?.message) && Object.keys(errors).length === 0 ? (
          <div ref={errorCardRef} className="create-payment-link-form__error">
            <MessageError errorInfo={errorInfo} />
          </div>
        ) : null}
        <Card>
          <div className="create-payment-link-form__container">
            <div className="create-payment-link-form__title">
              {l(isRecurring ? 'paymentLink.recurring.subtitle' : 'paymentLink.subtitle')}
            </div>
            <div
              ref={countryRef}
              className={`create-payment-link-form__row ${
                values.country && isCrossBorder && !crossBorderSameCurrency ? 'shrunk' : ''
              }`}
            >
              <div className="create-payment-link-form__field">
                <Select
                  name="country"
                  label={l('paymentLink.country')}
                  value={values.country}
                  onChange={e => handleOnChangeCountry(e.target.value)}
                  options={countries()}
                  defaultOptionEnabled={isRecurring}
                  defaultOptionText={isRecurring ? l('common.all.m') : undefined}
                  disabled={formDisabled}
                  error={(touched.country && errors.country) || undefined}
                />
              </div>
              <div className="create-payment-link-form__info">
                <TooltipIcon placement="bottom-end" text={Parser(l('paymentLink.country.tooltip'))} icon={<InfoIcon />} />
              </div>
            </div>
            {(isRecurring && values.country === '') || !isCrossBorder ? (
              <>
                <div ref={currencyRef} className="create-payment-link-form__row">
                  <div ref={amountRef} className="create-payment-link-form__field amount">
                    <Input
                      type="number"
                      name="amount"
                      label={l('paymentLink.amountWantToCharge')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.amount}
                      disabled={formDisabled || !values.currency}
                      error={(touched.amount && errors.amount) || undefined}
                      prefix={values.currency}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                {!crossBorderSameCurrency && (
                  <CurrencySelector
                    firstCurrency={crossBorderCurrency}
                    secondCurrency={Object.keys(currencies).find(c => c !== crossBorderCurrency) || ''}
                    value={values.currency}
                    onChange={value => handleOnChangeCurrency(value)}
                    disabled={formDisabled}
                  />
                )}
                <div
                  ref={currencyRef}
                  className={`create-payment-link-form__row calculator_row ${!crossBorderSameCurrency ? 'shrunk' : ''}`}
                >
                  <div
                    ref={amountRef}
                    className={`create-payment-link-form__field calculator_field ${
                      !crossBorderSameCurrency ? 'amount_cross_border amount_cross_border_left' : ''
                    }`}
                  >
                    <Input
                      type="number"
                      name="amount"
                      label={l(
                        crossBorderCurrency === values.currency
                          ? 'paymentLink.amountWantToCharge'
                          : 'paymentLink.amountYourClientWillPay'
                      )}
                      onChange={e => {
                        const value = e.target.value;
                        if (crossBorderCurrency === values.currency) {
                          setFieldValue('crossBorderData', {
                            ...values.crossBorderData,
                            secondAmount: applyToFixed(value * exchangeRates[values.crossBorderData.secondCurrency]),
                          });
                        } else {
                          setFieldValue('crossBorderData', {
                            ...values.crossBorderData,
                            secondAmount: applyToFixed(value / exchangeRates[values.currency]),
                          });
                        }
                        setFieldValue('amount', value);
                      }}
                      onBlur={handleBlur}
                      value={values.amount}
                      disabled={formDisabled || !values.currency}
                      error={(touched.amount && errors.amount) || undefined}
                      prefix={values.currency}
                    />
                    {!crossBorderSameCurrency && (
                      <div>
                        <p className="create-payment-link-form__exchange_rate">
                          <span>{l('paymentLink.exchangeRate')}</span>
                          <span>
                            &nbsp;{values.currency} {applyToFixed(exchangeRates[values.currency])}
                          </span>
                          <ArrowRightIcon />
                          <span>
                            <span> {values.crossBorderData.secondCurrency} </span>
                            {applyToFixed(exchangeRates[values.crossBorderData.secondCurrency])}
                          </span>
                        </p>
                      </div>
                    )}
                    {crossBorderSameCurrency && (
                      <div className={`create-payment-link-form__info input`}>
                        <TooltipIcon
                          placement="right"
                          text={Parser(
                            l(
                              'paymentLink.tooltip.sameCurrency',
                              (functionalCurrenciesByCountry as { [key: string]: string })[merchantMe?.country || ''] ||
                                '***'
                            )
                          )}
                          icon={<InfoIcon />}
                        />
                      </div>
                    )}
                  </div>
                  {!crossBorderSameCurrency && (
                    <div
                      ref={amountRef}
                      className="create-payment-link-form__field calculator_field amount_cross_border amount_cross_border_right"
                    >
                      <Input
                        type="number"
                        name="second_amount"
                        label={l(
                          crossBorderCurrency === values.currency
                            ? 'paymentLink.amountYourClientWillPay'
                            : 'paymentLink.amountYouWillReceive'
                        )}
                        onChange={e => {
                          const value = e.target.value;
                          setFieldValue('crossBorderData', { ...values.crossBorderData, secondAmount: value });
                          if (crossBorderCurrency === values.crossBorderData.secondCurrency) {
                            setFieldValue('amount', applyToFixed(value * exchangeRates[values.currency]));
                          } else {
                            setFieldValue(
                              'amount',
                              applyToFixed(value / exchangeRates[values.crossBorderData.secondCurrency])
                            );
                          }
                        }}
                        onBlur={handleBlur}
                        value={values.crossBorderData.secondAmount}
                        disabled={formDisabled}
                        prefix={Object.keys(currencies).find(c => c !== values.currency) || ''}
                      />
                      {crossBorderCurrency !== values.currency && (
                        <div>
                          <p className="create-payment-link-form__fx_message">
                            <span>{l('paymentLink.exchangeRate.fxMessage')}</span>
                          </p>
                        </div>
                      )}
                      <div className={`create-payment-link-form__info input`}>
                        <TooltipIcon
                          placement="bottom-end"
                          text={Parser(
                            l(
                              'currencySelector.tooltip',
                              Object.keys(currencies).find(c => c !== crossBorderCurrency) || crossBorderCurrency,
                              (functionalCurrenciesByCountry as { [key: string]: string })[merchantMe?.country || ''] ||
                                '***'
                            )
                          )}
                          icon={<InfoIcon />}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="create-payment-link-form__divider" />
              </>
            )}
            <div className="create-payment-link-form__row">
              <div className="create-payment-link-form__field description">
                <Input
                  name="description"
                  label={l('paymentLink.description')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                  maxLength={100}
                  disabled={formDisabled}
                  error={(touched.description && errors.description) || undefined}
                />
              </div>
              <div className="create-payment-link-form__info hide_sm">
                <TooltipIcon
                  placement="bottom-end"
                  text={Parser(l('paymentLink.description.tooltip'))}
                  icon={<InfoIcon />}
                />
              </div>
            </div>
            {!isRecurring && (
              <div ref={clientEmailRef} className="create-payment-link-form__row">
                <div className="create-payment-link-form__field expiration">
                  <ExpirationBox
                    errors={errors}
                    touched={touched}
                    expirationType={values.expirationType}
                    expirationValue={values.expirationValue}
                    onChangeExpirationType={expirationType => {
                      setFieldValue('expirationType', expirationType);
                    }}
                    onChangeExpirationValue={expirationValue => {
                      setFieldValue('expirationValue', expirationValue);
                    }}
                    expirationEnabled={values.expirationEnabled}
                    toggleExpirationEnabled={() => {
                      setFieldValue('expirationEnabled', !values.expirationEnabled);
                    }}
                  ></ExpirationBox>
                </div>
              </div>
            )}
            {!isRecurring && (!showClientData || newClient) && (
              <div ref={clientEmailRef} className="create-payment-link-form__row">
                <div className="create-payment-link-form__field client-email">
                  <Input
                    name="clientEmail"
                    label={l('paymentLink.clientEmail')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={email}
                    maxLength={50}
                    disabled={formDisabled}
                    error={(touched.clientEmail && errors.clientEmail) || undefined}
                  />
                </div>
              </div>
            )}
            {(!showClientData || newClient) && (
              <div ref={redirectLinkRef} className="create-payment-link-form__row">
                <div className="create-payment-link-form__field client-email">
                  <Input
                    name="redirectLink"
                    label={l('paymentLink.redirectLink')}
                    placeholder={l('companyInformation.kyc.website.placeholder')}
                    onChange={e => setFieldValue('redirectLink', removeProtocolFromUrl(e.target.value))}
                    onBlur={handleBlur}
                    value={values.redirectLink}
                    maxLength={100}
                    disabled={formDisabled}
                    error={(touched.redirectLink && errors.redirectLink) || undefined}
                  />
                </div>
              </div>
            )}
            {!isRecurring && (
              <div className="create-payment-link-form__row shrunk">
                <div className="create-payment-link-form__field">
                  <InputCheckbox
                    name="sendEmail"
                    label={l('paymentLink.sendEmail')}
                    onChange={e => setFieldValue('sendEmail', e.target.checked)}
                    onBlur={handleBlur}
                    value={values.sendEmail}
                    disabled={formDisabled}
                    error={(touched.sendEmail && errors.sendEmail) || undefined}
                  />
                </div>
              </div>
            )}
          </div>
        </Card>
        {!isRecurring && (
          <Accordion header={l('paymentLink.addClientData')} isExpanded={showClientData} onChange={setShowClientData}>
            <div className="create-payment-link-form__container">
              <span>{l('paymentLink.selectClient')}</span>
              <div className="create-payment-link-form__tabs">
                <Tabs
                  value={newClient ? 0 : 1}
                  onChange={() => {
                    setNewClient(!newClient);
                    if (!newClient) {
                      setFieldValue('clientEmail', '');
                    }
                  }}
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab label={<p>{l('paymentLink.newClient')}</p>} />
                  <Tab label={<p>{l('paymentLink.existingClient')}</p>} />
                </Tabs>
              </div>
              {newClient ? (
                <NewClientFields
                  clientFirstName={values.clientFirstName}
                  clientLastName={values.clientLastName}
                  clientDocumentType={values.clientDocumentType}
                  clientDocument={values.clientDocument}
                  clientDocumentRef={clientDocumentRef}
                  documentTypes={documentTypes}
                  formDisabled={formDisabled}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              ) : (
                <ExistingClients
                  value={values.clientSelected}
                  country={values.country}
                  fnSelectClient={c => setFieldValue('clientSelected', c)}
                  externalError={(touched.clientSelected && errors.clientSelected) || undefined}
                />
              )}
            </div>
          </Accordion>
        )}
        <div className="create-payment-link-form__submit_btn">
          <ButtonCustom
            name="create-payment-link"
            type="submit"
            text={l(isRecurring ? 'paymentLink.create.recurring' : 'paymentLink.create')}
            loading={formDisabled}
            onClick={() => {
              if (!_.isEmpty(errors)) {
                scrollToFormErrors(errors);
              }
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default CreateFormFields;
