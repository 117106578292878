import { ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isTabletMobile } from '../../../utils';
import Logo from '../../common/logo';
import { icons } from '../../../utils/menu-utils';
import icMinimize from '../../../assets/icons/arrow-right.svg?url';
import icMenu from '../../../assets/icons/menu.svg?url';
import l from '../../../lang';
import './styles.scss';
import AvatarOptions from '../../common/avatar-options';
import { MenuCodes } from '../../../constants';
import { segmentSideBarOption } from '../../../utils/analytics';
import { useDashboard } from '../../../store/dashboard';

interface SubItemMenu {
  [key: string]: string;
}

interface ItemMenu {
  [key: string]: string | SubItemMenu;
}

interface MenuProps {
  items: ItemMenu[];
  selected: string;
  body: ReactElement;
  canOperate: boolean;
}

const Menu = ({ items, selected, body, canOperate }: MenuProps) => {
  const { merchantMe } = useDashboard();
  const { validKYC = false } = merchantMe || {};

  const history = useHistory();
  const [minimize, setMnimize] = useState(isTabletMobile());

  const getSubMenuSelected = (keySelected: string, subItem: string | SubItemMenu) => {
    return typeof subItem !== 'string' && Object.keys(subItem).some(s => s === keySelected);
  };

  const onClickItem = (item: string | SubItemMenu, key: string) => {
    let nextScreen = '';

    if (key === MenuCodes.VIRTUAL_POS) {
      const virtualPos = /[/]([^/]+)/.exec(item)[1];
      const payload = { userId: merchantMe?.id };

      segmentSideBarOption(virtualPos, payload);
      return;
    }

    if (typeof item === 'string') {
      nextScreen = item;
    } else {
      const firstSubItem = Object.values(item)[0];
      if (firstSubItem) {
        nextScreen = firstSubItem;
      }
    }
    if (!nextScreen) return;

    const segmentSideBarName = /[/]([^/]+)/.exec(nextScreen);

    segmentSideBarOption(segmentSideBarName?.length ? segmentSideBarName[1] : nextScreen, null);
    history.push(nextScreen);
  };

  return (
    <>
      <div className={`arrow_minimize ${minimize ? 'minimize' : ''}`} onClick={() => setMnimize(!minimize)}>
        <img className="img-minimize" src={icMinimize} alt="img-minimize" />
      </div>
      <div className="menu-component" onClick={() => !minimize && isTabletMobile() && setMnimize(true)}>
        <nav className={`nav-dashboard ${minimize ? 'nav-dashboard--minimize' : ''}`}>
          <div className="nav-dashboard__bg">
            <div className="nav-dashboard__logo">
              <Logo
                goHomeDisabled={!canOperate}
                size={`${minimize ? 'sm' : 'lg'}`}
                type={!minimize ? 'vertical' : undefined}
              />
            </div>
            <ul className="menu">
              {items.map((group, idxGroup) => {
                return Object.keys(group).map((key, idx) => {
                  return (
                    <li
                      key={`menu-${idx.toString()}`}
                      className={`menu__item ${
                        !canOperate && !validKYC && key !== MenuCodes.ACTIVATE_ACCOUNT && key !== MenuCodes.PAYMENT_METHODS
                          ? 'menu__item--disabled'
                          : ''
                      }`}
                    >
                      <div className={`${idxGroup !== 0 && idx === 0 ? 'menu__item--separator' : ''}`} />
                      <div
                        className={`menu__item__container ${key === selected ? 'menu__item__container--selected' : ''} ${
                          getSubMenuSelected(selected, group[key]) ? 'menu__item__container--has-subitem-selected' : ''
                        }`}
                        onClick={() => onClickItem(group[key], key)}
                      >
                        <div className="img-item-menu">{icons(key)}</div>
                        {!minimize && <span> {l(`menu.item.${key}`)}</span>}
                      </div>
                      {getSubMenuSelected(selected, group[key]) && (
                        <div className="sub-menu-container">
                          {Object.keys(group[key]).map((subKey, subIdx) => {
                            return (
                              <div
                                className={`sub-menu-item ${subKey === selected ? 'selected' : ''} ${
                                  minimize ? 'minimize' : ''
                                }`}
                                key={`menu-${idx.toString()}-${subIdx.toString()}`}
                                // @ts-ignore
                                onClick={() => history.push(group[key][subKey])}
                              >
                                {minimize && icons(subKey) && <div className="img-sub-item-menu">{icons(subKey)}</div>}
                                {!minimize && <span> {l(`menu.subItem.${subKey}`)}</span>}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </li>
                  );
                });
              })}
            </ul>
          </div>
        </nav>
        {isTabletMobile() && (
          <section className={`section-top-dashboard ${minimize ? 'section-top-dashboard--minimize' : ''}`}>
            <div className="section-top-dashboard__img-menu" onClick={() => setMnimize(false)}>
              <img src={icMenu} alt="img-menu" />
            </div>
            <div className="section-top-dashboard__logo">
              <Logo type="horizontal" />
            </div>
            <div className="section-top-dashboard__avatar">
              <AvatarOptions />
            </div>
          </section>
        )}
        <section className={`section-dashboard ${minimize ? 'section-dashboard--minimize' : ''}`}>
          <div className="section-dashboard__container">{body}</div>
        </section>
      </div>
    </>
  );
};

export default Menu;
