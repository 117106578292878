import './styles.scss';
import TransactionsByCountry from './country';
import TransactionsByPaymentMethod from './payment-method';
import TransactionsByPaymentSolution from './payment-solution';

interface TransactionsByProps {
  period: string;
  days: number;
}

const TransactionsBy = ({ period, days }: TransactionsByProps) => {
  return (
    <div className="transactions_by">
      <TransactionsByCountry days={days} period={period} />
      <TransactionsByPaymentSolution days={days} period={period} />
      <TransactionsByPaymentMethod days={days} period={period} />
    </div>
  );
};

export default TransactionsBy;
