import l from '../../../../lang';
import IntegrationCard from '../integration-card';
import { ReactComponent as BillowshopIcon } from '../../../../assets/icons/integrations/billowshop.svg';
import { usePaymentIntegration } from '../../../../store/payment-integration';
import { DetailsOpened } from '../../../../store/payment-integration/interfaces';
import { segmentIntegrationsMenuHostinger } from '../../../../utils/analytics';

export default () => {
  const { setDetailsOpened } = usePaymentIntegration();

  return (
    <IntegrationCard
      title={l('paymentIntegration.billowshop.title')}
      icon={<BillowshopIcon />}
      color="pink"
      onDetails={() => {
        setDetailsOpened(DetailsOpened.BILLOWSHOP);
      }}
      detailsText={l('paymentIntegration.card.integration.voting.link')}
    />
  );
};
