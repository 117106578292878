import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LanguageSelect from '../../components/language-select';
import Logo from '../../components/common/logo';
import LoginForm from '../../components/login-form';
import Message from '../../components/common/message';
import { MessageTypes } from '../../constants';
import { languages, getLanguage } from '../../utils/index';
import { initLogin, sendLogin } from '../../store/auth/actions';
import { Routes } from '../../config/routes';
import l from '../../lang';
import './styles.scss';
import HelpButton from '../../components/common/help-button';
import ButtonLink from '../../components/common/button-link';
import Loading from '../../components/common/loading';
import useMerchantReferenceParams from '../../hooks/use-merchant-reference-param';
import { useCommon } from '../../store/common';
import useMerchantGaCookie from '../../hooks/use-merchant-ga-cookie';
import useMerchantGaSessionIdCookie from '../../hooks/use-merchant-ga-session-id-cookie';

const Login = ({
  loading,
  error,
  errorInfo,
  checkSessionCompleted,
  signUpSuccess,
  confirmSuccess,
  emailConfirmed,
  fnInitLogin,
  fnSendLogin,
}) => {
  const { changeLanguage } = useCommon();
  const merchantReference = useMerchantReferenceParams();
  const merchantGaCookie = useMerchantGaCookie();
  const merchantGaSessionIdCookie = useMerchantGaSessionIdCookie();
  const history = useHistory();
  const [msgSignUpSuccess, setMsgSignUpSuccess] = useState(signUpSuccess);
  const [msgConfirmSuccess, setMsgConfirmSuccess] = useState(confirmSuccess);
  const [msgEmailConfirmed, setMsgEmailConfirmed] = useState(emailConfirmed);

  const handleLogin = (email, pasword) => {
    fnSendLogin(email, pasword, merchantReference, merchantGaCookie, merchantGaSessionIdCookie);
  };

  useEffect(() => {
    fnInitLogin();
  }, []);

  return (
    <div className="login">
      {!checkSessionCompleted && <Loading fullScreen />}
      {checkSessionCompleted && (
        <div className="home-content">
          <div className="help-button-container">
            <HelpButton />
          </div>
          <div className="form-content">
            <div className="language-container">
              <LanguageSelect languages={languages} selected={getLanguage()} onClick={changeLanguage} />
            </div>
            <Logo size="xl" type="vertical" />
            <div className="title">
              <span className="">{l('login.title')}</span>
            </div>
            {(msgSignUpSuccess || msgEmailConfirmed) && (
              <div className="message-success-container">
                <Message
                  type={MessageTypes.SUCCESS}
                  title={l('signUp.successMessage.title')}
                  body={l('signUp.successMessage.body')}
                  onClose={() => {
                    if (msgSignUpSuccess) {
                      setMsgSignUpSuccess(!msgSignUpSuccess);
                    }
                    if (msgEmailConfirmed) {
                      setMsgEmailConfirmed(!msgEmailConfirmed);
                    }
                  }}
                />
              </div>
            )}
            {msgConfirmSuccess && (
              <div className="message-success-container">
                <Message
                  type={MessageTypes.SUCCESS}
                  title={l('passwordReset.successMessage.title')}
                  body={l('passwordReset.successMessage.body')}
                  onClose={() => setMsgConfirmSuccess(!msgConfirmSuccess)}
                />
              </div>
            )}
            <LoginForm onSubmit={handleLogin} error={error} errorInfo={errorInfo} loading={loading} />
            <div className="link-sign-up">
              <span>{l('login.signUp.label')} </span>
              <ButtonLink
                text={l('login.signUp')}
                onClick={() => history.push(`${Routes.SIGN_UP}${window.document.location.search}`)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Login.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  signUpSuccess: PropTypes.bool,
  emailConfirmed: PropTypes.bool,
  fnSendLogin: PropTypes.func,
};

const mapStateToProps = state => ({
  loading: state.auth.loading,
  error: state.auth.error,
  errorInfo: state.auth.errorInfo,
  checkSessionCompleted: state.auth.checkSessionCompleted,
  signUpSuccess: state.signUp.success,
  confirmSuccess: state.passwordReset.confirmSuccess,
  emailConfirmed: state.emailValidation.confirm.success,
});

const mapDispatchToProps = dispatch => ({
  fnInitLogin: () => dispatch(initLogin()),
  fnSendLogin: (email, pasword, merchantReference, merchantGaCookie, merchantGaSessionIdCookie) =>
    dispatch(sendLogin(email, pasword, merchantReference, merchantGaCookie, merchantGaSessionIdCookie)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
