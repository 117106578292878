import { PanelStore } from '../interfaces';

export namespace ChargebacksSelectors {
  export const getLoading = (state: PanelStore.RootState) => {
    return state.chargebacks.loading;
  };
  export const getError = (state: PanelStore.RootState) => {
    return state.chargebacks.error;
  };
  export const getAllChargebacks = (state: PanelStore.RootState) => {
    return state.chargebacks.allChargebacks;
  };
  export const getChargebackDetail = (state: PanelStore.RootState) => {
    return state.chargebacks.chargebackDetail;
  };
  export const getExport = (state: PanelStore.RootState) => {
    return state.chargebacks.export;
  };
  export const getExportType = (state: PanelStore.RootState) => {
    return state.chargebacks.exportType;
  };
  export const getPaymentMethods = (state: PanelStore.RootState) => {
    return state.chargebacks.paymentMethods;
  };
  export const getChargebackUploadDocument = (state: PanelStore.RootState) => {
    return state.chargebacks.uploadDocument;
  };
}
