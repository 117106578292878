import Cookies from 'universal-cookie';

const COOKIE_NAME = 'dgo_client_data';

export type ClientTrackerData = {
  utmSource: string | null;
  utmMedium: string | null;
  utmCampaign: string | null;
  utmTerm: string | null;
  utmContent: string | null;
  gaClientId: string | null;
  fbClientId: string | null;
  gclid: string | null;
  gac: string | null;
};

export default function collectTrackerData(): ClientTrackerData {
  const urlUTMParams = collectUtmParams();

  if (Object.values(urlUTMParams).some(value => value !== null)) {
    const clientData = {
      ...collectUtmParams(),
      gaClientId: getGaClientId(),
      fbClientId: getFbClientId(),
      gclid: getGclid(),
      gac: getGac(),
    };
    return clientData;
  }

  const cookieDgoData = collectTrackerDataFromCookie();
  if (cookieDgoData) {
    return cookieDgoData;
  }

  return {
    utmSource: null,
    utmMedium: null,
    utmCampaign: null,
    utmTerm: null,
    utmContent: null,
    gaClientId: getGaClientId(),
    fbClientId: getFbClientId(),
    gclid: getGclid(),
    gac: getGac(),
  };
}

function collectTrackerDataFromCookie() {
  const cookies = new Cookies();
  const cookieDgoData = cookies.get(COOKIE_NAME);
  if (cookieDgoData) {
    return {
      utmSource: cookieDgoData.utm_source,
      utmMedium: cookieDgoData.utm_medium,
      utmCampaign: cookieDgoData.utm_campaign,
      utmTerm: cookieDgoData.utm_term,
      utmContent: cookieDgoData.utm_content,
      gaClientId: cookieDgoData.gaClientId,
      fbClientId: cookieDgoData.fbClientId,
      gclid: cookieDgoData.gclid,
    };
  }
  return null;
}

function collectUtmParams() {
  const urlParams = new URLSearchParams(window.location.search);
  const utmParams = {
    utmSource: urlParams.get('utm_source') || null,
    utmMedium: urlParams.get('utm_medium') || null,
    utmCampaign: urlParams.get('utm_campaign') || null,
    utmTerm: urlParams.get('utm_term') || null,
    utmContent: urlParams.get('utm_content') || null,
    gclid: urlParams.get('gclid') || null,
  };
  return utmParams;
}

function getFbClientId() {
  let result = /_fbp=(fb\.1\.\d+\.\d+)/.exec(window.document.cookie);
  if (!(result && result[1])) {
    return null;
  }
  return result[1];
}

function getGaClientId() {
  const gaClientId = window.ga?.getAll()[0].get('clientId');
  return gaClientId;
}

function getGclid() {
  const urlParams = new URLSearchParams(window.location.search);

  return urlParams.get('gclid');
}

function getGac() {
  const urlParams = new URLSearchParams(window.location.search);

  return urlParams.get('_gac');
}
