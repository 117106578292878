import { takeLatest, put, call, select } from 'redux-saga/effects';
import { checkToken } from '../auth/sagas';
import { Urls } from '../../config/urls';
import { getTask, postTask, patchTask } from '../../services';
import {
  CheckoutThemesActionType,
  getCheckoutThemesSuccess,
  getCheckoutThemesError,
  postCreateCheckoutThemeSuccess,
  toggleCheckoutThemeSuccess,
  toggleCheckoutThemeError,
} from './actions';
import { PanelStore } from '../interfaces';

function* getCheckoutThemes(action: any) {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  let params = `?page=${action.page || 0}`;
  params += action.filter ? `&${action.filter}` : '';

  const { response, error } = yield call(getTask, {
    url: Urls.api.allCheckoutThemes + params,
    token: state.auth.token,
  });

  if (response) {
    yield put(getCheckoutThemesSuccess(response));
  } else {
    yield put(getCheckoutThemesError(error));
  }
}

function* postCreateCheckoutTheme(action: any) {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  const { response, error } = yield call(postTask, {
    url: Urls.api.createCheckoutTheme,
    token: state.auth.token,
    body: action.payload,
  });

  if (response) {
    yield put(postCreateCheckoutThemeSuccess(response));
  } else {
    yield put(getCheckoutThemesError(error));
  }
}

function* toggleCheckoutTheme(action: any) {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  const { response, error } = yield call(patchTask, {
    url: Urls.api.toggleCheckoutTheme.replace(':id', `${action.id}`),
    token: state.auth.token,
    body: {},
  });

  if (response) {
    yield put(toggleCheckoutThemeSuccess());
    yield getCheckoutThemes({});
  } else {
    yield put(toggleCheckoutThemeError(error));
  }
}

export function* getCheckoutThemesSaga() {
  yield takeLatest(CheckoutThemesActionType.GET_CHECKOUT_THEMES, getCheckoutThemes);
}

export function* postCreateCheckoutThemeSaga() {
  yield takeLatest(CheckoutThemesActionType.POST_CREATE_CHECKOUT_THEME, postCreateCheckoutTheme);
}

export function* toggleCheckoutThemeSaga() {
  yield takeLatest(CheckoutThemesActionType.TOGGLE_CHECKOUT_THEME, toggleCheckoutTheme);
}
