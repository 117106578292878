import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import l from '../../../../lang';
import { refundStatus } from '../../../../utils/refunds-utils';
import Filter from '../../../common/filter';
import Select from '../../../common/select';
import DateRangePicker from '../../../common/date-range-picker';
import './styles.scss';
import { getPaymentMethodsForRefunds } from '../../../../store/refunds/actions';
import MessageError from '../../../common/message-error';
import { useAvailableCountries } from '../../../../hooks/use-available-countries';

const RefundFilter = ({ paymentMethods, fnGetPaymentMethods, fnChangeFilters }) => {
  const initialValues = { status: '', country: '', from: '', to: '', paymentMethodId: '' };
  const [filter, setFilter] = useState(initialValues);
  const [appliedFilters, setAppliedFilters] = useState(0);
  const allCountries = useAvailableCountries();

  const handleChangeFilter = filters => {
    setFilter(filters);

    let filterParam = '';
    let applied = 0;
    Object.keys(filters).forEach(key => {
      if (filters[key]) {
        filterParam += filterParam ? '&' : '';
        filterParam += `${_.snakeCase(key)}=${filters[key]}`;
        applied += 1;
      }
    });
    setAppliedFilters(applied);
    fnChangeFilters(filterParam);
  };

  const countries = () => {
    const countries = {};
    allCountries.forEach(co => {
      countries[co] = l(`countryCode.${co}`);
    });
    return countries;
  };

  return (
    <div className="refund-filter">
      <Filter
        pillShaped
        clearFilter={() => handleChangeFilter(initialValues)}
        appliedFilters={appliedFilters}
        items={[
          {
            title: (
              <span>
                <span className="refund-filter__title">{l('refunds.status')}: </span>
                <span className="refund-filter__summary">
                  {`${l(filter.status ? `refunds.status.${filter.status}` : 'common.all.m')}`}
                </span>
              </span>
            ),
            component: (
              <Select
                name="filter-status"
                label={l('refunds.filter.status')}
                defaultOptionText={l('common.all.m')}
                defaultOptionEnabled
                value={filter.status}
                size="lg"
                onChange={e => {
                  handleChangeFilter({ ...filter, status: e.target.value });
                }}
                options={refundStatus()}
              />
            ),
          },
          {
            title: (
              <span>
                <span className="refund-filter__title">{l('refunds.country')}: </span>
                <span className="refund-filter__summary">
                  {`${l(filter.country ? `countryCode.${filter.country}` : 'common.all.m')}`}
                </span>
              </span>
            ),
            component: (
              <Select
                name="filter-status"
                size="lg"
                label={l('refunds.filter.country')}
                defaultOptionText={l('common.all.m')}
                defaultOptionEnabled
                value={filter.country}
                onChange={e => {
                  fnGetPaymentMethods(e.target.value);
                  handleChangeFilter({ ...filter, paymentMethodId: '', country: e.target.value });
                }}
                options={countries()}
              />
            ),
          },
          {
            title: (
              <span>
                <span className="refund-filter__title">{l('refunds.date')}: </span>
                <span className="refund-filter__summary">
                  {`${
                    filter.from || filter.to
                      ? `${filter.from ? `${l('datepicker.from')} ${filter.from}` : ''}  ${
                          filter.from && filter.to ? ' | ' : ''
                        } ${filter.to ? `${l('datepicker.to')} ${filter.to}` : ''}`
                      : l('common.all.f')
                  }`}
                </span>
              </span>
            ),
            component: (
              <DateRangePicker
                fromDate={filter.from}
                toDate={filter.to}
                changeFrom={from => handleChangeFilter({ ...filter, from })}
                changeTo={to => handleChangeFilter({ ...filter, to })}
              />
            ),
          },
          {
            title: (
              <span>
                <span className="refund-filter__title">{l('refunds.paymentMethod')}: </span>
                <span className="refund-filter__summary">
                  {paymentMethods.data[filter.paymentMethodId] || l('common.all.m')}
                </span>
              </span>
            ),
            component: (
              <div className="refund-filter__container">
                {!filter.country && <div>{l('refunds.filter.paymentMethod.desc')}</div>}
                {paymentMethods.error && <MessageError error={paymentMethods.error} errorInfo={paymentMethods.errorInfo} />}
                <Select
                  name="filter-payment-method-id"
                  label={l('refunds.filter.paymentMethod')}
                  defaultOptionText={l('common.all.f')}
                  defaultOptionEnabled
                  value={filter.paymentMethodId}
                  size="lg"
                  disabled={_.isEmpty(paymentMethods.data) || paymentMethods.loading}
                  onChange={e => {
                    handleChangeFilter({ ...filter, paymentMethodId: e.target.value });
                  }}
                  options={paymentMethods.data}
                />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

RefundFilter.propTypes = {
  paymentMethods: PropTypes.object,
  fnGetPaymentMethods: PropTypes.func,
  fnChangeFilters: PropTypes.func,
};

const mapStateToProps = state => ({
  paymentMethods: state.refunds.paymentMethods,
});

const mapDispatchToProps = dispatch => ({
  fnGetPaymentMethods: country => dispatch(getPaymentMethodsForRefunds(country)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RefundFilter);
