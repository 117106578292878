import l from '../../../../lang';
import IntegrationCard from '../integration-card';
import { ReactComponent as WixIcon } from '../../../../assets/icons/integrations/wix.svg';
import { usePaymentIntegration } from '../../../../store/payment-integration';
import { DetailsOpened } from '../../../../store/payment-integration/interfaces';

export default () => {
  const { setDetailsOpened } = usePaymentIntegration();

  return (
    <IntegrationCard
      title={l('paymentIntegration.wix.title')}
      icon={<WixIcon />}
      color="black"
      onDetails={() => {
        setDetailsOpened(DetailsOpened.WIX);
      }}
      detailsText={l('paymentIntegration.card.integration.voting.link')}
    />
  );
};
