import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import IconButton from '@mui/material/IconButton';
import l from '../../../lang';
import './styles.scss';
import Loading from '../../common/loading';
import {
  getCheckoutThemes,
  changeShowCreateCheckoutTheme,
  changeUpdateCheckoutTheme,
  toggleCheckoutTheme,
  hideCheckoutThemeError,
} from '../../../store/checkout-theme/actions';
import Table from '../../common/table';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import { ReactComponent as EmptyIcon } from '../../../assets/icons/empty-payment-links.svg';
import Dialog from '../../common/dialog';
import CreateTheme from './create-theme';
import UpdateTheme from './update-theme';
import ChipStatus from '../../common/chip-status';
import { createDate } from '../../../utils/index';
import TopDashboardSection from '../../common/top-dashboard-section';
import Card from '../../common/card';
import { Button } from '../../common/button';
import CardDescription from '../../common/card-description';
import AlertMessages from '../alert-messages';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';
import ButtonCustom from '../../common/__deprecated__/button-custom';

const CheckoutThemes = ({
  loading,
  error,
  errorInfo,
  allCheckoutThemes,
  showCreateCheckoutTheme,
  showUpdateCheckoutTheme,
  kycReview,
  fnGetCheckoutThemes,
  fnShowCreateCheckoutTheme,
  fnChangeUpdateCheckoutTheme,
  fnToggleCheckoutTheme,
  fnHideCheckoutThemeError,
}) => {
  useEffect(() => {
    fnGetCheckoutThemes();
  }, [fnGetCheckoutThemes]);

  const dataExist = allCheckoutThemes?.data?.length > 0;

  return (
    <>
      <TopDashboardSection title={l('menu.item.CHECKOUT_THEMES')} />
      <AlertMessages />
      <Card>
        <CardDescription>{l('menu.item.CHECKOUT_THEMES.desc')}</CardDescription>
        <div className="checkout-themes__options">
          <Button onClick={() => fnShowCreateCheckoutTheme(true)} startIcon={<PlusIcon />}>
            {l('checkout_themes.button')}
          </Button>
        </div>
        <div className={`checkout-themes`}>
          <div className={`checkout-themes__container ${dataExist ? 'checkout-themes__container--data-exist' : ''}`}>
            {loading && (
              <div className="checkout-themes__loading">
                <Loading />
              </div>
            )}
            {!loading && (
              <div className="checkout-themes__table">
                <Table
                  idxHiddenColumnsOnMobile={[3, 5]}
                  withBtnMenu
                  headers={[
                    l('checkout_themes.name'),
                    l('checkout_themes.createTheme.primary_color'),
                    l('checkout_themes.createTheme.bg_primary_color'),
                    l('checkout_themes.createTheme.text_primary_color'),
                    l('checkout_themes.creationDate'),
                    l('checkout_themes.review'),
                  ]}
                  body={
                    (dataExist &&
                      allCheckoutThemes?.data?.map(checkoutTheme => {
                        return [
                          <span>{checkoutTheme.name}</span>,
                          <div>
                            <span
                              className="checkout-themes__table__color_icon"
                              style={{ backgroundColor: checkoutTheme.theme_schema?.primary_color }}
                            ></span>
                          </div>,
                          <div>
                            <span
                              className="checkout-themes__table__color_icon"
                              style={{ backgroundColor: checkoutTheme.theme_schema?.bg_primary_color }}
                            ></span>
                          </div>,
                          <div>
                            <span
                              className="checkout-themes__table__color_icon"
                              style={{ backgroundColor: checkoutTheme.theme_schema?.text_primary_color }}
                            ></span>
                          </div>,
                          <Moment format="DD/MM/YYYY">{createDate(checkoutTheme.created_at)}</Moment>,
                          <span>
                            <ChipStatus
                              status={checkoutTheme.status === 'REJECTED' ? 'declined' : checkoutTheme.status}
                              text={l(`checkout_themes.status.${checkoutTheme.status.toLowerCase()}`)}
                            />
                          </span>,
                          <div>
                            <Tooltip title={l('common.copy')} placement="top">
                              <IconButton onClick={() => fnChangeUpdateCheckoutTheme(checkoutTheme)}>
                                <ContentCopyIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </div>,

                          <Tooltip
                            title={
                              checkoutTheme.active ? l(`checkout_themes.status.disable`) : l(`checkout_themes.status.enable`)
                            }
                            placement="top"
                          >
                            <Switch
                              onClick={() => fnToggleCheckoutTheme(checkoutTheme.id)}
                              disabled={!checkoutTheme.approved_at}
                              checked={checkoutTheme.active}
                            />
                          </Tooltip>,
                        ];
                      })) ||
                    null
                  }
                  emptyBody={
                    <div className="checkout-themes__no_data">
                      <EmptyIcon />
                      <div className="checkout-themes__no_data__title">{l('checkout_themes.emptyTable.title')}</div>
                      <div>{l('checkout_themes.emptyTable.body')}</div>
                    </div>
                  }
                  rowsPerPageOptions={[allCheckoutThemes?.size]}
                  rowsLength={allCheckoutThemes?.totalElements}
                  rowsPerPage={allCheckoutThemes?.size}
                  page={allCheckoutThemes?.page}
                  handleChangePage={(event, newPage) => {
                    fnGetCheckoutThemes(newPage);
                  }}
                />
              </div>
            )}
          </div>
          {error && errorInfo && (
            <Dialog
              titleCenter
              dividers={false}
              title={l(errorInfo.title)}
              unpaddingFooter
              unpaddingContent
              body={
                <div className="confirm-body-container">
                  <div>{l(errorInfo.message)}</div>
                  <br />
                </div>
              }
              footer={
                <div className="confirm-footer-container">
                  <ButtonCustom text={l('common.close')} size="full-bottom" onClick={() => fnHideCheckoutThemeError()} />
                </div>
              }
            />
          )}
          {showCreateCheckoutTheme && (
            <Dialog
              dividers={false}
              fullScreenMobile
              unpaddingContent
              type="sm"
              title={l('checkout_themes.createTheme.title')}
              body={<CreateTheme />}
              handleClose={() => {
                fnGetCheckoutThemes();
                fnShowCreateCheckoutTheme(false);
              }}
            />
          )}
          {showUpdateCheckoutTheme && (
            <Dialog
              dividers={false}
              fullScreenMobile
              unpaddingContent
              type="sm"
              title={l('checkout_themes.updateTheme.title')}
              body={<UpdateTheme />}
              handleClose={() => {
                fnChangeUpdateCheckoutTheme(null);
              }}
            />
          )}
        </div>
      </Card>
    </>
  );
};

CheckoutThemes.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  errorInfo: PropTypes.object,
  allSettlements: PropTypes.object,
  showCreateCheckoutTheme: PropTypes.bool,
  fnGetCheckoutThemes: PropTypes.func,
  fnShowCreateCheckoutTheme: PropTypes.func,
};

const mapStateToProps = state => ({
  loading: state.checkoutThemes.loading,
  error: state.checkoutThemes.error,
  errorInfo: state.checkoutThemes.errorInfo,
  allCheckoutThemes: state.checkoutThemes.allCheckoutThemes,
  showCreateCheckoutTheme: state.checkoutThemes.showCreateCheckoutTheme,
  showUpdateCheckoutTheme: state.checkoutThemes.showUpdateCheckoutTheme,
});

const mapDispatchToProps = dispatch => ({
  fnGetCheckoutThemes: page => dispatch(getCheckoutThemes(page)),
  fnShowCreateCheckoutTheme: show => dispatch(changeShowCreateCheckoutTheme(show)),
  fnChangeUpdateCheckoutTheme: checkoutTheme => dispatch(changeUpdateCheckoutTheme(checkoutTheme)),
  fnToggleCheckoutTheme: checkoutThemeId => dispatch(toggleCheckoutTheme(checkoutThemeId)),
  fnHideCheckoutThemeError: () => dispatch(hideCheckoutThemeError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutThemes);
