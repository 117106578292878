import {
  SegmentOnboardingStepPayload,
  SegmentSignUpPayload,
  SegmentLoginPayload,
  segmentKycFormL2DocsSubmitPayload,
  segmentKycFormL2BankSubmitPayload,
  segmentPaymentLinkCreatePaymentLinkPayload,
  segmentVirtualPOSCreatePaymentPayload,
  segmentIntegrationsPayButtonCreatePayButtonPayload,
  companyType,
  SegmentUserMenuProfileEditSavePayload,
  SegmentPaymentLinkLinkListCopyLinkPayload,
  SegmentTransactionsTransactionDetailPayload,
  SegmentTransactionsFilterSearchBoxPayload,
  SegmentTransactionsFilterDatePayload,
  SegmentTransactionsFilterCountryPayload,
  SegmentTransactionsFilterPaymentMethodPayload,
  SegmentTransactionsFilterPaymentStatusPayload,
  SegmentTransactionsTransactionDetailCopyInvoiceIdPayload,
  SegmentTransactionsTransactionDetailCopyExternalIdPayload,
  SegmentBankAccountsAddBankAccountPayload,
  SegmentPaymentMethodActivationCountryPaymentPayload,
  SegmentPaymentMethodEditCoverageRegionCountriesPayload,
} from './segment.d';

const segmentIdentify = (merchantId: string, countryCode: string, extraPayload: Object = {}): void => {
  window.analytics.identify(merchantId, {
    countryCode,
    ...extraPayload,
  });
};

const segmentOnboardingStep = (payload: SegmentOnboardingStepPayload): void => {
  window.analytics.track('kycformL1-submit', payload);
};

const segmentSignUp = (payload: SegmentSignUpPayload): void => {
  window.analytics.track('signUp', payload);
};

const segmentSignUpTc = (): void => {
  window.analytics.track('accountSignup-TC', null);
};

const segmentSignUpPrivacyPolicy = (): void => {
  window.analytics.track('accountSignup-PrivacyPolicy', null);
};

const segmentLogin = (payload: SegmentLoginPayload): void => {
  window.analytics.track('accountLogin', payload);
};

const segmentSideBarOption = (option: string, payload: object | null | undefined): void => {
  window.analytics.track(`sideBar-${option}`, payload);
};

const segmentKycStep1TermsConditions = (): void => {
  window.analytics.track('kycStep1TermsConditions', null);
};

const segmentKycStep1CompleteB = (option: number): void => {
  window.analytics.track(`companyInfo-kycStep1CompleteB${option}`, null);
};

const segmentKycStep2CompleteB = (option: number, isCompany: boolean): void => {
  window.analytics.track(`companyInfo-kycStep2CompleteB${option}`, {
    type: isCompany ? 'COMPANY' : 'PERSON',
  });
};

const segmentKycFormL1 = (type: companyType): void => {
  window.analytics.track(`kycFormL1-${type}`, null);
};

const segmentKycFormL2BankSubmit = (payload?: segmentKycFormL2BankSubmitPayload): void => {
  window.analytics.track(`kycFormL2-bankSubmit`, payload);
};

const segmentKycFormL2UploadDocBank = (): void => {
  window.analytics.track(`kycFormL2-uploadDocBank`, null);
};

const segmentKycFormL2UploadDocOwner = (): void => {
  window.analytics.track(`kycFormL2-uploadDocOwner`, null);
};

const segmentKycFormL2UploadDocAddress = (): void => {
  window.analytics.track(`kycFormL2-uploadDocAddress`, null);
};

const segmentKycFormL2UploadDocCompanyTax = (): void => {
  window.analytics.track(`kycFormL2-uploadDocCompanyTax`, null);
};

const segmentKycFormL2UploadDocCompanyIncorporation = (): void => {
  window.analytics.track(`kycFormL2-uploadDocCompanyIncorporation`, null);
};

const segmentKycFormL2DocsSubmit = (payload?: segmentKycFormL2DocsSubmitPayload): void => {
  window.analytics.track(`kycFormL2-docsSubmit`, payload);
};

const segmentHomeQuickLinkEditCountries = (): void => {
  window.analytics.track('home-quickLink-editCountries', null);
};

const segmentHomeKycStep2Complete = (isCompany: boolean): void => {
  window.analytics.track('home-kycStep2Complete', {
    type: isCompany ? 'COMPANY' : 'PERSON',
  });
};

const segmentProductMenuCreatePaymentLink = (): void => {
  window.analytics.track('productMenu-createPaymentLink', null);
};

const segmentProductMenuUseSubscriptions = (): void => {
  window.analytics.track('productMenu-useSubscriptions', null);
};

const segmentProductMenuUseCommissions = (): void => {
  window.analytics.track('productMenu-useCommissions', null);
};

const segmentProductMenuSeeIntegrations = (): void => {
  window.analytics.track('productMenu-seeIntegrations', null);
};

const segmentProductMenuSeeFirstStepsDoc = (): void => {
  window.analytics.track('productMenu-seeFirstStepsDoc', null);
};

const segmentCompanyInfoUploadLogo = (): void => {
  window.analytics.track('companyInfo-uploadLogo', null);
};

const segmentPaymentLinkListBtnCreatePaymentLink = (): void => {
  window.analytics.track('paymentLink-linkList-btnCreatePaymentLink', null);
};

const segmentPaymentLinkCreatePaymentLink = (payload: segmentPaymentLinkCreatePaymentLinkPayload): void => {
  window.analytics.track('paymentLink-createPaymentLink', payload);
};

const segmentPaymentLinkLinkBoxCopyLink = (): void => {
  window.analytics.track('paymentLink-linkBox-copyLink', null);
};

const segmentPaymentLinkShareFacebook = (): void => {
  window.analytics.track('paymentLink-linkBox-shareFacebook', null);
};

const segmentPaymentLinkLinkBoxShareWhatsApp = (): void => {
  window.analytics.track('paymentLink-linkBox-shareWhatsApp', null);
};

const segmentPaymentLinkLinkBoxShareMobile = (): void => {
  window.analytics.track('paymentLink-linkBox-shareMobile', null);
};

const segmentVirtualPOSCreatePayment = (payload: segmentVirtualPOSCreatePaymentPayload): void => {
  window.analytics.track('virtualPOS-createPayment', payload);
};

const segmentIntegrationsMenuKeys = (): void => {
  window.analytics.track('integrations-menu-keys', null);
};

const segmentIntegrationsMenuPayButton = (): void => {
  window.analytics.track('integrations-menu-payButton', null);
};

const segmentIntegrationsMenuShopify = (): void => {
  window.analytics.track('integrations-menu-shopify', null);
};

const segmentIntegrationsMenuMagento = (): void => {
  window.analytics.track('integrations-menu-magento', null);
};

const segmentIntegrationsMenuHostinger = (): void => {
  window.analytics.track('integrations-menu-hostinger', null);
};

const segmentIntegrationsMenuVtex = (): void => {
  window.analytics.track('integrations-menu-vtex', null);
};

const segmentIntegrationsMenuWix = (): void => {
  window.analytics.track('integrations-menu-wix', null);
};

const segmentIntegrationsMenuBillowshop = (): void => {
  window.analytics.track('integrations-menu-billowshop', null);
};

const segmentIntegrationsMenuJumpseller = (): void => {
  window.analytics.track('integrations-menu-jumpseller', null);
};

const segmentIntegrationsMenuBigcommerce = (): void => {
  window.analytics.track('integrations-menu-bigcommerce', null);
};

const segmentIntegrationsMenuVirtuemart = (): void => {
  window.analytics.track('integrations-menu-virtuemart', null);
};

const segmentIntegrationsMenuOpencart = (): void => {
  window.analytics.track('integrations-menu-opencart', null);
};

const segmentIntegrationsMenuFenicio = (): void => {
  window.analytics.track('integrations-menu-fenicio', null);
};

const segmentIntegrationsHostingerKeysCopyApiKey = (): void => {
  window.analytics.track('integrations-hostinger-keys-copyApiKey', null);
};

const segmentIntegrationsHostingerKeysCopySecretKey = (): void => {
  window.analytics.track('integrations-hostinger-keys-copySecretKey', null);
};

const segmentIntegrationsHostingerPageLinkDocs = (): void => {
  window.analytics.track('integrations-hostinger-pageLink-docs', null);
};

const segmentIntegrationsMenuPrestaShop = (): void => {
  window.analytics.track('integrations-menu-prestaShop', null);
};

const segmentIntegrationsMenuApiIntegration = (): void => {
  window.analytics.track('integrations-menu-apiIntegration', null);
};

const segmentIntegrationsMenuWoocommerce = (): void => {
  window.analytics.track('integrations-menu-woocommerce', null);
};

const segmentIntegrationsKeysCopyApiKey = (): void => {
  window.analytics.track('integrations-keys-copyApiKey', null);
};

const segmentIntegrationsKeysCopySecretKey = (): void => {
  window.analytics.track('integrations-keys-copySecretKey', null);
};

const segmentIntegrationsPayButtonCeatePayButton = (payload: segmentIntegrationsPayButtonCreatePayButtonPayload): void => {
  window.analytics.track('integrations-payButton-createPayButton', payload);
};

const segmentIntegrationsPayButtonCopyCode = (): void => {
  window.analytics.track('integrations-payButton-copyCode', null);
};

const segmentIntegrationsShopifyInstall = (): void => {
  window.analytics.track('integrations-shopify-install', null);
};

const segmentIntegrationsShopifyPageLinkDocs = (): void => {
  window.analytics.track('integrations-shopify-pageLink-docs', null);
};

const segmentIntegrationsMagentoInstall = (): void => {
  window.analytics.track('integrations-magento-install', null);
};

const segmentIntegrationsPrestaShopInstall = (): void => {
  window.analytics.track('integrations-prestaShop-install', null);
};

const segmentIntegrationsPrestaShopDownloadPlugin = (): void => {
  window.analytics.track('integrations-prestaShop-downloadPlugin', null);
};

const segmentIntegrationsMagentoPageLinkDocs = (): void => {
  window.analytics.track('integrations-magento-pageLink-docs', null);
};

const segmentIntegrationsApiIntegrationPageLinkDocs = (): void => {
  window.analytics.track('integrations-apiIntegration-pageLink-docs', null);
};

const segmentIntegrationsWooCommerceInstall = (): void => {
  window.analytics.track('integrations-woocommerce-install', null);
};

const segmentIntegrationsWooCommercePageLinkDocs = (): void => {
  window.analytics.track('integrations-woocommerce-pageLink-docs', null);
};

const segmentIntegrationsWooCommerceKeysCopyApiKey = (): void => {
  window.analytics.track('integrations-woocommerce-keys-copyApiKey', null);
};

const segmentIntegrationsWooCommerceKeysCopySecretKey = (): void => {
  window.analytics.track('integrations-woocommerce-keys-copySecretKey', null);
};

const segmentIntegrationsMagentoKeysCopyApiKey = (): void => {
  window.analytics.track('integrations-magento-keys-copyApiKey', null);
};

const segmentIntegrationsPrestaShopKeysCopyApiKey = (): void => {
  window.analytics.track('integrations-prestaShop-keys-copyApiKey', null);
};

const segmentIntegrationsMagentoKeysCopySecretKey = (): void => {
  window.analytics.track('integrations-magento-keys-copySecretKey', null);
};

const segmentIntegrationsPrestaShopKeysCopySecretKey = (): void => {
  window.analytics.track('integrations-prestaShop-keys-copySecretKey', null);
};

const segmentUserMenuProfileEdit = (): void => {
  window.analytics.track('userMenu-profile-edit', null);
};

const segmentUserMenuProfileChangePassword = (): void => {
  window.analytics.track('userMenu-profile-changePassword', null);
};

const segmentUserMenuProfileEditSave = (payload: SegmentUserMenuProfileEditSavePayload): void => {
  window.analytics.track('userMenu-profile-editSave', payload);
};

const segmentUserMenuProfilePasswordSave = (): void => {
  window.analytics.track('userMenu-profile-passwordSave', null);
};

const segmentPaymentLinkLinkListCopyLink = (payload: SegmentPaymentLinkLinkListCopyLinkPayload): void => {
  window.analytics.track('paymentLink-linkList-copyLink', payload);
};

const segmentTransactionsDownloadReport = (): void => {
  window.analytics.track('transactions-downloadReport', null);
};

const segmentTransactionsTransactionDetail = (payload: SegmentTransactionsTransactionDetailPayload): void => {
  window.analytics.track('transactions-transactionDetail', payload);
};

const segmentTransactionsFilterSearchBox = (payload: SegmentTransactionsFilterSearchBoxPayload): void => {
  window.analytics.track('transactions-filter-searchBox', payload);
};

const segmentTransactionsFilterDate = (payload: SegmentTransactionsFilterDatePayload): void => {
  window.analytics.track('transactions-filter-date', payload);
};

const segmentTransactionsFilterCountry = (payload: SegmentTransactionsFilterCountryPayload): void => {
  window.analytics.track('transactions-filter-country', payload);
};

const segmentTransactionsFilterPaymentMethod = (payload: SegmentTransactionsFilterPaymentMethodPayload): void => {
  window.analytics.track('transactions-filter-paymentMethod', payload);
};

const segmentTransactionsFilterPaymentStatus = (payload: SegmentTransactionsFilterPaymentStatusPayload): void => {
  window.analytics.track('transactions-filter-paymentStatus', payload);
};

const segmentTransactionsTransactionDetailCopyInvoiceId = (
  payload: SegmentTransactionsTransactionDetailCopyInvoiceIdPayload
): void => {
  window.analytics.track('transactions-transactionDetail-copyInvoiceId', payload);
};

const segmentTransactionsTransactionDetailCopyExternalId = (
  payload: SegmentTransactionsTransactionDetailCopyExternalIdPayload
): void => {
  window.analytics.track('transactions-transactionDetail-copyExternalId', payload);
};

const segmentRefundsFilter = (): void => {
  window.analytics.track('refunds-filter', null);
};

const segmentBankAccountsAddBankAccount = (payload: SegmentBankAccountsAddBankAccountPayload): void => {
  window.analytics.track('bankAccounts-addBankAccount', payload);
};

const segmentBankAccountsBaDetail = (): void => {
  window.analytics.track('bankAccounts-baDetail', null);
};

const segmentPaymentMethodEditCoverage = (): void => {
  window.analytics.track('paymentMethod-editCoverage', null);
};

const segmentPaymentMethodActivationCountryPayment = (
  payload: SegmentPaymentMethodActivationCountryPaymentPayload
): void => {
  window.analytics.track('paymentMethod-activationCountryPayment', payload);
};

const segmentPaymentMethodEditCoverageRegionCountries = ({
  region,
  ...payload
}: SegmentPaymentMethodEditCoverageRegionCountriesPayload): void => {
  window.analytics.track(`paymentMethod-editCoverage-${region}Countries`, payload);
};

export {
  segmentIdentify,
  segmentOnboardingStep,
  segmentSignUp,
  segmentSignUpTc,
  segmentSignUpPrivacyPolicy,
  segmentLogin,
  segmentSideBarOption,
  segmentKycStep1TermsConditions,
  segmentKycStep1CompleteB,
  segmentKycStep2CompleteB,
  segmentKycFormL1,
  segmentKycFormL2BankSubmit,
  segmentKycFormL2UploadDocBank,
  segmentKycFormL2UploadDocOwner,
  segmentKycFormL2UploadDocAddress,
  segmentKycFormL2UploadDocCompanyTax,
  segmentKycFormL2UploadDocCompanyIncorporation,
  segmentKycFormL2DocsSubmit,
  segmentHomeQuickLinkEditCountries,
  segmentHomeKycStep2Complete,
  segmentProductMenuCreatePaymentLink,
  segmentProductMenuUseSubscriptions,
  segmentProductMenuUseCommissions,
  segmentProductMenuSeeIntegrations,
  segmentProductMenuSeeFirstStepsDoc,
  segmentCompanyInfoUploadLogo,
  segmentPaymentLinkListBtnCreatePaymentLink,
  segmentPaymentLinkCreatePaymentLink,
  segmentPaymentLinkLinkBoxCopyLink,
  segmentPaymentLinkShareFacebook,
  segmentPaymentLinkLinkBoxShareWhatsApp,
  segmentPaymentLinkLinkBoxShareMobile,
  segmentVirtualPOSCreatePayment,
  segmentIntegrationsMenuKeys,
  segmentIntegrationsMenuPayButton,
  segmentIntegrationsMenuShopify,
  segmentIntegrationsMenuMagento,
  segmentIntegrationsMenuApiIntegration,
  segmentIntegrationsMenuWoocommerce,
  segmentIntegrationsKeysCopyApiKey,
  segmentIntegrationsKeysCopySecretKey,
  segmentIntegrationsWooCommerceKeysCopyApiKey,
  segmentIntegrationsWooCommerceKeysCopySecretKey,
  segmentIntegrationsMagentoKeysCopyApiKey,
  segmentIntegrationsMagentoKeysCopySecretKey,
  segmentIntegrationsPayButtonCeatePayButton,
  segmentIntegrationsPayButtonCopyCode,
  segmentIntegrationsShopifyInstall,
  segmentIntegrationsShopifyPageLinkDocs,
  segmentIntegrationsMagentoInstall,
  segmentIntegrationsMagentoPageLinkDocs,
  segmentIntegrationsApiIntegrationPageLinkDocs,
  segmentIntegrationsWooCommerceInstall,
  segmentIntegrationsWooCommercePageLinkDocs,
  segmentIntegrationsMenuPrestaShop,
  segmentIntegrationsPrestaShopKeysCopyApiKey,
  segmentIntegrationsPrestaShopKeysCopySecretKey,
  segmentIntegrationsPrestaShopInstall,
  segmentIntegrationsPrestaShopDownloadPlugin,
  segmentIntegrationsMenuHostinger,
  segmentIntegrationsHostingerKeysCopyApiKey,
  segmentIntegrationsHostingerKeysCopySecretKey,
  segmentIntegrationsHostingerPageLinkDocs,
  segmentUserMenuProfileEdit,
  segmentUserMenuProfileChangePassword,
  segmentUserMenuProfileEditSave,
  segmentUserMenuProfilePasswordSave,
  segmentPaymentLinkLinkListCopyLink,
  segmentTransactionsDownloadReport,
  segmentTransactionsTransactionDetail,
  segmentTransactionsFilterSearchBox,
  segmentTransactionsFilterDate,
  segmentTransactionsFilterCountry,
  segmentTransactionsFilterPaymentMethod,
  segmentTransactionsFilterPaymentStatus,
  segmentTransactionsTransactionDetailCopyInvoiceId,
  segmentTransactionsTransactionDetailCopyExternalId,
  segmentRefundsFilter,
  segmentBankAccountsAddBankAccount,
  segmentBankAccountsBaDetail,
  segmentPaymentMethodEditCoverage,
  segmentPaymentMethodActivationCountryPayment,
  segmentPaymentMethodEditCoverageRegionCountries,
};
