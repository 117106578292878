import { useDispatch, useSelector } from 'react-redux';
import {
  changeShowCreateCheckoutTheme,
  changeUpdateCheckoutTheme,
  getCheckoutThemes,
  postCreateCheckoutTheme,
} from './actions';
import { CheckoutThemeSelectors } from './selectors';
import { CreateCheckoutTheme, CheckoutTheme } from './interfaces';

export const useCheckoutTheme = () => {
  const dispatch = useDispatch();

  return {
    loading: useSelector(CheckoutThemeSelectors.getLoading),
    formLoading: useSelector(CheckoutThemeSelectors.getFormLoading),
    error: useSelector(CheckoutThemeSelectors.getError),
    errorInfo: useSelector(CheckoutThemeSelectors.getErrorInfo),
    allCheckoutThemes: useSelector(CheckoutThemeSelectors.getAllCheckoutThemes),
    showRequestSettlement: useSelector(CheckoutThemeSelectors.getShowCreateCheckoutTheme),
    requestSuccess: useSelector(CheckoutThemeSelectors.getRequestSuccess),
    updateCheckoutTheme: useSelector(CheckoutThemeSelectors.getUpdateCheckoutTheme),
    getCheckoutThemes: (page: number) => dispatch(getCheckoutThemes(page)),
    changeUpdateCheckoutTheme: (theme: CheckoutTheme) => dispatch(changeUpdateCheckoutTheme(theme)),
    changeShowCreateCheckoutTheme: (show: boolean) => dispatch(changeShowCreateCheckoutTheme(show)),
    postCreateCheckoutTheme: (payload: CreateCheckoutTheme) => dispatch(postCreateCheckoutTheme(payload)),
  };
};
