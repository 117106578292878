import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Parser from 'html-react-parser';
import l from '../../../lang';
import Loading from '../../common/loading';
import TopDashboardSection from '../../common/top-dashboard-section';
import Message from '../../common/message';
import BasicInfo from './basic-info';
import { showUploadLogo, changeShowUpLevel, getKycLevels } from '../../../store/company-information/actions';
import OnboardingDialog from '../../common/onboarding-dialog';
import ButtonLink from '../../common/button-link';
import { Urls } from '../../../config/urls';
import LinkDocs from '../../common/link-docs';
import Card from '../../common/card';
import CardTitle from '../../common/card-title';
import CardDescription from '../../common/card-description';
import KYCSteps from './kyc-steps';
import KYCLevel1 from './kyc-levels/level-1';
import KYCLevel2 from './kyc-levels/level-2';
import { useMediaQuery } from '@mui/material';
import Dialog from '../../common/dialog';
import TermsAndConditions from '../../common/terms-and-conditions';
import { KycReviewStatus } from './kyc-steps/enums/KycReviewStatus';
import { segmentIdentify, segmentKycStep1TermsConditions } from '../../../utils/analytics';
import DialogLeaveOnboarding from './dialog-leave-onboarding';
import { getLanguage } from '../../../utils';
import AlertMessages from '../alert-messages';
import { FeatureToggle, On, Off, useFeature } from '../../../hooks/use-features';
import labels from './kyc-levels/config/labels';
import './styles.scss';
import { MerchantFeatures } from '../../../constants';
import { useDashboard } from '../../../store/dashboard';

const CompanyInformation = ({
  loading,
  merchantId,
  kycLevel,
  allKycLevels,
  validKYC,
  kycReview,
  completedTransactions,
  name,
  lastName,
  documentType,
  document,
  phone,
  website,
  country,
  logoUrl,
  kycReviewStatus,
  showUploadLogo,
  showUpLevel,
  loadingUploadLogo,
  fnShowUploadLogo,
  fnShowUpLevel,
  fnGetKycLevels,
  company,
}) => {
  const isTabletMobile = useMediaQuery('(max-width:768px)');
  const { level, requiredTransactions } = kycLevel;

  const [kycStep, setKycStep] = useState(0);
  const [onboardingMenu, setOnboardingMenu] = useState([]);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [displayConfirmClose, setDisplayConfirmClose] = useState(false);
  const { hasFeature } = useFeature();
  const { userMe } = useDashboard();

  useEffect(() => {
    const kycSearch = new URLSearchParams(window.location.search).get('kyc');
    const levelNumber = Number(kycSearch);

    const isFirstStep = level === 1 && !validKYC && !kycReview;
    const isSecondStep = (level === 1 && validKYC && !kycReview) || (level > 1 && level < 4 && !kycReview);

    if (levelNumber > 0) {
      if ((levelNumber === 1 && isFirstStep) || (levelNumber === 2 && isSecondStep)) {
        setKycStep(levelNumber);
        fnShowUpLevel(true);
      }
    }
  }, []);

  let StepComponent;
  switch (kycStep) {
    case 1:
      StepComponent = <KYCLevel1 setOnboardingMenu={menu => setOnboardingMenu(menu)} />;
      break;
    case 2:
      StepComponent = <KYCLevel2 setOnboardingMenu={menu => setOnboardingMenu(menu)} />;
      break;
    default:
      fnShowUpLevel(false);
  }

  useEffect(() => {
    fnGetKycLevels();
    segmentIdentify(merchantId, country, { merchantUserId: userMe?.id });
  }, []);

  const openTermsAndConditionsHandler = () => {
    segmentKycStep1TermsConditions();
    setShowTermsAndConditions(true);
  };

  return (
    <div>
      <TopDashboardSection
        title={level === 1 ? l('companyInformation.headline.step1') : l('companyInformation.headline.step2')}
      />
      <AlertMessages />
      <div className="company-information">
        {level !== 4 && (
          <div className="company-information__section">
            <Card>
              {loading && <Loading fullScreen />}
              {kycReview && !kycReviewStatus && (
                <>
                  <CardTitle>{l('companyInformation.kycReview.headline')}</CardTitle>
                  <CardDescription>{l('companyInformation.kycReview.subheadline')}</CardDescription>
                </>
              )}
              {kycReview && kycReviewStatus === KycReviewStatus.REJECTED_BY_WEBSITE && (
                <>
                  <CardTitle>{l('companyInformation.kycReview.headline.website')}</CardTitle>
                  <CardDescription>{l('companyInformation.kycReview.subheadline.website')}</CardDescription>
                </>
              )}
              {kycReview && kycReviewStatus === KycReviewStatus.REJECTED_BY_INDUSTRY && (
                <>
                  <CardTitle>{l('companyInformation.kycReview.headline.industry')}</CardTitle>
                  <CardDescription>{l('companyInformation.kycReview.subheadline.industry')}</CardDescription>
                </>
              )}
              {validKYC && !kycReview && requiredTransactions > completedTransactions && (
                <div className="company-information__required-transactions">
                  <Message body={Parser(l('companyInformation.requiredTransactions', requiredTransactions))} />
                </div>
              )}
              <div className="company-information__link_docs">
                <LinkDocs textKey="linkDocs.levels" url={Urls.docs.levels[getLanguage()]} />
              </div>

              {level === 1 && !validKYC && !kycReview && (
                <FeatureToggle feature={MerchantFeatures.ONBOARD_BY_COMPANY_TYPE}>
                  <On>
                    <CardTitle>{l(`companyInformation.headlineCard.step1.${company ? 'company' : 'physical'}`)}</CardTitle>
                    <CardDescription>
                      {l(`companyInformation.subheadlineCard.step1.${company ? 'company' : 'physical'}`)}
                      <ButtonLink
                        text={l('companyInformation.termsAndConditions')}
                        onClick={openTermsAndConditionsHandler}
                      />
                      <span>.</span>
                    </CardDescription>
                  </On>
                  <Off>
                    <CardTitle>{l('companyInformation.headlineCard.step1')}</CardTitle>
                    <CardDescription>
                      {l('companyInformation.subheadlineCard.step1')}
                      <ButtonLink
                        text={l('companyInformation.termsAndConditions')}
                        onClick={openTermsAndConditionsHandler}
                      />
                      <span>.</span>
                    </CardDescription>
                  </Off>
                </FeatureToggle>
              )}
              {((level === 1 && validKYC && !kycReview) || (level > 1 && level < 4 && !kycReview)) && (
                <>
                  <CardTitle>{l('companyInformation.headlineCard.step2')}</CardTitle>
                  <CardDescription>
                    {l('companyInformation.subheadlineCard.step2.part1')}
                    <ButtonLink
                      text={l('companyInformation.termsAndConditions')}
                      onClick={() => setShowTermsAndConditions(true)}
                    />
                    <span>. </span>
                    {l('companyInformation.subheadlineCard.step2.part2')}
                  </CardDescription>
                </>
              )}
              {level < 4 && (
                <KYCSteps
                  limitStepOne={(allKycLevels?.levels && allKycLevels.levels[0]?.maxAmount) || '####'}
                  kycReviewStatus={kycReviewStatus}
                  onClick={step => {
                    setKycStep(step);
                    fnShowUpLevel(true);
                  }}
                />
              )}
            </Card>
          </div>
        )}
        {name && (
          <div className="company-information__section">
            <BasicInfo
              loadingUploadLogo={loadingUploadLogo}
              logoUrl={logoUrl}
              name={name}
              lastName={lastName}
              documentType={documentType}
              document={document}
              phone={phone}
              website={website}
              country={country}
              showUploadLogo={showUploadLogo}
              fnShowUploadLogo={fnShowUploadLogo}
            />
          </div>
        )}
        {showUpLevel && kycStep && (
          <OnboardingDialog
            dividers={false}
            unpaddingContent
            unpaddingFooter
            fullScreenMobile
            type="md"
            handleClose={() => {
              if (!loading) {
                if (level === 1 && !validKYC) {
                  setDisplayConfirmClose(true);
                } else {
                  setKycStep(0);
                  fnShowUpLevel(false);
                }
              }
            }}
            title={
              isTabletMobile ? (
                <div className="">
                  <div>
                    <b>{labels.header(hasFeature, { isCompany: company })}</b>
                  </div>
                  <div>{labels.subheader(hasFeature, { isCompany: company })}</div>
                </div>
              ) : (
                <>
                  <b>{labels.header(hasFeature, { isCompany: company })}</b> |{' '}
                  {labels.subheader(hasFeature, { isCompany: company })}
                </>
              )
            }
            body={StepComponent}
            menuItems={onboardingMenu}
          />
        )}
        <Dialog
          open={showTermsAndConditions}
          type="lg"
          dividers={false}
          fullScreenMobile
          titleCenter
          handleClose={() => setShowTermsAndConditions(false)}
          body={<TermsAndConditions />}
        />
        {displayConfirmClose && (
          <DialogLeaveOnboarding
            onClose={confirm => {
              setDisplayConfirmClose(false);
              if (confirm) {
                setKycStep(0);
                fnShowUpLevel(false);
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

CompanyInformation.propTypes = {
  loading: PropTypes.bool,
  kycLevel: PropTypes.object,
  validKYC: PropTypes.bool,
  kycReview: PropTypes.bool,
  completedTransactions: PropTypes.number,
  name: PropTypes.string,
  lastName: PropTypes.string,
  documentType: PropTypes.string,
  document: PropTypes.string,
  phone: PropTypes.string,
  website: PropTypes.string,
  logoUrl: PropTypes.string,
  kycReviewStatus: PropTypes.oneOf(['REJECTED_BY_WEBSITE', 'REJECTED_BY_INDUSTRY', 'REJECTED_BY_DOCUMENTATION', null]),
  showUploadLogo: PropTypes.bool,
  showUpLevel: PropTypes.bool,
  loadingUploadLogo: PropTypes.bool,
  allKycLevels: PropTypes.object,
  fnShowUploadLogo: PropTypes.func,
  fnShowUpLevel: PropTypes.func,
  fnGetKycLevels: PropTypes.func,
};

const mapStateToProps = state => ({
  loading: state.companyInformation.loading,
  showUploadLogo: state.companyInformation.uploadLogo.showModal,
  showUpLevel: state.companyInformation.showUpLevel,
  loadingUploadLogo: state.companyInformation.uploadLogo.loading,
  merchantId: state.dashboard.merchantMe?.id,
  kycLevel: state.dashboard.merchantMe?.kycLevel,
  validKYC: state.dashboard.merchantMe?.validKYC,
  kycReview: state.dashboard.merchantMe?.kycReview,
  completedTransactions: state.dashboard.merchantMe?.completedTransactions,
  name: state.dashboard.merchantMe?.name,
  lastName: state.dashboard.merchantMe?.lastName,
  documentType: state.dashboard.merchantMe?.documentType,
  document: state.dashboard.merchantMe?.document,
  phone: state.dashboard.merchantMe?.phone,
  website: state.dashboard.merchantMe?.website,
  country: state.dashboard.merchantMe?.country,
  logoUrl: state.dashboard.merchantMe?.logoUrl,
  kycReviewStatus: state.dashboard.merchantMe?.kycReviewStatus,
  allKycLevels: state.companyInformation.allKycLevels,
  company: state.dashboard.merchantMe.company,
});

const mapDispatchToProps = dispatch => ({
  fnShowUploadLogo: show => dispatch(showUploadLogo(show)),
  fnShowUpLevel: show => dispatch(changeShowUpLevel(show)),
  fnGetKycLevels: () => dispatch(getKycLevels()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyInformation);
