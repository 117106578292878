import { call, put, select, takeLatest } from 'redux-saga/effects';
import { Urls } from '../../config/urls';
import { PaymentMethodTypes, PaymentSolutionType } from '../../constants';
import { getTask } from '../../services';
import { checkToken } from '../auth/sagas';
import { AuthSelectors } from '../auth/selectors';
import {
  ReportsActionType,
  reportsGetTransactionsSummaryError,
  reportsGetTransactionsSummarySuccess,
  reportsGetPaymentVolumeSuccess,
  reportsGetPaymentVolumeError,
  reportsGetTransactionsByCountryError,
  reportsGetTransactionsByCountrySuccess,
  reportsGetTransactionsByPaymentSolutionSuccess,
  reportsGetTransactionsByPaymentSolutionError,
  reportsGetTransactionsByPaymentMethodSuccess,
  reportsGetTransactionsByPaymentMethodError,
} from './actions';

function* reportsTransactionsSummary(action?: { days: number; period?: string }) {
  yield* checkToken();
  const token: string = yield select(AuthSelectors.getToken);

  const url = new URL(Urls.api.reportsTransactionsSummary.replace(':days', `${action?.days}`));
  if (action?.period) {
    url.searchParams.append('period', action?.period);
  }

  const { response, error } = yield call(getTask, {
    url: url.toString(),
    token,
  });

  if (response) {
    yield put(reportsGetTransactionsSummarySuccess(response));
  } else {
    yield put(reportsGetTransactionsSummaryError(error));
  }
}

export function* reportsTransactionsSummarySaga() {
  yield takeLatest(ReportsActionType.REPORTS_TRANSACTIONS_SUMMARY, reportsTransactionsSummary);
}

function* reportsGetPaymentVolume(action?: { days: number; period?: string }) {
  yield* checkToken();
  const token: string = yield select(AuthSelectors.getToken);

  const url = new URL(Urls.api.reportsTransactionsPaymentVolume.replace(':days', `${action?.days}`));
  if (action?.period) {
    url.searchParams.append('period', action?.period);
  }

  const { response, error } = yield call(getTask, {
    url: url.toString(),
    token,
  });

  if (response) {
    yield put(reportsGetPaymentVolumeSuccess(response));
  } else {
    yield put(reportsGetPaymentVolumeError(error));
  }
}

export function* reportsGetPaymentVolumeSaga() {
  yield takeLatest(ReportsActionType.REPORTS_TRANSACTIONS_PAYMENT_VOLUME, reportsGetPaymentVolume);
}

function* reportsGetTransactionsByCountry(action?: { days: number; period?: string }) {
  yield* checkToken();
  const token: string = yield select(AuthSelectors.getToken);

  const url = new URL(Urls.api.reportsTransactionsByCountry.replace(':days', `${action?.days}`));
  if (action?.period) {
    url.searchParams.append('period', action?.period);
  }

  const { response, error } = yield call(getTask, {
    url: url.toString(),
    token,
  });

  if (response) {
    yield put(reportsGetTransactionsByCountrySuccess(response));
  } else {
    yield put(reportsGetTransactionsByCountryError(error));
  }
}

export function* reportsGetTransactionsByCountrySaga() {
  yield takeLatest(ReportsActionType.REPORTS_TRANSACTIONS_BY_COUNTRY, reportsGetTransactionsByCountry);
}

function* reportsGetTransactionsByPaymentSolution(action?: { days: number; period?: string }) {
  yield* checkToken();
  const token: string = yield select(AuthSelectors.getToken);
  const url = new URL(Urls.api.reportsTransactionsByPaymentSolution.replace(':days', `${action?.days}`));
  if (action?.period) {
    url.searchParams.append('period', action?.period);
  }

  const { response, error } = yield call(getTask, {
    url: url.toString(),
    token,
  });

  if (response) {
    yield put(reportsGetTransactionsByPaymentSolutionSuccess(response));
  } else {
    yield put(reportsGetTransactionsByPaymentSolutionError(error));
  }
}

export function* reportsGetTransactionsByPaymentSolutionSaga() {
  yield takeLatest(ReportsActionType.REPORTS_TRANSACTIONS_BY_PAYMENT_SOLUTION, reportsGetTransactionsByPaymentSolution);
}

function* reportsGetTransactionsByPaymentMethod(action?: { days: number; period: string }) {
  yield* checkToken();
  const token: string = yield select(AuthSelectors.getToken);

  const url = new URL(Urls.api.reportsTransactionsByPaymentMethod.replace(':days', `${action?.days}`));
  if (action?.period) {
    url.searchParams.append('period', action?.period);
  }

  const { response, error } = yield call(getTask, {
    url: url.toString(),
    token,
  });

  if (response) {
    yield put(reportsGetTransactionsByPaymentMethodSuccess(response));
  } else {
    yield put(reportsGetTransactionsByPaymentMethodError(error));
  }
}

export function* reportsGetTransactionsByPaymentMethodSaga() {
  yield takeLatest(ReportsActionType.REPORTS_TRANSACTIONS_BY_PAYMENT_METHOD, reportsGetTransactionsByPaymentMethod);
}
