import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Login from '../../pages/login';
import SignUp from '../../pages/sign-up';
import Dashboard from '../../pages/dashboard';
import NotFound from '../../pages/not-found';
import { Routes } from '../../config/routes';
import { StatusSession, MenuCodes } from '../../constants';
import { redirecTo, setCookieLanguage } from '../../utils/index';
import ConfirmEmail from '../../pages/confirm-email';
import PasswordReset from '../../pages/pasword-reset';
import TestMessage from '../../components/common/test-message';
import useMerchantReferenceParams from '../../hooks/use-merchant-reference-param';
import './styles.scss';
import SignUpPlugin from '../../pages/sign-up-plugin';
import KycMessage from '../../components/common/kyc-message';

const App = ({ isAuthenticated, sessionStatus, canOperate, level, emailConfirmed, afterLoginSuccess }) => {
  const handleLangParam = () => {
    const params = new URLSearchParams(window.location.search);
    const lang = params.get('lang');
    if (lang) {
      setCookieLanguage(lang);
    }
  };

  const handleUrlParam = url => {
    const searchParams = window.location.search;
    if (sessionStatus !== StatusSession.LOGGED_OUT) {
      const destination = encodeURIComponent(window.location.pathname + searchParams);
      return `${url}?destination_url=${destination}`;
    }
    return url;
  };

  const checkInvalidSession = () => {
    return sessionStatus === StatusSession.LOGGED_OUT || sessionStatus === StatusSession.INVALID;
  };

  const LoginRender = () => {
    const merchantReference = useMerchantReferenceParams();

    if ((merchantReference && afterLoginSuccess) || !merchantReference) {
      if (isAuthenticated || sessionStatus === StatusSession.LOGGED_IN) {
        const params = new URLSearchParams(window.location.search);
        const destination = params.get('destination_url');
        if (destination) {
          return <Redirect to={decodeURIComponent(destination)} />;
        }
        return <Redirect to={Routes.HOME} />;
      }
    }
    handleLangParam();
    return <Login />;
  };

  return (
    <div className="app">
      <Router>
        <div className="top-messages">
          <TestMessage />
        </div>
        <Switch>
          {/* PUBLIC ROUTES */}
          <Route exact path={Routes.LOGIN} render={() => <LoginRender />} />
          <Route
            exact
            path={Routes.SIGN_UP}
            render={() => {
              handleLangParam();
              return <SignUp />;
            }}
          />
          <Route
            exact
            path={Routes.SIGN_UP_PLUGIN}
            render={() => {
              handleLangParam();
              return <SignUpPlugin />;
            }}
          />
          <Route
            exact
            path={Routes.PASSWORD_RESET}
            render={() => {
              handleLangParam();
              return <PasswordReset />;
            }}
          />
          <Route
            exact
            path={Routes.USER_PASSWORD_RESET}
            render={() => {
              handleLangParam();
              return <PasswordReset requestNewPassword />;
            }}
          />

          {/* PRIVATE ROUTES */}
          <Route
            exact
            path={Routes.HOME}
            render={() => {
              if (checkInvalidSession()) {
                redirecTo(handleUrlParam(Routes.LOGIN));
                return <></>;
              }
              if (!canOperate) {
                return <Dashboard menuSelected={MenuCodes.ACTIVATE_ACCOUNT} />;
              }
              return <Dashboard menuSelected={MenuCodes.HOME} />;
            }}
          />
          <Route
            exact
            path={Routes.REPORTS}
            render={() => {
              if (checkInvalidSession()) {
                redirecTo(handleUrlParam(Routes.LOGIN));
                return <></>;
              }
              return <Dashboard menuSelected={MenuCodes.REPORTS} />;
            }}
          />
          <Route
            exact
            path={Routes.ACTIVATE_ACCOUNT}
            render={() => {
              if (checkInvalidSession()) {
                redirecTo(handleUrlParam(Routes.LOGIN));
                return <></>;
              }
              if (!canOperate && level < 4) {
                return <Dashboard menuSelected={MenuCodes.ACTIVATE_ACCOUNT} />;
              }
              return <Dashboard menuSelected={MenuCodes.HOME} />;
            }}
          />
          <Route
            exact
            path={Routes.EMAIL_VALIDATION}
            render={() => {
              if (checkInvalidSession()) {
                redirecTo(handleUrlParam(Routes.LOGIN));
                return <></>;
              }
              if (!emailConfirmed) {
                return <Dashboard menuSelected={MenuCodes.EMAIL_VALIDATION} />;
              }
              redirecTo(Routes.HOME);
              return <></>;
            }}
          />
          <Route exact path={Routes.CONFIRM_EMAIL} render={() => <ConfirmEmail />} />
          <Route
            exact
            path={Routes.PAYMENT_METHODS}
            render={() => {
              if (checkInvalidSession()) {
                redirecTo(handleUrlParam(Routes.LOGIN));
                return <></>;
              }
              return <Dashboard menuSelected={MenuCodes.PAYMENT_METHODS} />;
            }}
          />
          <Route
            exact
            path={Routes.TRANSACTIONS}
            render={() => {
              if (checkInvalidSession()) {
                redirecTo(handleUrlParam(Routes.LOGIN));
                return <></>;
              }
              return <Dashboard menuSelected={MenuCodes.TRANSACTIONS} />;
            }}
          />
          <Route
            exact
            path={Routes.TRANSACTION_DETAIL}
            render={() => {
              if (checkInvalidSession()) {
                redirecTo(handleUrlParam(Routes.LOGIN));
                return <></>;
              }
              return <Dashboard menuSelected={MenuCodes.TRANSACTION_DETAIL} />;
            }}
          />
          <Route
            exact
            path={Routes.SETTLEMENTS}
            render={() => {
              if (checkInvalidSession()) {
                redirecTo(handleUrlParam(Routes.LOGIN));
                return <></>;
              }
              return <Dashboard menuSelected={MenuCodes.SETTLEMENTS} />;
            }}
          />
          <Route
            exact
            path={Routes.CHECKOUT_THEMES}
            render={() => {
              if (checkInvalidSession()) {
                redirecTo(handleUrlParam(Routes.LOGIN));
                return <></>;
              }
              return <Dashboard menuSelected={MenuCodes.CHECKOUT_THEMES} />;
            }}
          />
          <Route exact path={Routes.PAYMENT_LINK} render={() => <Redirect to={Routes.CREATED_PAYMENT_LINKS} />} />
          <Route
            exact
            path={Routes.CREATE_ONE_TIME_PAYMENT_LINK}
            render={() => {
              if (checkInvalidSession()) {
                redirecTo(handleUrlParam(Routes.LOGIN));
                return <></>;
              }
              return <Dashboard menuSelected={MenuCodes.CREATE_ONE_TIME_PAYMENT_LINK} />;
            }}
          />
          <Route
            exact
            path={Routes.CREATE_RECURRING_PAYMENT_LINK}
            render={() => {
              if (checkInvalidSession()) {
                redirecTo(handleUrlParam(Routes.LOGIN));
                return <></>;
              }
              return <Dashboard menuSelected={MenuCodes.CREATE_RECURRING_PAYMENT_LINK} />;
            }}
          />
          <Route
            exact
            path={Routes.CREATED_PAYMENT_LINKS}
            render={() => {
              if (checkInvalidSession()) {
                redirecTo(handleUrlParam(Routes.LOGIN));
                return <></>;
              }
              return <Dashboard menuSelected={MenuCodes.CREATED_PAYMENT_LINKS} />;
            }}
          />
          {/* <Route
            exact
            path={Routes.VIRTUAL_POS}
            render={() => {
              if (checkInvalidSession()) {
                redirecTo(handleUrlParam(Routes.LOGIN));
                return <></>;
              }
              return <Dashboard menuSelected={MenuCodes.VIRTUAL_POS} />;
            }}
          /> */}
          <Route
            exact
            path={Routes.PAYMENT_BUTTON}
            render={() => {
              if (checkInvalidSession()) {
                redirecTo(handleUrlParam(Routes.LOGIN));
                return <></>;
              }
              return <Dashboard menuSelected={MenuCodes.PAYMENT_BUTTON} />;
            }}
          />
          <Route
            exact
            path={Routes.PAYMENT_INTEGRATION}
            render={() => {
              if (checkInvalidSession()) {
                redirecTo(handleUrlParam(Routes.LOGIN));
                return <></>;
              }
              return <Dashboard menuSelected={MenuCodes.PAYMENT_INTEGRATION} />;
            }}
          />
          <Route
            exact
            path={Routes.COMPANY_INFORMATION}
            render={() => {
              if (checkInvalidSession()) {
                redirecTo(handleUrlParam(Routes.LOGIN));
                return <></>;
              }
              return <Dashboard menuSelected={MenuCodes.COMPANY_INFORMATION} />;
            }}
          />
          <Route
            exact
            path={Routes.REFUNDS}
            render={() => {
              if (checkInvalidSession()) {
                redirecTo(handleUrlParam(Routes.LOGIN));
                return <></>;
              }
              return <Dashboard menuSelected={MenuCodes.REFUNDS} />;
            }}
          />
          <Route
            exact
            path={Routes.CLIENTS}
            render={() => {
              if (checkInvalidSession()) {
                redirecTo(handleUrlParam(Routes.LOGIN));
                return <></>;
              }
              return <Dashboard menuSelected={MenuCodes.CLIENTS} />;
            }}
          />
          <Route
            exact
            path={Routes.BANK_ACCOUNTS}
            render={() => {
              if (checkInvalidSession()) {
                redirecTo(handleUrlParam(Routes.LOGIN));
                return <></>;
              }
              return <Dashboard menuSelected={MenuCodes.BANK_ACCOUNTS} />;
            }}
          />
          <Route
            exact
            path={Routes.CHARGEBACKS}
            render={() => {
              if (checkInvalidSession()) {
                redirecTo(handleUrlParam(Routes.LOGIN));
                return <></>;
              }
              return <Dashboard menuSelected={MenuCodes.CHARGEBACKS} />;
            }}
          />
          <Route
            exact
            path={Routes.SUBSCRIPTIONS}
            render={() => {
              if (checkInvalidSession()) {
                redirecTo(handleUrlParam(Routes.LOGIN));
                return <></>;
              }
              return <Dashboard menuSelected={MenuCodes.SUBSCRIPTIONS} />;
            }}
          />
          <Route
            exact
            path={Routes.SUBSCRIPTIONS_PLANS}
            render={() => {
              if (checkInvalidSession()) {
                redirecTo(handleUrlParam(Routes.LOGIN));
                return <></>;
              }
              return <Dashboard menuSelected={MenuCodes.SUBSCRIPTIONS_PLANS} />;
            }}
          />
          <Route
            exact
            path={Routes.SUBSCRIPTIONS_DETAIL}
            render={() => {
              if (checkInvalidSession()) {
                redirecTo(handleUrlParam(Routes.LOGIN));
                return <></>;
              }
              return <Dashboard menuSelected={MenuCodes.SUBSCRIPTIONS_DETAIL} />;
            }}
          />
          <Route
            exact
            path={Routes.SUBSCRIPTIONS_PLANS_DETAILS}
            render={() => {
              if (checkInvalidSession()) {
                redirecTo(handleUrlParam(Routes.LOGIN));
                return <></>;
              }
              return <Dashboard menuSelected={MenuCodes.SUBSCRIPTIONS_PLANS_DETAILS} />;
            }}
          />
          <Route
            exact
            path={Routes.SUBSCRIPTIONS_PLANS_CREATE}
            render={() => {
              if (checkInvalidSession()) {
                redirecTo(handleUrlParam(Routes.LOGIN));
                return <></>;
              }
              return <Dashboard menuSelected={MenuCodes.SUBSCRIPTIONS_PLANS_CREATE} />;
            }}
          />
          <Route
            exact
            path={Routes.SUBSCRIPTIONS_PLANS_EDIT}
            render={() => {
              if (checkInvalidSession()) {
                redirecTo(handleUrlParam(Routes.LOGIN));
                return <></>;
              }
              return <Dashboard menuSelected={MenuCodes.SUBSCRIPTIONS_PLANS_EDIT} />;
            }}
          />
          {/* NOT FOUND */}
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

App.propTypes = {
  isAuthenticated: PropTypes.bool,
  sessionStatus: PropTypes.string,
  canOperate: PropTypes.bool,
  level: PropTypes.number,
  emailConfirmed: PropTypes.bool,
  afterLoginSuccess: PropTypes.bool,
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  afterLoginSuccess: state.auth.afterLoginSuccess,
  sessionStatus: state.auth.sessionStatus,
  canOperate: state.dashboard.merchantMe?.canOperate,
  level: state.dashboard.merchantMe?.kycLevel?.level,
  emailConfirmed: !state.dashboard.userMe?.emailConfirmed,
});

export default connect(mapStateToProps)(App);
