import l from '../../../../lang';
import IntegrationCard from '../integration-card';
import { ReactComponent as VirtueMartIcon } from '../../../../assets/icons/integrations/virtuemart.svg';
import { usePaymentIntegration } from '../../../../store/payment-integration';
import { DetailsOpened } from '../../../../store/payment-integration/interfaces';

export default () => {
  const { setDetailsOpened } = usePaymentIntegration();

  return (
    <IntegrationCard
      title={l('paymentIntegration.virtuemart.title')}
      icon={<VirtueMartIcon />}
      color="blue"
      onDetails={() => {
        setDetailsOpened(DetailsOpened.VIRTUEMART);
      }}
      detailsText={l('paymentIntegration.card.integration.voting.link')}
    />
  );
};
