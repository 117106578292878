import { FunctionComponent } from 'react';
import Parser from 'html-react-parser';
import l from '../../../../lang';
import { DialogConfirm, CardConfirm } from '../../../common/confirm';

interface UploadChargebackDocumentErrorProps {
  onClose: (confirm: boolean) => void;
}

const UploadChargebackDocumentError: FunctionComponent<UploadChargebackDocumentErrorProps> = ({ onClose }) => (
  <DialogConfirm onClose={onClose}>
    <CardConfirm
      onClose={onClose}
      headline={Parser(l('chargebacks.upload-doc.error.headline'))}
      description={Parser(l('chargebacks.upload-doc.error.subheadline'))}
      confirmChildren={l('chargebacks.upload-doc.error.button')}
      level={'error'}
    />
  </DialogConfirm>
);

export default UploadChargebackDocumentError;
