import './styles.scss';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { ClickAwayListener } from '@mui/base';
import { ReactComponent as DollarIcon } from '../../../../assets/icons/dollar.svg';
import { useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import l from '../../../../lang';
import { Urls } from '../../../../config/urls';
import { getLanguage, openNewTabAndFocus } from '../../../../utils';

interface TransactionTooltipButtonProps {
  type: string;
  transactionId: string;
  email: string;
  document: string;
  documentType: string;
}

type TransactionTooltipProps = TransactionTooltipButtonProps & TooltipProps;

const getHelpUrl = () => {
  const lang = getLanguage();
  switch (lang) {
    case 'es':
      return Urls.checkoutLogsHelp.es;
    case 'en':
      return Urls.checkoutLogsHelp.en;
    case 'pt':
      return Urls.checkoutLogsHelp.pt;
    default:
      return Urls.checkoutLogsHelp.en;
  }
};

const openHelp = (e: MouseEvent<HTMLAnchorElement, MouseEvent>) => {
  e.stopPropagation();

  const urlFaq = getHelpUrl();

  openNewTabAndFocus(urlFaq);
};

const TransactionTooltip = styled(
  ({ type, transactionId = '-', document, documentType, email, className, ...props }: TransactionTooltipProps) => (
    <Tooltip
      /* @ts-ignore-next-line */
      title={
        <div className="transactionTooltip">
          <div className="transactionTooltip__header">
            <DollarIcon />
            <h3 style={{ fontSize: 14 }}>{l('checkout-logs.tooltip.title')}</h3>
          </div>
          <div>
            <p className="transactionTooltip__id">
              {l('checkout-logs.tooltip.transaction_id')}: {transactionId}
            </p>
            <p className="transactionTooltip__id">
              {l('checkout-logs.tooltip.document')}: {documentType ? `(${documentType})` : ''} {document}
            </p>
            <p className="transactionTooltip__id">
              {l('checkout-logs.tooltip.email')}: {email}
            </p>
            <div className="separator"></div>
            <div className="transactionTooltip__status">
              <span>{l('checkout-logs.tooltip.status')}:</span>
              <span className="transactionTooltip__status__name">{l('checkout-logs.status.review')}</span>
            </div>
            <div className="separator"></div>
            <div className="transactionTooltip__details">
              <span className="transactionTooltip__details__title">{l('checkout-logs.details.review.title')}:</span>
              <span className="transactionTooltip__details__desc">{l(`checkout-logs.type.${type}`)}</span>
            </div>

            <a onClick={e => openHelp(e)} style={{ cursor: 'pointer' }}>
              {l('checkout-logs.tooltip.help')}
            </a>
          </div>
        </div>
      }
      {...props}
      classes={{ popper: className }}
    />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ffffff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 230,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    borderBottom: '8px solid #eb7300',
    borderRadius: '8px',
  },
}));

export const TransactionTooltipButton = ({
  transactionId,
  type,
  document,
  documentType,
  email,
}: TransactionTooltipButtonProps) => {
  const [open, setOpen] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const onClickAway = (e: MouseEvent | TouchEvent) => {
    if (tooltipRef.current?.contains(e.target as Node)) {
      return;
    }

    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div>
        {/* @ts-ignore-next-line  */}
        <TransactionTooltip
          ref={tooltipRef}
          type={type}
          transactionId={transactionId}
          open={open}
          document={document}
          documentType={documentType}
          email={email}
        >
          <IconButton onClick={() => setOpen(!open)}>
            <MoreVert fontSize="small" />
          </IconButton>
        </TransactionTooltip>
      </div>
    </ClickAwayListener>
  );
};
