export enum AuthActionType {
  INIT_LOGIN = 'AUTH/INIT_LOGIN',
  INIT_CHECK_COMPLETED = 'AUTH/INIT_CHECK_COMPLETED',
  SEND_LOGIN = 'AUTH/SEND_LOGIN',
  SENDING_LOGIN = 'AUTH/SENDING_LOGIN',
  LOGIN_SUCCESS = 'AUTH/LOGIN_SUCCESS',
  LOGIN_ERROR = 'AUTH/LOGIN_ERROR',
  REFRESH_TOKEN = 'AUTH/REFRESH_TOKEN',
  TOKEN_CHECKED = 'AUTH/TOKEN_CHECKED',
  REFRESH_TOKEN_ERROR = 'AUTH/REFRESH_TOKEN_ERROR',
  SEND_LOGOUT = 'AUTH/SEND_LOGOUT',
  LOGOUT_SUCCESS = 'AUTH/LOGOUT_SUCCESS',
  LOGOUT_ERROR = 'AUTH/LOGOUT_ERROR',
}

export const initLogin = () => ({
  type: AuthActionType.INIT_LOGIN,
});

export const initCheckCompleted = () => ({
  type: AuthActionType.INIT_CHECK_COMPLETED,
});

export const sendLogin = (
  email: string,
  password: string,
  merchantReference: string,
  merchantGaCookie: string,
  merchantGaSessionIdCookie: string
) => ({
  type: AuthActionType.SEND_LOGIN,
  email,
  password,
  merchantReference,
  merchantGaCookie,
  merchantGaSessionIdCookie,
});

export const sendingLogin = () => ({
  type: AuthActionType.SENDING_LOGIN,
});

export const loginSuccess = (response: any) => ({
  type: AuthActionType.LOGIN_SUCCESS,
  response,
});

export const loginError = (error: any) => ({
  type: AuthActionType.LOGIN_ERROR,
  error,
});

export const refreshToken = (response: any) => ({
  type: AuthActionType.REFRESH_TOKEN,
  response,
});

export const tokenChecked = () => ({
  type: AuthActionType.TOKEN_CHECKED,
});

export const refreshTokenError = (error: any) => ({
  type: AuthActionType.REFRESH_TOKEN_ERROR,
  error,
});

export const sendLogout = () => ({
  type: AuthActionType.SEND_LOGOUT,
});

export const logoutSuccess = (response: any) => ({
  type: AuthActionType.LOGOUT_SUCCESS,
  response,
});

export const logoutError = (error: any) => ({
  type: AuthActionType.LOGOUT_ERROR,
  error,
});
