import { useState, useRef, useEffect } from 'react';
import './styles.scss';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ReactComponent as FileCsvIcon } from '../../../../../assets/icons/file-csv.svg';
import { ReactComponent as FileXlsxIcon } from '../../../../../assets/icons/file-xlsx.svg';
import l from '../../../../../lang';
import Dialog from '../../../../common/dialog';
import DateRangePicker from '../../../../common/date-range-picker';
import Loading from '../../../../common/loading';
import MessageError from '../../../../common/message-error';
import Message from '../../../../common/message';
import { MessageTypes } from '../../../../../constants';
import icDownloadFile from '../../../../../assets/icons/download-file.svg?url';
import Select from '../../../../common/select';
import { chargebackStatus } from '../../../../../utils/chargebacks-utils';
import { allCountries } from '../../../../../config';
import { scrollIntoView } from '../../../../../utils';
import ButtonLink from '../../../../common/button-link';
import _ from 'lodash';
import { ExportType, Filters } from '../../../../../store/interfaces';
import { useChargebacks } from '../../../../../store/chargebacks';

const initialValues = () => {
  const now = new Date();
  const past = new Date(now.getTime() - 86400000 * 31); // 86400000 milisecons in 1 day.
  let month = `${now.getMonth() + 1}`;
  if (month.length === 1) month = `0${month}`;
  let day = `${now.getDate()}`;
  if (day.length === 1) day = `0${day}`;

  let monthPast = `${past.getMonth() + 1}`;
  if (monthPast.length === 1) monthPast = `0${monthPast}`;
  let dayPast = `${past.getDate()}`;
  if (dayPast.length === 1) dayPast = `0${dayPast}`;

  return {
    from: `${past.getFullYear()}-${monthPast}-${dayPast}`,
    to: `${now.getFullYear()}-${month}-${day}`,
    status: '',
    country: '',
  };
};

const DialogExportTransactions = () => {
  const {
    export: { loading, error, errorInfo, show, data },
    exportType,
    showExportChargebacks,
    exportChargebacks,
  } = useChargebacks();
  const messageRef = useRef(null);
  const [params, setParams] = useState('');
  const [filter, setFilter] = useState<Filters>(initialValues());
  const [dateRangeError, setDateRangeError] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const download = (d: any) => {
    if (exportType === ExportType.CSV) {
      const a = document.createElement('a');
      a.href = `data:text/csv;charset=utf-8,${escape(d)}`;
      a.download = `${l('menu.item.CHARGEBACKS')}-dLocalGo.csv`;
      a.click();
    }
    if (exportType === ExportType.XLSX) {
      const byteArray = new Uint8Array(d);
      const blob = new Blob([byteArray], { type: 'application/octetstream' });
      const url = window.URL || window.webkitURL;
      const link = url.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = link;
      a.download = `${l('menu.item.CHARGEBACKS')}-dLocalGo.xlsx`;
      a.click();
    }
  };

  useEffect(() => {
    handleChangeFilter(filter);
  }, []);

  useEffect(() => {
    if (error || data === '') {
      scrollIntoView(messageRef);
    }
    if (data && show) {
      download(data);
    }
  }, [error, data]);

  const handleChangeFilter = (filters: Filters) => {
    setFilter(filters);

    let filterParam = '';
    Object.keys(filters).forEach(key => {
      if (filters[key]) {
        filterParam += filterParam ? '&' : '';
        filterParam += `${_.snakeCase(key)}=${filters[key]}`;
      }
    });
    setParams(filterParam);
  };

  const handleDateRangeError = (from: string, to: string) => {
    setDateRangeError('');
    if (from && to) {
      const f = new Date(from);
      const t = new Date(to);

      if (f > t) {
        setDateRangeError(l('exportTransactions.invalidDates'));
      } else {
        const range = t.getTime() - f.getTime();
        if (Math.round(range / (1000 * 60 * 60 * 24)) > 31) {
          setDateRangeError(l('exportTransactions.rangeError'));
        }
      }
    }
  };

  const countries = () => {
    const countries: { [co: string]: string } = {};
    allCountries.forEach(co => {
      countries[co] = l(`countryCode.${co}`);
    });
    return countries;
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="export_transactions">
      <Dialog
        open={show}
        dividers={false}
        title={<span>{l('exportChargebacks.title')}</span>}
        unpaddingContent
        unpaddingFooter
        type="sm"
        handleClose={() => showExportChargebacks(false)}
        body={
          <div>
            {loading && (
              <div className="export_transactions__loading">
                <Loading />
              </div>
            )}
            <div className={`export_transactions__content ${loading ? 'export_transactions__content__hide' : ''}`}>
              {!data && (
                <div>
                  <div>{l('exportChargebacks.range')}</div>
                  <div>
                    <DateRangePicker
                      error={dateRangeError}
                      fromDate={filter.from}
                      toDate={filter.to}
                      changeFrom={from => {
                        handleDateRangeError(from, filter.to);
                        handleChangeFilter({ ...filter, from });
                      }}
                      changeTo={to => {
                        handleDateRangeError(filter.from, to);
                        handleChangeFilter({ ...filter, to });
                      }}
                    />
                  </div>
                  <div className="export_transactions__other_filters">
                    <div>{l('exportChargebacks.otherFilters')}</div>
                    <div>
                      <Select
                        name="filter-status"
                        label={l('chargebacks.filter.status')}
                        defaultOptionText={l('common.all.m')}
                        defaultOptionEnabled
                        value={filter.status}
                        size="lg"
                        onChange={e => {
                          handleChangeFilter({ ...filter, status: e.target.value });
                        }}
                        options={chargebackStatus()}
                      />
                      <Select
                        name="filter-status"
                        size="lg"
                        label={l('transactions.filter.country')}
                        defaultOptionText={l('common.all.m')}
                        defaultOptionEnabled
                        value={filter.country}
                        onChange={e => {
                          handleChangeFilter({ ...filter, country: e.target.value });
                        }}
                        options={countries()}
                      />
                    </div>
                  </div>
                  <div ref={messageRef}>
                    {data === '' && (
                      <div className="export_transactions__empty">
                        <Message type={MessageTypes.WARNING} body={l('exportChargebacks.empty')} />
                      </div>
                    )}
                    {error && (
                      <div className="export_transactions__error">
                        <MessageError errorInfo={errorInfo} />
                      </div>
                    )}
                  </div>
                </div>
              )}
              {data && (
                <div>
                  <div className="export_transactions__image_success">
                    <img src={icDownloadFile} className="export_transactions__image" alt="success" />
                  </div>
                  <p>{l('exportChargebacks.successMessage')}</p>
                  <p>
                    <span>{l('exportChargebacks.downloadAgain')}</span>
                    <ButtonLink text={l('exportChargebacks.downloadAgain.here')} onClick={() => download(data)} />
                  </p>
                </div>
              )}
            </div>
            {!data && (
              <div className="export_transactions__export_btn">
                <Button
                  name="exportTransactions"
                  variant="contained"
                  onClick={handleClick}
                  endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  disabled={!filter.to || !filter.from || !!dateRangeError || loading}
                >
                  {l('exportChargebacks.exportBtn')}
                </Button>
                <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
                  <MenuItem
                    className="export_transactions__export_menu"
                    onClick={() => exportChargebacks(params, ExportType.CSV)}
                  >
                    <FileCsvIcon />
                    CSV
                  </MenuItem>
                  <MenuItem
                    className="export_transactions__export_menu"
                    onClick={() => exportChargebacks(params, ExportType.XLSX)}
                  >
                    <FileXlsxIcon />
                    XLSX
                  </MenuItem>
                </Menu>
              </div>
            )}
          </div>
        }
      />
    </div>
  );
};

export default DialogExportTransactions;
