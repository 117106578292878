import { PanelStore } from '../interfaces';

export namespace CheckoutThemeSelectors {
  export const getLoading = (state: PanelStore.RootState) => {
    return state.checkoutThemes.loading;
  };
  export const getFormLoading = (state: PanelStore.RootState) => {
    return state.checkoutThemes.formLoading;
  };
  export const getError = (state: PanelStore.RootState) => {
    return state.checkoutThemes.error;
  };
  export const getErrorInfo = (state: PanelStore.RootState) => {
    return state.checkoutThemes.errorInfo;
  };
  export const getAllCheckoutThemes = (state: PanelStore.RootState) => {
    return state.checkoutThemes.allCheckoutThemes;
  };
  export const getShowCreateCheckoutTheme = (state: PanelStore.RootState) => {
    return state.checkoutThemes.showCreateCheckoutTheme;
  };
  export const getRequestSuccess = (state: PanelStore.RootState) => {
    return state.checkoutThemes.requestSuccess;
  };
  export const getUpdateCheckoutTheme = (state: PanelStore.RootState) => {
    return state.checkoutThemes.updateTheme;
  };
}
