import { useState } from 'react';
import AccountCircleIcon from '../../../assets/icons/user-circle.svg?url';
import QuestionAnswerIcon from '../../../assets/icons/faq.svg?url';
import CopyIcon from '../../../assets/icons/copy.svg?url';
import ExitToAppIcon from '../../../assets/icons/signout.svg?url';
import AvatarMenu from './avatar-menu';
import Profile from '../../dashboard/profile';
import Dialog from '../dialog';
import l from '../../../lang';
import { getLanguage, openNewTabAndFocus } from '../../../utils';
import { Urls } from '../../../config/urls';
import { useDashboard } from '../../../store/dashboard';
import { useAuth } from '../../../store/auth';

const AvatarOptions = () => {
  const { userMe } = useDashboard();
  const { sendLogout } = useAuth();
  const [open, setOpen] = useState(false);

  const getDocumentationUrl = () => {
    const lang = getLanguage();
    switch (lang) {
      case 'es':
        return Urls.faq.es;
      case 'en':
        return Urls.faq.en;
      case 'pt':
        return Urls.faq.pt;
      default:
        return Urls.faq.en;
    }
  };

  const getFeedbackUrl = () => {
    const lang = getLanguage();
    switch (lang) {
      case 'es':
        return Urls.feedback.es;
      case 'en':
        return Urls.feedback.en;
      case 'pt':
        return Urls.feedback.pt;
      default:
        return Urls.feedback.en;
    }
  };

  const openFAQs = () => {
    const urlFaq = getDocumentationUrl();
    openNewTabAndFocus(urlFaq);
  };

  const openFeedback = () => {
    const urlFaq = getFeedbackUrl();
    openNewTabAndFocus(urlFaq);
  };

  const menuOptions = [
    {
      icon: <img src={AccountCircleIcon} />,
      text: l('avatarOptions.profile'),
      onClick: () => setOpen(!open),
    },
    {
      icon: <img src={QuestionAnswerIcon} />,
      text: l('avatarOptions.FAQ'),
      onClick: () => openFAQs(),
    },
    {
      icon: <img src={CopyIcon} />,
      text: l('avatarOptions.feedback'),
      onClick: () => openFeedback(),
    },
    {
      icon: <img src={ExitToAppIcon} />,
      text: l('avatarOptions.logout'),
      onClick: () => sendLogout(),
    },
  ];

  return (
    <div>
      <AvatarMenu user={userMe?.name} items={menuOptions} />
      {open && (
        <Dialog
          dividers={false}
          unpaddingContent
          unpaddingFooter
          fullScreenMobile
          type="sm"
          body={<Profile onClose={() => setOpen(false)} />}
        />
      )}
    </div>
  );
};

export default AvatarOptions;
