import { takeLatest, put, call, select } from 'redux-saga/effects';
import { checkToken } from '../auth/sagas';
import { Urls } from '../../config/urls';
import { getCSVTask, getTask, getXlsxTask, postTask } from '../../services';
import {
  ChargebacksActionType,
  getChargebacksSuccess,
  getChargebacksError,
  getPaymentMethodsForTransSuccess,
  getPaymentMethodsForTransError,
  exportChargebacksSuccess,
  exportChargebacksError,
  getChargebackDetailSuccess,
  getChargebackDetailError,
  sendChargebackDocumentSuccess,
  sendChargebackDocumentError,
} from './actions';
import { ExportType, PanelStore } from '../interfaces';
import _ from 'lodash';

function* getChargebacks(action: any) {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  let params = `?page=${action.page || 0}`;

  const filters = action.filter;
  let filterParam = '';
  if (filters) {
    Object.keys(filters).forEach(key => {
      if (filters[key]) {
        filterParam += filterParam ? '&' : '';
        filterParam += `${_.snakeCase(key)}=${filters[key]}`;
      }
    });
  }

  params += filterParam ? `&${filterParam}` : '';

  const { response, error } = yield call(getTask, {
    url: Urls.api.allChargebacks + params,
    token: state.auth.token,
  });

  if (response) {
    yield put(getChargebacksSuccess(response));
  } else {
    yield put(getChargebacksError(error));
  }
}

export function* getChargebacksSaga() {
  yield takeLatest(ChargebacksActionType.GET_CHARGEBACKS, getChargebacks);
}

function* getChargebackDetail(action?: { invoiceId: string }) {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  const { response, error } = yield call(getTask, {
    url: `${Urls.api.allChargebacks}?transaction_id=${action?.invoiceId}`,
    token: state.auth.token,
  });

  if (response && response.data?.length > 0) {
    yield put(getChargebackDetailSuccess(response.data[0]));
  } else {
    yield put(getChargebackDetailError(error));
  }
}

export function* getChargebackDetailSaga() {
  yield takeLatest(ChargebacksActionType.GET_CHARGEBACK_DETAIL, getChargebackDetail);
}

function* getPaymentMethodsForTrans(action: any) {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  const { response, error } = yield call(getTask, {
    url: `${Urls.api.allPaymentMethods}?include_deleted=1&page_size=50&country=${action.country}`,
    token: state.auth.token,
  });

  if (response) {
    yield put(getPaymentMethodsForTransSuccess(response));
  } else {
    yield put(getPaymentMethodsForTransError(error));
  }
}

export function* getPaymentMethodsForTransSaga() {
  yield takeLatest(ChargebacksActionType.GET_PAYMENT_METHODS_FOR_CHARGEBACKS, getPaymentMethodsForTrans);
}

function* exportChargebacks(action: any) {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  const params = action.filter ? `?${action.filter}` : '';
  const exportType: ExportType = action.exportType;
  const getTask = exportType === ExportType.CSV ? getCSVTask : getXlsxTask;
  const url = exportType === ExportType.CSV ? Urls.api.exportChargebacks : Urls.api.exportChargebacksExcel;

  const { response, error } = yield call(getTask, {
    url: url + params,
    token: state.auth.token,
  });

  if (response) {
    yield put(exportChargebacksSuccess(response));
  } else {
    yield put(exportChargebacksError(error));
  }
}

export function* exportChargebacksSaga() {
  yield takeLatest(ChargebacksActionType.EXPORT_CHARGEBACKS, exportChargebacks);
}

function* sendChargebackDocument(action: any) {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();

  const url = Urls.api.uploadChargebackDocument.replace(':id', action.chargebackId);

  const { response, error } = yield call(postTask, {
    url,
    token: state.auth.token,
    body: action.values,
    format: 'form',
  });

  if (!error) {
    yield put(sendChargebackDocumentSuccess(response));
  } else {
    yield put(sendChargebackDocumentError(error));
  }
}

export function* sendChargebackDocumentSaga() {
  yield takeLatest(ChargebacksActionType.SEND_CHARGEBACK_DOCUMENT, sendChargebackDocument);
}
