import './styles.scss';
import { Button } from '@mui/material';
import { Formik, FormikValues } from 'formik';

import DialogSuccess from './dialog-success';
import DialogError from './dialog-error';

import Dialog from '../../../common/dialog';
import UploadFile from '../../../common/upload-file';
import Input from '../../../common/input';
import { useChargebacks } from '../../../../store/chargebacks';
import l from '../../../../lang';

import { type UploadChargebackDocumentValues } from '../../../../store/chargebacks/interfaces';

interface UploaderProps {
  onClose: () => void;
  chargebackId: number;
}

const getMbSize = (size: number): number => {
  return size / 1024 / 1024;
};

function UploaderBody({ chargebackId, onClose }: UploaderProps) {
  const { sendChargebackDocument, chargebackUploadDocument, resetChargebackDocument } = useChargebacks();

  const onSubmit = (values: FormikValues) => {
    sendChargebackDocument(chargebackId, values as UploadChargebackDocumentValues);
  };

  const onCloseSuccessDialog = () => {
    onClose();
  };

  const onCloseErrorDialog = () => {
    onClose();
    resetChargebackDocument();
  };

  const initialValues: UploadChargebackDocumentValues = {
    document: null,
    comments: '',
  };

  const validate = (values: FormikValues) => {
    const errors: any = {};
    if (!values.document) {
      errors.document = l('chargebacks.uploader.docRequired');
    }

    if (values.document && getMbSize(values.document.size) > 1) {
      errors.document = l('chargebacks.uploader.exceedsSize');
    }

    return errors;
  };

  return (
    <Formik initialValues={initialValues} validate={validate} onSubmit={values => onSubmit(values)}>
      {({ handleSubmit, handleBlur, handleChange, errors, setFieldValue, values }) => {
        const onFileUploadChange = ({ file: value }: { file: File | null; base64: string | null }): void => {
          setFieldValue('document', value);
        };

        return (
          <form onSubmit={handleSubmit}>
            <div className="uploader_modal">
              <div className="uploader_modal_column">
                <div className="uploader_modal_column_uploader_section">
                  <div className="uploader_modal_column__title">{l('chargebacks.uploader.title')}</div>
                  <p>{l('chargebacks.uploader.desc1')}</p>
                  <p>{l('chargebacks.uploader.desc2')}</p>
                  <UploadFile
                    title={l('chargebacks.uploader.selectFile')}
                    supportedExtensions={['pdf']}
                    maxSize={1}
                    onChange={onFileUploadChange}
                    externalError={errors.document}
                  />
                  <div className="uploader_modal_column_uploader_section__comments">
                    <label htmlFor="comments">{l('chargebacks.uploader.commentsTitle')}</label>
                    <Input
                      name="comments"
                      maxLength={250}
                      value={values.comments}
                      label={l('chargebacks.uploader.comments')}
                      message={l('chargebacks.uploader.comments.maxCharacters')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.comments}
                    />
                  </div>
                </div>
              </div>
              <div className="uploader_modal_column">
                <div className="uploader_modal_column_dispute_documentation">
                  <div className="uploader_modal_column__title">{l('chargebacks.uploader.disputeDocumentation')}</div>
                  <p>{l('chargebacks.uploader.disputeDocumentation.desc')}</p>
                  <div className="uploader_modal_column__title">{l('chargebacks.uploader.fileToUpload')}</div>
                  {values.document ? (
                    <p>
                      {values.document.name} ({getMbSize(values.document.size).toFixed(2)} MB)
                    </p>
                  ) : (
                    <p>{l('chargebacks.uploader.fileToUpload.noDoc')}</p>
                  )}
                  <div className="uploader_modal_column_dispute_documentation__footer">
                    <Button
                      type="submit"
                      sx={{ backgroundColor: 'white', width: '100%', ':hover': { backgroundColor: 'white' } }}
                    >
                      {l('common.submit')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            {chargebackUploadDocument.data && <DialogSuccess onClose={onCloseSuccessDialog} />}
            {chargebackUploadDocument.error && <DialogError onClose={onCloseErrorDialog} />}
          </form>
        );
      }}
    </Formik>
  );
}

export function ChargebackUploader({ chargebackId, onClose }: UploaderProps) {
  return (
    <Dialog
      withoutTitle
      type="md"
      unpaddingContent
      body={<UploaderBody chargebackId={chargebackId} onClose={onClose} />}
      handleClose={onClose}
    ></Dialog>
  );
}
