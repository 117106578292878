import React, { ChangeEventHandler, FocusEventHandler, SyntheticEvent, useState } from 'react';
import { Popover, Box, Grid, Typography, IconButton, TextField, FormControl, InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import l from '../../../lang';

interface ColorPickerProps {
  colors: string[];
  value: string;
  label?: string;
  onChange: (color: string) => void;
  name?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  error?: string | null;
}

const ColorPicker = ({ colors, value, onChange, label, name, onBlur, error }: ColorPickerProps) => {
  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'color-popover' : undefined;

  const handleClick = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColorChange = (color: string, shouldClose: boolean) => {
    onChange(color);

    if (shouldClose) {
      handleClose();
    }
  };

  return (
    <FormControl sx={{ marginTop: '16px' }} fullWidth>
      <TextField
        label={label}
        variant="outlined"
        fullWidth
        value={value}
        name={name}
        onClick={handleClick}
        onBlur={onBlur}
        error={!!error}
        helperText={error && typeof error === 'string' ? error : ''}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Box
                sx={{
                  backgroundColor: value,
                  width: 24,
                  height: 24,
                  borderRadius: '8px',
                  border: '1px solid #ddd',
                }}
              />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <ArrowDropDownIcon />
            </InputAdornment>
          ),
          readOnly: true,
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box p={2} sx={{ width: 340 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">{l('colorPicker.selectColor')}</Typography>
            <IconButton size="small" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Grid container spacing={2} mt={2}>
            {colors.map(color => (
              <Grid item xs={2} key={color}>
                <Box
                  onClick={() => handleColorChange(color, true)}
                  style={{
                    backgroundColor: color,
                    width: '40px',
                    height: '40px',
                    borderRadius: '8px',
                    border: '1px solid #ddd',
                    cursor: 'pointer',
                  }}
                />
              </Grid>
            ))}
          </Grid>
          <TextField
            fullWidth
            margin="normal"
            value={value}
            onChange={e => handleColorChange(e.target.value)}
            InputProps={{
              style: {
                marginTop: '16px',
                height: '40px',
                borderRadius: '8px',
              },
            }}
          />
        </Box>
      </Popover>
    </FormControl>
  );
};

export default ColorPicker;
