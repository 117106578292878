import Parser from 'html-react-parser';
import l from '../../../../lang';
import { usePaymentIntegration } from '../../../../store/payment-integration';
import { DetailsOpened } from '../../../../store/payment-integration/interfaces';
import { ReactComponent as BigcommerceIcon } from '../../../../assets/icons/integrations/bigcommerce.svg';
import IntegrationDetails from '../integration-details';
import { Button } from '../../../common/button';
import { openNewTabAndFocus, getLanguage } from '../../../../utils';
import { Urls } from '../../../../config/urls';
import ButtonLink from '../../../common/button-link';

export default () => {
  const { setDetailsOpened } = usePaymentIntegration();

  return (
    <IntegrationDetails icon={<BigcommerceIcon />} color="black" onClose={() => setDetailsOpened(DetailsOpened.NONE)}>
      <div className="integration_details__title">{l('paymentIntegration.bigcommerce.details.headline')}</div>
      <p>
        <span>{Parser(l('paymentIntegration.bigcommerce.details.description'))}</span>
      </p>
      <Button
        className="integration_details__button"
        onClick={() => {
          openNewTabAndFocus(Urls.docs.teaserIntegrations[getLanguage()]);
        }}
      >
        {l('paymentIntegration.card.integration.voting.link')}
      </Button>
    </IntegrationDetails>
  );
};
