import { useMemo } from 'react';
import Cookies from 'universal-cookie';

const COOKIE_PREFIX = '_ga_';

export default function useMerchantGaCookie() {
  return useMemo(() => {
    const cookies = new Cookies();
    const allCookies = cookies.getAll();

    const matchingCookieKey = Object.keys(allCookies).find(key => key.startsWith(COOKIE_PREFIX));
    if (!matchingCookieKey) return undefined;

    const matchingCookieValue = allCookies[matchingCookieKey];

    const match = matchingCookieValue.match(/(?:\d+\.){5}(\d+)\./);

    return match ? match[1] : undefined;
  }, []);
}
