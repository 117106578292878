import TopDashboardSection from '../../common/top-dashboard-section';
import l from '../../../lang';
import Card from '../../common/card';
import CardDescription from '../../common/card-description';
import { IntegrationCards } from './integration-cards';
import { usePaymentIntegration } from '../../../store/payment-integration';
import { ApiDetails } from './integration-api';
import { DetailsOpened } from '../../../store/payment-integration/interfaces';
import WooDetails from './integration-woo/woo-details';
import { MagentoDetails } from './integration-magento';
import { ShopifyDetails } from './integration-shopify';
import { PrestashopDetails } from './integration-prestashop';
import { HostingerDetails } from './integration-hostinger';
import AlertMessages from '../alert-messages';
import { VtexDetails } from './integration-vtex';
import { BillowshopDetails } from './integration-billowshop';
import { JumpsellerDetails } from './integration-jumpseller';
import { VirtueMartDetails } from './integration-virtuemart';
import { BigcommerceDetails } from './integration-bigcommerce';
import { OpenCartDetails } from './integration-opencart';
import { FenicioDetails } from './integration-fenicio';
import { WixDetails } from './integration-wix';

export default () => {
  const { detailsOpened } = usePaymentIntegration();

  return (
    <>
      <TopDashboardSection title={l('menu.item.PAYMENT_INTEGRATION')} />
      <AlertMessages />
      <Card>
        <CardDescription>{l('menu.item.PAYMENT_INTEGRATION.desc')}</CardDescription>
        <IntegrationCards />
      </Card>
      {detailsOpened === DetailsOpened.API && <ApiDetails />}
      {detailsOpened === DetailsOpened.WOO && <WooDetails />}
      {detailsOpened === DetailsOpened.MAGENTO && <MagentoDetails />}
      {detailsOpened === DetailsOpened.SHOPIFY && <ShopifyDetails />}
      {detailsOpened === DetailsOpened.PRESTASHOP && <PrestashopDetails />}
      {detailsOpened === DetailsOpened.HOSTINGER && <HostingerDetails />}
      {detailsOpened === DetailsOpened.VTEX && <VtexDetails />}
      {detailsOpened === DetailsOpened.BILLOWSHOP && <BillowshopDetails />}
      {detailsOpened === DetailsOpened.JUMPSELLER && <JumpsellerDetails />}
      {detailsOpened === DetailsOpened.BIGCOMMERCE && <BigcommerceDetails />}
      {detailsOpened === DetailsOpened.OPENCART && <OpenCartDetails />}
      {detailsOpened === DetailsOpened.VIRTUEMART && <VirtueMartDetails />}
      {detailsOpened === DetailsOpened.FENICIO && <FenicioDetails />}
      {detailsOpened === DetailsOpened.WIX && <WixDetails />}
    </>
  );
};
