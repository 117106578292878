import './styles.scss';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getOptions, plugin, chartColors, chartEmptyColor } from './config';
import l from '../../../../../../lang';
import TooltipIcon from '../../../../../common/tooltip-icon';
import { ReactComponent as HelpIcon } from '../../../../../../assets/icons/question-circle.svg';
import { useReports } from '../../../../../../store/reports';
import { useEffect } from 'react';
import ErrorRetry from '../../../error-retry';
import Loading from '../../../../../common/loading';
import { useMediaQuery } from '@mui/material';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels, plugin);

interface TransactionsByCountryProps {
  period: string;
  days: number;
}

const TransactionsByCountry = ({ days, period }: TransactionsByCountryProps) => {
  const isDesktop = useMediaQuery('(min-width:1024px)');
  const { transactionsByCountry, getTransactionsByCountry } = useReports();
  const { loading, error, data } = transactionsByCountry;

  useEffect(() => {
    getTransactionsByCountry(days, period);
  }, [period, days]);

  const emptyChart = !data.length || (data.length === 1 && !data[0].country);
  const noTransactionLabel = [l('reports.transactionsBy.empty')];

  const chartData: ChartData<'pie'> = {
    labels: !emptyChart
      ? data.map(item => (item.country === 'OTHERS' ? l('common.others') : l(`countryCode.${item.country}`)))
      : noTransactionLabel,
    datasets: [
      {
        data: !emptyChart ? data.map(item => item.percentage) : [],
        backgroundColor: !emptyChart ? chartColors : chartEmptyColor,
        borderWidth: 0,
      },
    ],
  };

  return (
    <div className="transactions_by_country">
      <div className="transactions_by_country__title">
        <span>{l('reports.transactionsByCountry.title')}</span>
        <TooltipIcon
          placement="right"
          text={l('reports.transactionsByCountry.tooltip')}
          icon={<HelpIcon width={21} height={21} color="primary" />}
        />
      </div>
      <div className="transactions_by_country__chart">
        {error && <ErrorRetry onRetry={() => getTransactionsByCountry(days, period)} />}
        {loading && <Loading />}
        {!loading && !error && <Pie height={300} data={chartData} options={getOptions(data, emptyChart, isDesktop)} />}
      </div>
    </div>
  );
};

export default TransactionsByCountry;
