import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';
import Loading from '../../common/loading';
import Table from '../../common/table';
import { Urls } from '../../../config/urls';
import l from '../../../lang';
import './styles.scss';
import ChipStatus from '../../common/chip-status';
import { createDate, getMappedCountryCode } from '../../../utils/index';
import TopDashboardSection from '../../common/top-dashboard-section';
import Card from '../../common/card';
import CardDescription from '../../common/card-description';
import { Empty, EmptyNoFound } from './empty';
import ChargebackFilter from './filter';
import { useMediaQuery } from '@mui/material';
import ExportTransactions from './export';
import { useChargebacks } from '../../../store/chargebacks';
import { Filters } from '../../../store/interfaces';
import AlertMessages from '../alert-messages';
import ChargebackDrawer from './drawer';
import { Chargeback } from '../../../store/chargebacks/interfaces';

const Chargebacks = () => {
  const isDesktop = useMediaQuery('(min-width:1024px)');
  const { loading, error, allChargebacks, getChargebacks } = useChargebacks();

  const initFilters = { search_by: '', status: '', country: '', from: '', to: '', paymentMethodId: '' };
  const [filter, setFilter] = useState<Filters>(initFilters);
  const [isDetailsShown, setIsDetailsShown] = useState(false);
  const [detailTransaction, setDetailTransaction] = useState<Chargeback | null>(null);

  useEffect(() => {
    getChargebacks(0, filter);
  }, [filter]);

  const hasFilter = Object.values(filter).some(value => value !== '');
  const hasTransactions = allChargebacks && allChargebacks?.data?.length > 0;

  const EmptyBody = () => {
    if (!error && !hasTransactions && !hasFilter) {
      return <Empty />;
    }
    if (error || !hasTransactions) {
      return <EmptyNoFound />;
    }
    return null;
  };

  return (
    <div className="transactions">
      <TopDashboardSection title={l('menu.item.CHARGEBACKS')} />
      <AlertMessages />
      <Card>
        <CardDescription>
          <div className="chargebacks_header">
            <span>{l('chargebacks.subheadline')}</span>
            <div className={isDesktop ? '' : 'chargebacks__export__btn'}>{<ExportTransactions />}</div>
          </div>
        </CardDescription>
        <div className="transactions__content">
          <div className="transactions__filter_wrapper">
            <ChargebackFilter
              loading={loading}
              appliedFilters={filter}
              clearFilters={() => setFilter(initFilters)}
              changeFilters={filters => setFilter(filters)}
            />
          </div>
          {loading && (
            <div className="transactions__loading">
              <Loading />
            </div>
          )}
          {!loading && (
            <div className="transactions__table">
              <Table
                emptyBody={<EmptyBody />}
                headers={[
                  <span className="transactions__column__left">{l('transactions.createDate')}</span>,
                  <span className="transactions__column__left">{l('chargebacks.invoiceId')}</span>,
                  <span className="transactions__column__left">{l('transactions.country')}</span>,
                  <span className="transactions__column__left">{l('transactions.paymentMethod')}</span>,
                  <span className="transactions__column__right transactions__title">
                    {l('transactions.amountReceived')}
                  </span>,
                  <span>{l('chargebacks.filter.status')}</span>,
                ]}
                body={
                  (hasTransactions &&
                    !error &&
                    allChargebacks?.data?.map(trans => {
                      return [
                        <span className="transactions__column__left">
                          <Moment format="DD/MM/YYYY">{createDate(trans.date)}</Moment>
                        </span>,
                        <span className="transactions__column__left chargebacks__column__external_id">
                          <span>
                            <a
                              href="#"
                              onClick={() => {
                                setIsDetailsShown(true);
                                setDetailTransaction(trans);
                              }}
                            >
                              {trans.externalId}
                            </a>
                          </span>
                        </span>,
                        <span className="transactions__column__left transactions__country">
                          <img
                            alt=""
                            className="transactions__country__flag"
                            src={`${Urls.flags.replace(':code', getMappedCountryCode(trans.country))}`}
                          />
                          &nbsp;
                          <span>{l(`countryCode.${trans.country}`)}</span>
                        </span>,
                        <span className="transactions__column__left">{trans.paymentMethod}</span>,
                        <span className="transactions__column__right">
                          {trans.currency} {trans.localAmount}
                        </span>,
                        <div>
                          <ChipStatus status={trans.status} text={l(`chargebacks.status.${trans.status}`)} />
                        </div>,
                      ];
                    })) ||
                  null
                }
                rowsPerPageOptions={[allChargebacks?.size]}
                rowsLength={allChargebacks?.totalElements}
                rowsPerPage={allChargebacks?.size}
                page={allChargebacks?.page}
                handleChangePage={(event, newPage) => {
                  getChargebacks(newPage, filter);
                }}
              />
            </div>
          )}
        </div>
      </Card>
      {isDetailsShown && <ChargebackDrawer data={detailTransaction} onClose={() => setIsDetailsShown(false)} />}
    </div>
  );
};

export default Chargebacks;
