import { useState, useEffect, useRef } from 'react';
import l from '../../../../lang';
import Input from '../../../common/input';
import ButtonCustom from '../../../common/__deprecated__/button-custom';
import ColorPicker from '../../../common/color-picker';
import Loading from '../../../common/loading';
import MessageError from '../../../common/message-error';
import SuccessfulAnimation from '../../../common/successful-animation';
import { scrollIntoView } from '../../../../utils';
import config from '../../../../config';
import UploadFileWithPreview from '../../../common/upload-file-with-preview';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import './styles.scss';
import { useCheckoutTheme } from '../../../../store/checkout-theme';
import AvailableColors from '../colors';
import { type CreateThemePayload } from '../create-theme';
import { Formik } from 'formik';

function getCopiedThemeName(themeName: string) {
  const regex = /\((\d+)\)/;
  const match = themeName.match(regex);
  if (match) {
    const num = parseInt(match[1]);
    return themeName.replace(regex, `(${num + 1})`);
  }

  return `${themeName} (1)`;
}

const UpdateTheme = () => {
  const {
    formLoading: loading,
    error,
    errorInfo,
    requestSuccess,
    postCreateCheckoutTheme,
    updateCheckoutTheme,
  } = useCheckoutTheme();
  const errorRef = useRef(null);

  const initialValues = {
    name: updateCheckoutTheme?.name ? getCopiedThemeName(updateCheckoutTheme.name) : '',
    primary_color: updateCheckoutTheme?.theme_schema?.primary_color || '#006cfa',
    bg_primary_color: updateCheckoutTheme?.theme_schema?.bg_primary_color || '#e5e5e5',
    text_primary_color: updateCheckoutTheme?.theme_schema?.text_primary_color || '#000000',
    logo: updateCheckoutTheme?.theme_schema?.logo || '',
  } as CreateThemePayload;

  const previewTheme = (values: CreateThemePayload) => {
    const encodedPrimaryColor = encodeURIComponent(values.primary_color);
    const encodedBgPrimaryColor = encodeURIComponent(values.bg_primary_color);
    const encodedTextPrimaryColor = encodeURIComponent(values.text_primary_color);
    const domain = import.meta.env.REACT_APP_CHECKOUT_DOMAIN_DLGO;

    const url = `${domain}/preview?primary_color=${encodedPrimaryColor}&bg_primary_color=${encodedBgPrimaryColor}&text_primary_color=${encodedTextPrimaryColor}&logo_fallback=show`;
    window.open(url, '_blank');
  };

  useEffect(() => {
    if (error) {
      scrollIntoView(errorRef);
    }
  }, [error]);

  const isFormValid = (errors: object) => Object.values(errors).every(value => !value);

  const isValidHexColor = (color: string) => /^#[0-9A-F]{6}$/i.test(color);

  const validate = (values: CreateThemePayload) => {
    const errors: any = {};
    if (!values.name) {
      errors.name = l('checkout_themes.createTheme.nameRequired');
    }
    if (!isValidHexColor(values.primary_color)) {
      errors.primary_color = l('checkout_themes.createTheme.primaryColorRequired');
    }
    if (!isValidHexColor(values.bg_primary_color)) {
      errors.bg_primary_color = l('checkout_themes.createTheme.bgPrimaryColorRequired');
    }
    if (!isValidHexColor(values.text_primary_color)) {
      errors.text_primary_color = l('checkout_themes.createTheme.textPrimaryColorRequired');
    }

    return errors;
  };

  const onSubmit = async (values: CreateThemePayload) => {
    postCreateCheckoutTheme(values);
  };

  return (
    <div className="create-theme">
      {loading && <Loading fullScreen />}
      {requestSuccess && (
        <div className="create-theme__success">
          <SuccessfulAnimation text={l('create_checkout_themes.success')} />
        </div>
      )}
      {!requestSuccess && (
        <Formik initialValues={initialValues} validate={validate} onSubmit={values => onSubmit(values)}>
          {({ handleSubmit, handleBlur, handleChange, errors, setFieldValue, values }) => (
            <form onSubmit={handleSubmit}>
              <div className="create-theme__container">
                <div className="create-theme__name">
                  <Input
                    type="text"
                    name="name"
                    label={l('checkout_themes.createTheme.name')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    error={errors.name}
                  />
                </div>
                <div className="create-theme__primary_color">
                  <ColorPicker
                    name="primary_color"
                    onBlur={handleBlur}
                    colors={AvailableColors}
                    label={l('checkout_themes.createTheme.primary_color')}
                    onChange={val => setFieldValue('primary_color', val)}
                    value={values.primary_color}
                    error={errors.primary_color}
                  />
                </div>
                <div className="create-theme__bg_primary_color">
                  <ColorPicker
                    name="bg_primary_color"
                    onBlur={handleBlur}
                    colors={AvailableColors}
                    label={l('checkout_themes.createTheme.bg_primary_color')}
                    onChange={val => setFieldValue('bg_primary_color', val)}
                    value={values.bg_primary_color}
                    error={errors.bg_primary_color}
                  />
                </div>
                <div className="create-theme__text_primary_color">
                  <ColorPicker
                    name="text_primary_color"
                    colors={AvailableColors}
                    label={l('checkout_themes.createTheme.text_primary_color')}
                    onChange={val => setFieldValue('text_primary_color', val)}
                    value={values.text_primary_color}
                    error={errors.text_primary_color}
                  />
                </div>

                <div className="create-theme__preview_button">
                  <a
                    href="#"
                    onClick={() => isFormValid(errors) && previewTheme(values)}
                    style={{
                      color: !isFormValid(errors) ? 'gray' : '#006cfa',
                      cursor: !isFormValid(errors) ? 'not-allowed' : 'pointer',
                    }}
                  >
                    <RemoveRedEyeIcon /> <span>{l('checkout_themes.createTheme.preview')}</span>
                  </a>
                </div>

                <div className="create-theme__logo">
                  <UploadFileWithPreview
                    /* @ts-ignore-next-line */
                    initialFileUrl={values.logo}
                    preview
                    description={l(
                      'checkout_themes.createTheme.uploadLogo.allowedDesc',
                      config.uploadFileLogoMaxSize,
                      config.uploadFileSupportedExtensionsForImages.join(', ')
                    )}
                    supportedExtensions={config.uploadFileSupportedExtensionsForImages}
                    maxSize={config.uploadFileMaxSize}
                    onChange={(value): void => {
                      setFieldValue('logo', value);
                    }}
                  />
                </div>
                <div ref={errorRef}>
                  {error && (
                    <div className="create-theme__error">
                      <MessageError errorInfo={errorInfo} />
                    </div>
                  )}
                </div>
              </div>
              <div className="create-theme__btn">
                <ButtonCustom
                  text={l('checkout_themes.updateTheme.update')}
                  loading={loading}
                  size="full-bottom"
                  variant="contained"
                  type="submit"
                />
              </div>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default UpdateTheme;
