import _ from 'lodash';
import l from '../../../../../lang';
import { useTransactions } from '../../../../../store/transactions';
import { Filters } from '../../../../../store/interfaces';
import MultiSelect from '../../../../common/multi-select';
import SelectDateRange from '../../../../common/select-date-range';
import { transationStatus } from '../../../../../utils/transactions-utils';
import {
  segmentTransactionsFilterCountry,
  segmentTransactionsFilterDate,
  segmentTransactionsFilterPaymentMethod,
  segmentTransactionsFilterPaymentStatus,
} from '../../../../../utils/analytics';
import { useAvailableCountries } from '../../../../../hooks/use-available-countries';

interface useComponentsFilterProps {
  appliedFilters: Filters;
  changeFilters: (filters: Filters) => void;
}

const useComponentsFilter = ({ appliedFilters, changeFilters }: useComponentsFilterProps) => {
  const { loading, paymentMethods, getPaymentMethodsForTrans } = useTransactions();
  const allCountries = useAvailableCountries();

  const countries = () => {
    const countries: { id: string; name: string }[] = [];
    allCountries.forEach(co => {
      countries.push({
        name: l(`countryCode.${co}`),
        id: co,
      });
    });
    return countries;
  };

  const status = () => {
    const status: { id: string; name: string }[] = [];
    const ts: { [key: string]: string } = transationStatus();
    Object.keys(ts).forEach(s => {
      status.push({
        name: ts[s],
        id: s,
      });
    });
    return status;
  };

  return {
    statusFilter: (
      <MultiSelect
        value={appliedFilters.status ? appliedFilters.status.split(',').map(s => status().find(ts => `${ts.id}` === s)) : []}
        name="filter-status"
        size="sm"
        label={l('transactions.filter.status')}
        titleOptions={l('transactions.filter.status.titleOptions')}
        disabled={loading}
        onChange={s => {
          segmentTransactionsFilterPaymentStatus({
            filterPaymentStatus: s.length === 0 ? [] : s.split(','),
          });
          changeFilters({ ...appliedFilters, status: s });
        }}
        options={status()}
        withApplyBtn
        hideValue
      />
    ),
    countryFilter: (
      <MultiSelect
        value={
          appliedFilters.country
            ? appliedFilters.country.split(',').map(countryCode => countries().find(co => co.id === countryCode))
            : []
        }
        name="filter-country"
        size="sm"
        label={l('transactions.filter.country')}
        titleOptions={l('transactions.filter.country.titleOptions')}
        placeholderSearch={l('searchCountries.label')}
        disabled={loading}
        onChange={countries => {
          if (countries) {
            getPaymentMethodsForTrans(countries);
          }
          segmentTransactionsFilterCountry({
            filterCountry: countries,
          });
          segmentTransactionsFilterPaymentMethod({
            filterPaymentMethod: [],
          });
          changeFilters({ ...appliedFilters, paymentMethodId: '', country: countries });
        }}
        options={countries()}
        withApplyBtn
        maxHeightOptions={400}
        hideValue
      />
    ),
    dateRangeFilter: (
      <SelectDateRange
        fromDate={appliedFilters.from}
        toDate={appliedFilters.to}
        onChangeRange={({ from, to }) => {
          segmentTransactionsFilterDate({
            filterFrom: from,
            filterTo: to,
          });
          changeFilters({ ...appliedFilters, from, to });
        }}
        size="small"
        label={l('transactions.filter.creationDate')}
        titleOptions={l('transactions.filter.creationDate.titleOptions')}
        disabled={loading}
        withApplyBtn
        hideValue
      />
    ),
    paymentMethodsFilter: (
      <MultiSelect
        key={`payment-methods-for-${appliedFilters.country}-${paymentMethods.data.length}`}
        value={
          appliedFilters.paymentMethodId
            ? appliedFilters.paymentMethodId.split(',').map(pmId => paymentMethods.data.find(pm => `${pm.id}` === pmId))
            : []
        }
        name="filter-payment-method-id"
        size="sm"
        label={l('transactions.filter.paymentMethod')}
        titleOptions={l('transactions.filter.paymentMethod.titleOptions')}
        descriptionOptions={!appliedFilters.country && l('transactions.filter.paymentMethod.descriptionOptions')}
        placeholderSearch={l('searchPaymentMethods.label')}
        disabled={loading || paymentMethods.loading}
        onChange={pms => {
          segmentTransactionsFilterPaymentMethod({
            filterPaymentMethod: pms.length === 0 ? [] : pms.split(','),
          });
          changeFilters({ ...appliedFilters, paymentMethodId: pms });
        }}
        options={
          appliedFilters.country ? paymentMethods.data.map(pm => ({ id: pm.id, name: `${pm.name} (${pm.country})` })) : []
        }
        withApplyBtn
        hideValue
      />
    ),
  };
};

export default useComponentsFilter;
