import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@mui/styles';
import MuiDialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@mui/material';

const styles = theme => ({
  rootTitle: {
    margin: 0,
    padding: '15px 25px',
    minHeight: 30,
    '@media (max-width:768px)': {
      textAlign: 'center',
    },
  },
  title: {
    fontFamily: 'Roboto',
    marginRight: 32,
    fontSize: 28,
    '&.--title-center': {
      textAlign: 'center',
    },
    '@media (max-width:768px)': {
      margin: 'auto 24px auto auto',
      fontSize: 20,
    },
    '&.--without-closing': {
      margin: 'auto',
    },
  },
  closeButton: {
    position: 'absolute',
    right: 4,
    top: 4,
    color: theme.palette.grey[500],
  },
  rootContent: {
    padding: theme.spacing(2),
    '&.-bg': {
      backgroundColor: '#f8f8f8',
    },
    '&.-unpaddingContent': {
      padding: 0,
    },
  },
  rootFooter: {
    margin: 0,
    padding: theme.spacing(1),
    '&.-unpaddingFooter': {
      padding: 0,
    },
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, titleCenter, withoutClosing, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.rootTitle} {...other}>
      <Typography
        variant="h6"
        className={`${classes.title} ${titleCenter ? '--title-center' : ''} ${withoutClosing ? '--without-closing' : ''}`}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(styles)(props => {
  const { classes, bg, unpaddingContent, ...other } = props;
  return (
    <MuiDialogContent
      className={`${classes.rootContent} ${bg ? '-bg' : ''} ${unpaddingContent ? '-unpaddingContent' : ''}`}
      {...other}
    />
  );
});

const DialogActions = withStyles(styles)(props => {
  const { classes, unpaddingFooter, ...other } = props;
  return <MuiDialogActions className={`${classes.rootFooter} ${unpaddingFooter ? '-unpaddingFooter' : ''}`} {...other} />;
});

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiPaper-rounded': {
      borderRadius: '8px',
    },
  },
}));

const Dialog = ({
  type = 'sm', // 'lg' | 'md' | 'xl' | 'xs' | 'sm',
  open = true,
  dividers = true,
  fullScreen = false,
  fullScreenMobile = false,
  unpaddingContent = false,
  unpaddingFooter = false,
  withoutTitle = false,
  titleCenter = false,
  title,
  body,
  footer,
  handleClose,
}) => {
  const classes = useStyles();
  const isTabletMobile = useMediaQuery('(max-width:768px)');
  return (
    <div>
      <MuiDialog
        open={open}
        fullScreen={fullScreen || (fullScreenMobile && isTabletMobile)}
        onClose={handleClose}
        fullWidth
        maxWidth={type}
        classes={classes}
      >
        {!withoutTitle && (handleClose || title) && (
          <DialogTitle titleCenter={titleCenter} withoutClosing={!handleClose} onClose={handleClose}>
            {title}
          </DialogTitle>
        )}
        <DialogContent dividers={dividers} bg={dividers} unpaddingContent={unpaddingContent}>
          {body}
        </DialogContent>
        {footer && <DialogActions unpaddingFooter={unpaddingFooter}>{footer}</DialogActions>}
      </MuiDialog>
    </div>
  );
};

Dialog.propTypes = {
  type: PropTypes.string,
  open: PropTypes.bool,
  dividers: PropTypes.bool,
  fullScreen: PropTypes.bool,
  fullScreenMobile: PropTypes.bool,
  unpaddingContent: PropTypes.bool,
  titleCenter: PropTypes.bool,
  title: PropTypes.string,
  body: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.node]),
  footer: PropTypes.object,
  handleClose: PropTypes.func,
};

export default Dialog;
