import { ErrorInfo } from '../../utils/error-codes';
import { Currencies, ExchangeRates } from '../interfaces';

export interface PaymentIntegrationState {
  loading: boolean;
  error: boolean;
  errorInfo?: ErrorInfo;
  apiKey: string;
  secretKey: string;
  currencies: Currencies;
  exchangeRates: ExchangeRates;
  shopifyIntegration: {
    loading: boolean;
    error: boolean;
  };
  detailsOpened: DetailsOpened;
}

export enum DetailsOpened {
  NONE = 'NONE',
  API = 'API',
  SHOPIFY = 'SHOPIFY',
  WOO = 'WOO',
  MAGENTO = 'MAGENTO',
  PRESTASHOP = 'PRESTASHOP',
  HOSTINGER = 'HOSTINGER',
  VTEX = 'VTEX',
  BILLOWSHOP = 'BILLOWSHOP',
  JUMPSELLER = 'JUMPSELLER',
  BIGCOMMERCE = 'BIGCOMMERCE',
  OPENCART = 'OPENCART',
  VIRTUEMART = 'VIRTUEMART',
  FENICIO = 'FENICIO',
  WIX = 'WIX',
}
