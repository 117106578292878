import l from '../../../../lang';
import IntegrationCard from '../integration-card';
import { ReactComponent as VtexIcon } from '../../../../assets/icons/integrations/vtex.svg';
import { usePaymentIntegration } from '../../../../store/payment-integration';
import { DetailsOpened } from '../../../../store/payment-integration/interfaces';

export default () => {
  const { setDetailsOpened } = usePaymentIntegration();

  return (
    <IntegrationCard
      title={l('paymentIntegration.vtex.title')}
      icon={<VtexIcon />}
      color="pink"
      onDetails={() => {
        setDetailsOpened(DetailsOpened.VTEX);
      }}
      detailsText={l('paymentIntegration.card.integration.voting.link')}
    />
  );
};
