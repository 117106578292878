import { call, put, takeLatest, select } from 'redux-saga/effects';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { postTask } from '../../services';
import { Urls } from '../../config/urls';
import {
  refreshToken,
  refreshTokenError,
  tokenChecked,
  sendingLogin,
  loginSuccess,
  loginError,
  logoutSuccess,
  logoutError,
  initCheckCompleted,
  AuthActionType,
} from './actions';
import { callbackUrlIsFromShopify, getLanguage, isRefreshTokenNecessary } from '../../utils';
import { PanelStore } from '../interfaces';
import { AuthSelectors } from './selectors';
import { segmentLogin } from '../../utils/analytics';

export function* checkToken(init?: boolean) {
  const state: PanelStore.RootState = yield select();
  if (isRefreshTokenNecessary(state.auth)) {
    const { response, error } = yield call(postTask, {
      url: Urls.api.refreshLogin,
      body: {},
    });

    if (response) {
      yield put(refreshToken(response));
      if (init) {
        yield put(tokenChecked());
      }
    } else {
      yield put(refreshTokenError(error));
    }
  } else if (init) {
    yield put(tokenChecked());
  }
}

function* initLogin() {
  yield* checkToken();
  yield put(initCheckCompleted());
}

export function* initLoginSaga() {
  yield takeLatest(AuthActionType.INIT_LOGIN, initLogin);
}

function* sendLogin(action: any) {
  yield put(sendingLogin());

  const { response, error } = yield call(postTask, {
    url: Urls.api.login,
    body: {
      username: action.email,
      password: action.password,
      merchantReference: action.merchantReference,
      merchantGaCookie: action.merchantGaCookie,
      merchantGaSessionIdCookie: action.merchantGaSessionIdCookie,
    },
  });

  if (response) {
    if (response.redirectUrl && callbackUrlIsFromShopify(response.redirectUrl)) {
      window.location.href = sanitizeUrl(response.redirectUrl);
      return;
    }

    segmentLogin({
      language: getLanguage(),
    });
    yield put(loginSuccess(response));
  } else {
    yield put(loginError(error));
  }
}

export function* sendLoginSaga() {
  yield takeLatest(AuthActionType.SEND_LOGIN, sendLogin);
}

function* sendLogout() {
  const token: string = yield select(AuthSelectors.getToken);
  const { response, error } = yield call(postTask, {
    url: Urls.api.logout,
    body: {},
    token,
  });
  if (response) {
    yield put(logoutSuccess(response));
  } else {
    yield put(logoutError(error));
  }
}

export function* sendLogoutSaga() {
  yield takeLatest(AuthActionType.SEND_LOGOUT, sendLogout);
}
