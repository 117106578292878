import { takeLatest, select, put, call } from 'redux-saga/effects';
import { checkToken } from '../auth/sagas';
import { Urls } from '../../config/urls';
import { getTask } from '../../services';
import {
  DashboardActionType,
  initDashboardSuccess,
  userMeSuccess,
  userMeError,
  merchantMeSuccess,
  merchantMeError,
} from './actions';
import { AuthActionType } from '../auth/actions';
import { PanelStore } from '../interfaces';
import { refreshStatus as jumioRefreshStatus } from '../jumio/actions';

function* userMe() {
  const state: PanelStore.RootState = yield select();
  const { response, error } = yield call(getTask, {
    url: Urls.api.userMe,
    token: state.auth.token,
  });
  if (response) {
    if (window.Intercom) {
      window.Intercom('boot', {
        app_id: window.intercomSettings.app_id,
        email: response.email,
        name: response.name,
        user_hash: response.intercomIdentity,
      });
    }
    yield put(userMeSuccess(response));
  } else {
    yield put(userMeError(error));
  }
}

export function* getUserMeSaga() {
  yield takeLatest(DashboardActionType.GET_MERCHANT_ME_SUCCESS, userMe);
}

export function* merchantMe() {
  yield* checkToken();
  const state: PanelStore.RootState = yield select();
  const { response, error } = yield call(getTask, {
    url: Urls.api.merchantMe,
    token: state.auth.token,
  });

  if (response) {
    yield put(merchantMeSuccess(response));
    // if (!response.canOperate && response.kycLevel.level === 1 && response.document?.length > 0 && state.jumio.status === undefined) {
    //   yield put(jumioRefreshStatus());
    // }
  } else {
    yield put(merchantMeError(error));
  }
}

export function* tokenCheckedSaga() {
  yield takeLatest(AuthActionType.TOKEN_CHECKED, merchantMe);
}

export function* getMerchantMeSaga() {
  yield takeLatest(DashboardActionType.GET_MERCHANT_ME, merchantMe);
}

function* initDashboardFinish() {
  yield put(initDashboardSuccess());
}

export function* initDashboardFinishSaga() {
  yield takeLatest(DashboardActionType.GET_USER_ME_SUCCESS, initDashboardFinish);
}

function* initDashboard() {
  yield* checkToken(true);
}

export function* initDashboardSaga() {
  yield takeLatest(DashboardActionType.INIT_DASHBOARD, initDashboard);
}
