import { MenuCodes } from '../../../constants';
import Home from '../../../components/dashboard/home';
import Reports from '../../../components/dashboard/reports';
import ActivateAccount from '../../../components/dashboard/activate-account';
import EmailValidation from '../../../components/dashboard/email-validation';
import PaymentMethods from '../../../components/dashboard/payment-methods';
import Transactions from '../../../components/dashboard/transactions';
import TransactionDetail from '../../../components/dashboard/transactions/detail';
import Settlements from '../../../components/dashboard/settlements';
import CreatePaymentLink from '../../../components/dashboard/payment-link/create';
import CreatedPaymentLinks from '../../../components/dashboard/payment-link/created';
import CheckoutThemes from '../../../components/dashboard/checkout-themes';
import VirtualPOS from '../../../components/dashboard/virtual-pos';
import PaymentButton from '../../../components/dashboard/payment-button';
import PaymentIntegration from '../../../components/dashboard/payment-integration';
import CompanyInformation from '../../../components/dashboard/company-information';
import Refunds from '../../../components/dashboard/refunds';
import Clients from '../../../components/dashboard/clients';
import BankAccounts from '../../../components/dashboard/bank-accounts';
import Subscriptions from '../../../components/dashboard/subscriptions';
import SubscriptionsDetail from '../../../components/dashboard/subscriptions/subscribers/details';
import SubscriptionsPlanDetails from '../../../components/dashboard/subscriptions/plans/details';
import SubscriptionsCreateEditPlan from '../../../components/dashboard/subscriptions/plans/create-edit';
import Chargebacks from '../../../components/dashboard/chargebacks';

export default {
  [MenuCodes.HOME]: <Home />,
  [MenuCodes.REPORTS]: <Reports />,
  [MenuCodes.ACTIVATE_ACCOUNT]: <ActivateAccount />,
  [MenuCodes.EMAIL_VALIDATION]: <EmailValidation />,
  [MenuCodes.PAYMENT_METHODS]: <PaymentMethods />,
  [MenuCodes.TRANSACTIONS]: <Transactions />,
  [MenuCodes.TRANSACTION_DETAIL]: <TransactionDetail />,
  [MenuCodes.SETTLEMENTS]: <Settlements />,
  [MenuCodes.CREATE_ONE_TIME_PAYMENT_LINK]: <CreatePaymentLink />,
  [MenuCodes.CREATE_RECURRING_PAYMENT_LINK]: <CreatePaymentLink isRecurring />,
  [MenuCodes.CREATED_PAYMENT_LINKS]: <CreatedPaymentLinks />,
  [MenuCodes.CHECKOUT_THEMES]: <CheckoutThemes />,
  // [MenuCodes.VIRTUAL_POS]: <VirtualPOS />,
  [MenuCodes.PAYMENT_BUTTON]: <PaymentButton />,
  [MenuCodes.PAYMENT_INTEGRATION]: <PaymentIntegration />,
  [MenuCodes.COMPANY_INFORMATION]: <CompanyInformation />,
  [MenuCodes.REFUNDS]: <Refunds />,
  [MenuCodes.CLIENTS]: <Clients />,
  [MenuCodes.BANK_ACCOUNTS]: <BankAccounts />,
  [MenuCodes.CHARGEBACKS]: <Chargebacks />,
  [MenuCodes.SUBSCRIPTIONS]: <Subscriptions initTabSelected={0} />,
  [MenuCodes.SUBSCRIPTIONS_PLANS]: <Subscriptions initTabSelected={1} />,
  [MenuCodes.SUBSCRIPTIONS_DETAIL]: <SubscriptionsDetail />,
  [MenuCodes.SUBSCRIPTIONS_PLANS_DETAILS]: <SubscriptionsPlanDetails />,
  [MenuCodes.SUBSCRIPTIONS_PLANS_CREATE]: <SubscriptionsCreateEditPlan />,
  [MenuCodes.SUBSCRIPTIONS_PLANS_EDIT]: <SubscriptionsCreateEditPlan />,
};
