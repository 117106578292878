import { FunctionComponent, useEffect, useState } from 'react';
import { Card, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { createPortal } from 'react-dom';
import Moment from 'react-moment';
import moment from 'moment';
import l from '../../../../lang';
import ChipStatus from '../../../common/chip-status';
import { Button } from '../../../common/button';
import { ReactComponent as CardIcon } from '../../../../assets/icons/card.svg';
import { ReactComponent as FileUploadIcon } from '../../../../assets/icons/file-upload.svg';
import { ReactComponent as FaceSmileIcon } from '../../../../assets/icons/face-smile.svg';
import { ReactComponent as FaceNeutralIcon } from '../../../../assets/icons/face-neutral.svg';
import { ReactComponent as FaceSadIcon } from '../../../../assets/icons/face-sad.svg';
import { ChargebackUploader } from '../uploader';
import './styles.scss';
import { Chargeback } from '../../../../store/chargebacks/interfaces';
import { Routes } from '../../../../config/routes';
import { useHistory } from 'react-router-dom';

interface ChargebackDrawerProps {
  onClose: () => void;
  data: any;
}

const FIXED_USD_FEE_AMOUNT = 5;

const ChargebackDrawer: FunctionComponent<ChargebackDrawerProps> = ({
  onClose,
  data,
}: {
  onClose: () => void;
  data: Chargeback;
}) => {
  const [isChargebackUploaderOpen, setIsChargebackUploaderOpen] = useState(false);
  const [canUploadDocumentation, setCanUploadDocumentation] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (data.status.toLocaleLowerCase() !== 'completed' && !data.disputeUploadedAt) {
      setCanUploadDocumentation(true);
    }
  }, [data]);

  const ResolutionIcon = () => {
    const status = data.status.toLowerCase();

    if (status === 'pending') {
      return <FaceNeutralIcon />;
    } else if (status === 'completed') {
      return <FaceSadIcon />;
    } else {
      return <FaceSmileIcon />;
    }
  };

  const getResolution = () => {
    const chargebackStatus = data.status.toLowerCase();

    const resolutions = {
      pending: 'pending',
      completed: 'against',
      reversal: 'inFavour',
    };

    const color = {
      pending: 'orange',
      completed: 'red',
      reversal: 'green',
    };

    /* @ts-ignore-next-line */
    return { color: color[chargebackStatus], text: l(`chargebacks.resolution.${resolutions[chargebackStatus]}`) };
  };

  const getResolutionAmount = () => {
    if (data.status.toLowerCase() === 'completed') {
      return `${data.currency} ${data.localAmount}`;
    }

    return '-';
  };

  const getResolutionFeeAmount = () => {
    if (data.status.toLowerCase() === 'completed') {
      return `${data.currency} ${FIXED_USD_FEE_AMOUNT.toFixed(2)}`;
    }

    return '-';
  };

  return createPortal(
    <div className="chargeback_drawer">
      <Card className={`chargeback_drawer__content`} onClick={ev => ev.stopPropagation()}>
        <div className="chargeback_drawer__content__header">
          <div>
            {l('chargebacks.chargeback')} - {data.externalId}
          </div>
          <IconButton className="chargeback_drawer__content__close" onClick={onClose}>
            <CloseIcon color="action" />
          </IconButton>
        </div>
        <div className="chargeback_drawer__content__body">
          <div className="details-row">
            <div className="d-grid">
              <div className="chargeback_detail">
                <div className="chargeback_detail__title">{l('chargebacks.status')}</div>
                <div className="chargeback_detail__value">
                  <ChipStatus status={data.status} text={l(`chargebacks.status.${data.status}`)} />
                </div>
              </div>
              <div className="chargeback_detail">
                <div className="chargeback_detail__title">{l('chargebacks.amount')}</div>
                <div className="chargeback_detail__value">
                  {data.currency} {data.localAmount}
                </div>
              </div>
              <div className="chargeback_detail">
                <div className="chargeback_detail__title">{l('chargebacks.createdDate')}</div>
                <div className="chargeback_detail__value">
                  <Moment format="MM/DD/YYYY, h:mm:ss A">{moment.utc(data.date)}</Moment> (GMT +0)
                </div>
              </div>
              <div className="chargeback_detail">
                <div className="chargeback_detail__title">{l('chargebacks.paymentMethod')}</div>
                <div className="chargeback_detail__value">{data.paymentMethod}</div>
              </div>
            </div>
          </div>
          <div className="details-row">
            <div className="transaction_detail__title_section">
              <div className="transaction_detail__title_section__text">
                <CardIcon className="transaction_detail__title_section__icon" />
                {l('chargebacks.details')}
              </div>
            </div>
            <div className="transaction_detail__box_data transaction_detail__box_data">
              <div className="transaction_detail__box_data__title">{l('chargebacks.reference')}</div>
              <div className="transaction_detail__box_data__value__one_line">{data.externalId}</div>
            </div>
            <div className="transaction_detail__box_data transaction_detail__box_data__border_left">
              <div className="transaction_detail__box_data__title">{l('chargebacks.invoiceId')}</div>
              <div className="transaction_detail__box_data__value__one_line">{data.transactionInvoiceId}</div>
            </div>
            <div className="transaction_detail__box_data transaction_detail__box_data__border_left">
              <div className="transaction_detail__box_data__title">{l('chargebacks.feeAmount')}</div>
              <div className="transaction_detail__box_data__value__one_line">
                {data.currency} {(data.localAmount - data.balanceAmount).toFixed(2)}
              </div>
            </div>
            <div className="transaction_detail__box_data transaction_detail__box_data__border_left">
              <div className="transaction_detail__box_data__title">{l('chargebacks.localCurrency')}</div>
              <div className="transaction_detail__box_data__value__one_line">{data.currency}</div>
            </div>
            <div className="transaction_detail__divider" />
            <div className="transaction_detail__box_data transaction_detail__box_data">
              <div className="transaction_detail__box_data__title">{l('chargebacks.countryCode')}</div>
              <div className="transaction_detail__box_data__value__one_line">{data.country}</div>
            </div>
            <div className="transaction_detail__box_data transaction_detail__box_data__border_left">
              <div className="transaction_detail__box_data__title">{l('chargebacks.dueDate')}</div>
              <div className="transaction_detail__box_data__value__one_line">{'-'}</div>
            </div>
          </div>
          <div className="details-row">
            <div className="transaction_detail__title_section">
              <div className="transaction_detail__title_section__text">{l('chargebacks.relatedTransactions')}</div>
            </div>
            <div className="transaction_detail__box_data transaction_detail__box_data">
              <div className="transaction_detail__box_data__title">{l('chargebacks.reference')}</div>
              <div>
                <a
                  href="#"
                  onClick={() => {
                    history.push(Routes.TRANSACTION_DETAIL.replace(':invoice_id', data.transactionInvoiceId));
                  }}
                >
                  {data.transactionExternalId}
                </a>
              </div>
            </div>
            <div className="transaction_detail__box_data transaction_detail__box_data__border_left">
              <div className="transaction_detail__box_data__title">{l('transactions.amountPaid')}</div>
              <div className="transaction_detail__box_data__value__one_line">
                {data.transactionCurrency} {data.transactionLocalAmount}
              </div>
            </div>
            <div className="transaction_detail__box_data transaction_detail__box_data__border_left">
              <div className="transaction_detail__box_data__title">{l('chargebacks.paymentType')}</div>
              <div className="transaction_detail__box_data__value__one_line">{data.transactionPaymentType}</div>
            </div>
            <div className="transaction_detail__box_data transaction_detail__box_data__border_left">
              <div className="transaction_detail__box_data__title">{l('chargebacks.clientName')}</div>
              <div className="transaction_detail__box_data__value__one_line">
                {data.transactionClientFirstName} {data.transactionClientLastName}
              </div>
            </div>
          </div>
          <div className="details-row">
            <div className="transaction_detail__title_section">
              <div className="transaction_detail__title_section__text">{l('chargebacks.chargebackResolution')}</div>
            </div>
            <div className="transaction_detail__box_data transaction_detail__box_data">
              <div className="transaction_detail__box_data__title">{'Resolution'}</div>
              <div className={`resolution__section resolution_${getResolution().color}`}>
                <ResolutionIcon /> {getResolution().text}
              </div>
            </div>
            <div className="transaction_detail__box_data transaction_detail__box_data__border_left">
              <div className="transaction_detail__box_data__title">{l('chargebacks.resolution.amount')}</div>
              <div className="transaction_detail__box_data__value__one_line">{getResolutionAmount()}</div>
            </div>
            <div className="transaction_detail__box_data transaction_detail__box_data__border_left">
              <div className="transaction_detail__box_data__title">{l('chargebacks.feeAmount')}</div>
              <div className="transaction_detail__box_data__value__one_line">{getResolutionFeeAmount()}</div>
            </div>
          </div>
          {canUploadDocumentation && (
            <div className="upload_doc">
              <Button
                textTransformNone
                startIcon={<FileUploadIcon />}
                size="small"
                variant="outlined"
                onClick={() => {
                  setIsChargebackUploaderOpen(true);
                }}
              >
                {l('chargebacks.uploadDocumentation')}
              </Button>
            </div>
          )}

          {data.disputeUploadedAt && data.status == 'PENDING' && (
            <div className="upload_doc">
              <p>{l('chargebacks.uploadDocumentation.received')}</p>
            </div>
          )}
        </div>
      </Card>
      {isChargebackUploaderOpen && (
        <ChargebackUploader chargebackId={data.id} onClose={() => setIsChargebackUploaderOpen(false)} />
      )}
    </div>,
    window.document.body
  );
};

export default ChargebackDrawer;
