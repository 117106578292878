// IMPORTANT: When adding a new country verify that the translated text exists.

// Used: KyC level 1 - add bank account.
// IMPORTANT: When adding a new country, check if the telephone code for that country has already been added (countryPhoneCodes).
export const operatingCountries = [
  'AR',
  'BO',
  'BR',
  'CL',
  'CR',
  'CO',
  'EC',
  'GT',
  'ID',
  'KE',
  'MX',
  'MY',
  'NG',
  'PA',
  'PE',
  'PY',
  'US',
  'UY',
  'ES',
];

// Used: Create payment link - Create payment button - Virtual POS - add payment methods (Filter).
export const allCountries = [
  'AR',
  'BO',
  'BR',
  'CL',
  'CO',
  'CR',
  'EC',
  'GT',
  'ID',
  'KE',
  'MX',
  'MY',
  'NG',
  'PA',
  'PE',
  'PY',
  'UY',
];

// Used: Virtual POS
export const countriesWithoutCC = ['KE'];

export const countriesByRegions = {
  // América Latina
  LA: ['AR', 'BO', 'BR', 'CL', 'CO', 'CR', 'EC', 'GT', 'MX', 'PA', 'PE', 'PY', 'UY'],
  // África y Medio Oriente
  AF_ME: ['KE', 'NG'],
  // Asia
  AS: ['ID', 'MY'],
};

// Used: Subscription
export const subscriptionCountries = ['BR', 'CL', 'CO', 'MX', 'PA', 'PE', 'UY'];

// Used: kyc level 1
export const countryPhoneCodes = {
  AR: '+54',
  BO: '+591',
  BR: '+55',
  CL: '+56',
  CO: '+57',
  CR: '+506',
  EC: '+593',
  ES: '+34',
  GT: '+502',
  ID: '+62',
  KE: '+254',
  MX: '+52',
  MY: '+60',
  NG: '+234',
  PA: '+507',
  PE: '+51',
  PY: '+595',
  US: '+1',
  UY: '+598',
};

export const functionalCurrenciesByCountry: { [key: string]: string } = {
  AR: 'ARS',
  BO: 'BOB',
  BR: 'BRL',
  CL: 'CLP',
  CO: 'COP',
  CR: 'CRC',
  EC: 'USD',
  ES: 'EUR',
  GT: 'GTQ',
  ID: 'IDR',
  KE: 'KES',
  MX: 'MXN',
  MY: 'MYR',
  NG: 'NGN',
  PA: 'USD',
  PE: 'PEN',
  PY: 'PYG',
  US: 'USD',
  UY: 'UYU',
};

// IMPORTANT: When adding a new currency verify that the translated text exists.
export const functionalCurrencies = Object.values(functionalCurrenciesByCountry);

export default {
  refreshToken: 300000, // Milliseconds
  emailValidationOnSignUp: true,
  uploadFileLogoMaxSize: 2, // MegaBytes
  uploadFileMaxSize: 10, // MegaBytes
  uploadFileSupportedExtensions: ['pdf', 'png', 'jpeg', 'jpg', 'doc', 'docx'],
  uploadFileSupportedExtensionsForImages: ['png', 'jpeg', 'jpg'],
  lastLevelKYC: 4,
  minWithdrawalUSD: 10, // Without fee
  chargeWithdrawalUSD: 1, // Without fee
  daysToWithdrawLocalPMs: 3,
  daysToWithdrawCards: 7,
};
