import { useEffect } from 'react';
import { usePaymentIntegration } from '../../../../store/payment-integration';
import './styles.scss';
import { ApiCard } from '../integration-api';
import { WooCard } from '../integration-woo';
import { MagentoCard } from '../integration-magento';
import { ShopifyCard } from '../integration-shopify';
import { PrestashopCard } from '../integration-prestashop';
import { HostingerCard } from '../integration-hostinger';
import VtexCard from '../integration-vtex/vtex-card';
import WixCard from '../integration-wix/wix-card';
import BillowshopCard from '../integration-billowshop/billowshop-card';
import JumpsellerCard from '../integration-jumpseller/jumpseller-card';
import BigcommerceCard from '../integration-bigcommerce/bigcommerce-card';
import { OpenCartCard } from '../integration-opencart';
import { VirtueMartCard } from '../integration-virtuemart';
import FenicioCard from '../integration-fenicio/fenicio-card';

export const IntegrationCards = () => {
  const { getIntegrationKeys, apiKey } = usePaymentIntegration();

  useEffect(() => {
    if (apiKey.length === 0) {
      getIntegrationKeys();
    }
  }, []);

  return (
    <div className="integration_cards">
      <div className="row">
        <ApiCard />
        <ShopifyCard />
        <HostingerCard />
      </div>
      <div className="row">
        <WooCard />
        <MagentoCard />
        <PrestashopCard />
      </div>
      <div className="row">
        <VtexCard />
        <WixCard />
        <BillowshopCard />
      </div>
      <div className="row">
        <JumpsellerCard />
        <BigcommerceCard />
        <OpenCartCard />
      </div>
      <div className="row cards_2">
        <VirtueMartCard />
        <FenicioCard />
      </div>
    </div>
  );
};
