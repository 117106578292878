import { ExportType, Filters } from '../interfaces';
import { Chargeback, UploadChargebackDocumentValues } from './interfaces';

export enum ChargebacksActionType {
  GET_CHARGEBACKS = 'CHARGEBACKS/GET_CHARGEBACKS',
  GET_CHARGEBACKS_SUCCESS = 'CHARGEBACKS/GET_CHARGEBACKS_SUCCESS',
  GET_CHARGEBACKS_ERROR = 'CHARGEBACKS/GET_CHARGEBACKS_ERROR',
  GET_PAYMENT_METHODS_FOR_CHARGEBACKS = 'CHARGEBACKS/GET_PAYMENT_METHODS_FOR_CHARGEBACKS',
  GET_PAYMENT_METHODS_FOR_CHARGEBACKS_SUCCESS = 'CHARGEBACKS/GET_PAYMENT_METHODS_FOR_CHARGEBACKS_SUCCESS',
  GET_PAYMENT_METHODS_FOR_CHARGEBACKS_ERROR = 'CHARGEBACKS/GET_PAYMENT_METHODS_FOR_CHARGEBACKS_ERROR',
  SHOW_EXPORT_CHARGEBACKS = 'CHARGEBACKS/SHOW_EXPORT_CHARGEBACKS',
  EXPORT_CHARGEBACKS = 'CHARGEBACKS/EXPORT_CHARGEBACKS',
  EXPORT_CHARGEBACKS_SUCCESS = 'CHARGEBACKS/EXPORT_CHARGEBACKS_SUCCESS',
  EXPORT_CHARGEBACKS_ERROR = 'CHARGEBACKS/EXPORT_CHARGEBACKS_ERROR',
  SET_CHARGEBACK_DETAIL = 'CHARGEBACKS/SET_CHARGEBACK_DETAIL',
  GET_CHARGEBACK_DETAIL = 'CHARGEBACKS/GET_CHARGEBACK_DETAIL',
  GET_CHARGEBACK_DETAIL_SUCCESS = 'CHARGEBACKS/GET_CHARGEBACK_DETAIL_SUCCESS',
  GET_CHARGEBACK_DETAIL_ERROR = 'CHARGEBACKS/GET_CHARGEBACK_DETAIL_ERROR',
  SEND_CHARGEBACK_DOCUMENT = 'CHARGEBACKS/SEND_CHARGEBACK_DOCUMENT',
  SEND_CHARGEBACK_DOCUMENT_SUCCESS = 'CHARGEBACKS/SEND_CHARGEBACK_DOCUMENT_SUCCESS',
  SEND_CHARGEBACK_DOCUMENT_ERROR = 'CHARGEBACKS/SEND_CHARGEBACK_DOCUMENT_ERROR',
  RESET_CHARGEBACK_DOCUMENT = 'CHARGEBACKS/RESET_CHARGEBACK_DOCUMENT',
}

export const getChargebacks = (page?: number, filter?: Filters) => ({
  type: ChargebacksActionType.GET_CHARGEBACKS,
  page,
  filter,
});

export const getChargebacksSuccess = (response: any) => ({
  type: ChargebacksActionType.GET_CHARGEBACKS_SUCCESS,
  response,
});

export const getChargebacksError = (error: any) => ({
  type: ChargebacksActionType.GET_CHARGEBACKS_ERROR,
  error,
});

export const getPaymentMethodsForTrans = (country: string) => ({
  type: ChargebacksActionType.GET_PAYMENT_METHODS_FOR_CHARGEBACKS,
  country,
});

export const getPaymentMethodsForTransSuccess = (response: any) => ({
  type: ChargebacksActionType.GET_PAYMENT_METHODS_FOR_CHARGEBACKS_SUCCESS,
  response,
});

export const getPaymentMethodsForTransError = (error: any) => ({
  type: ChargebacksActionType.GET_PAYMENT_METHODS_FOR_CHARGEBACKS_ERROR,
  error,
});

export const showExportChargebacks = (show: boolean) => ({
  type: ChargebacksActionType.SHOW_EXPORT_CHARGEBACKS,
  show,
});

export const exportChargebacks = (filter: string, type: ExportType) => ({
  type: ChargebacksActionType.EXPORT_CHARGEBACKS,
  filter,
  exportType: type,
});

export const exportChargebacksSuccess = (response: any) => ({
  type: ChargebacksActionType.EXPORT_CHARGEBACKS_SUCCESS,
  response,
});

export const exportChargebacksError = (error: any) => ({
  type: ChargebacksActionType.EXPORT_CHARGEBACKS_ERROR,
  error,
});

export const setChargebackDetail = (data: Chargeback) => ({
  type: ChargebacksActionType.SET_CHARGEBACK_DETAIL,
  data,
});

export const getChargebackDetail = (invoiceId: string) => ({
  type: ChargebacksActionType.GET_CHARGEBACK_DETAIL,
  invoiceId,
});

export const getChargebackDetailSuccess = (response: any) => ({
  type: ChargebacksActionType.GET_CHARGEBACK_DETAIL_SUCCESS,
  response,
});

export const getChargebackDetailError = (error: any) => ({
  type: ChargebacksActionType.GET_CHARGEBACK_DETAIL_ERROR,
  error,
});

export const sendChargebackDocument = (chargebackId: number, values: UploadChargebackDocumentValues) => ({
  type: ChargebacksActionType.SEND_CHARGEBACK_DOCUMENT,
  chargebackId,
  values,
});

export const sendChargebackDocumentSuccess = (response: any) => ({
  type: ChargebacksActionType.SEND_CHARGEBACK_DOCUMENT_SUCCESS,
  response,
});

export const sendChargebackDocumentError = (error: any) => ({
  type: ChargebacksActionType.SEND_CHARGEBACK_DOCUMENT_ERROR,
  error,
});

export const resetChargebackDocument = () => ({
  type: ChargebacksActionType.RESET_CHARGEBACK_DOCUMENT,
});
